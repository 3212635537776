import {
  Component,
  OnInit,
  ViewEncapsulation,
  AfterContentInit,
  DoCheck
} from '@angular/core';
import { AuthenticationService } from '../../../services/authentication/authentication.service';
import { Router } from '@angular/router';

declare var $:any;
@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./style/nav-sp.component.less']
})
export class NavMenuComponent implements OnInit, AfterContentInit, DoCheck {

  constructor(
    private auth: AuthenticationService,
    private router: Router
  ) { }
  loginStatus: boolean = false;
  data: any;

  ngOnInit() {
  }

  ngDoCheck() {
    this.loginStatus = this.auth.getLoginStatus();
  }

  startTrialBtn() {
    new Promise((resolve, reject) => {
      this.router.navigate(["/"]);
      resolve();
    }).then(
      () => {
        setTimeout(() => {
          $('html,body').animate({ scrollTop: $("#section-8").offset().top }, 'slow');
        }, 400);
      }
    );
  }

  ngAfterContentInit() {
    (function () {
      $('[id^="nav-icon"]').on("click", function () {
        $(this).toggleClass('open');
        $(this).siblings(".nav-menu").toggleClass("active");
      });

      $('.header-sp .nav-item a').on("click", function () {
        $(this).toggleClass("active");
        if (!$(this).hasClass("have-dropdown")) {
          $(".header-sp .nav-menu").toggleClass("active");
          $(".header-sp .burger-icon").toggleClass("open");
        }
      });

      $(".add-dropdown .dropdown-menu a").on("mouseover", function (e) {
        var $this = $(this);
        $this.siblings(".list-item-screen").children("[ class^='list-item-screen-']").addClass("d-none");
        $this.siblings(".list-item-screen").children(".list-item-screen-" + $this.attr("data-screen")).removeClass("d-none");
        $this.parent().children().removeClass("active");
        $this.addClass("active");
        e.target.classList.toggle("active");
      });

    })();
  }
  toAboutUs(id) {
    this.router.navigate(['/about-us']).then(() => {
      document.querySelector('#' + id).scrollIntoView({
        behavior: 'smooth'
      })
    })
  }
}
