<!-- Start Trail Model -->
<div
  class="modal fade"
  id="exampleModalCenter"
  role="dialog"
  data-backdrop="true"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="exampleModalLongTitle">Your Information</h3>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container">
          <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12">
              <form
                (ngSubmit)="onFormSubmit(subscriptionFrom.value)"
                [formGroup]="subscriptionFrom"
              >
                <!-- progressbar -->
                <!-- <ul id="progressbar">
                  <li class="active">Personal </li>
                  <li>Organization </li>
                  <li>Location </li>
                  <li>Done</li>
                </ul> -->
                <!-- fieldsets -->
                <!-- <div class="space-20"></div> -->
                <!-- <h2 class="fs-title">Your Information</h2>
                  <div class="space-20"></div> -->
                <div class="form-group">
                  <select
                    class="form-control"
                    formControlName="regType"
                    placeholder="Select Registration Type"
                  >
                    <option value="" selected>Select Registration Type</option>
                    <option value="student">Student</option>
                    <option value="professional">Professional</option>
                  </select>
                  <p
                    *ngIf="
                      subscriptionFrom.get('regType').invalid &&
                      subscriptionFrom.get('regType').touched
                    "
                    class="warn-text"
                  >
                    Select Registratin Type
                  </p>

                  <input
                    type="text"
                    class="form-control"
                    formControlName="name"
                    placeholder="Contact Name*"
                  />

                  <p
                    *ngIf="
                      subscriptionFrom.get('name').invalid &&
                      subscriptionFrom.get('name').touched
                    "
                    class="warn-text"
                  >
                    Enter a valid name eg. JohnDoe
                  </p>

                  <input
                    type="email"
                    class="form-control"
                    formControlName="email"
                    placeholder="Email id*"
                  />

                  <p
                    *ngIf="
                      subscriptionFrom.get('email').invalid &&
                      subscriptionFrom.get('email').touched
                    "
                    class="warn-text"
                  >
                    Enter a valid email eg. johndoe@example.com
                  </p>

                  <input
                    type="tel"
                    class="form-control"
                    formControlName="phone"
                    placeholder="Mobile no*"
                    maxlength="10"
                  />

                  <p
                    *ngIf="
                      subscriptionFrom.get('phone').invalid &&
                      subscriptionFrom.get('phone').touched
                    "
                    class="warn-text"
                  >
                    Enter a valid mobile number eg. 9000090000
                  </p>
                </div>
                <div class="text-center">
                  <input
                    type="submit"
                    name="next"
                    class="next action-button mx-auto"
                    [ngClass]="subscriptionFrom.invalid ? 'disabled' : ''"
                    value="Register"
                    [disabled]="subscriptionFrom.invalid"
                  />
                </div>
                <!-- <fieldset>
                  <div class="status">
                    <div class="loading flex-container flex-d-c flex-pc" *ngIf="loading; else loaddone">
                      <i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i>
                      <span>
                        Please Wait...
                      </span>
                    </div>
                    <ng-template #loaddone>
                      <div *ngIf="status==true; else undone" class="done">
                        <img *ngIf="responseStatus.status=='OK'" src="/assets/images/icons/tickicon.png" alt="Info-icons"
                          class="img-fluid">
                        <h4 *ngIf="responseStatus.status=='OK'">Success</h4>
                        <h6 *ngIf="responseStatus.status=='OK'">We have sent an email to {{responseStatus.response.emailId}}.
                          Please validate your email to get access.</h6>
          
                        <img *ngIf="responseStatus.status=='ALREADY_REPORTED'" src="/assets/images/icons/crossicon.png"
                          alt="Info-icons" class="img-fluid">
                        <h4 *ngIf="responseStatus.status=='ALREADY_REPORTED'">Already Exist</h4>
                        <h6 *ngIf="responseStatus.status=='ALREADY_REPORTED'">{{responseStatus.response}}</h6>
                      </div>
                      <ng-template class="undone" #undone>
                        <img src="/assets/images/icons/crossicon.png" alt="Info-icons" class="img-fluid">
                        <h4>Oops! something went wrong</h4>
                        <h6>Please refresh page and try again.</h6>
                      </ng-template>
                    </ng-template>
                  </div>
                </fieldset> -->
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div> -->
    </div>
  </div>
</div>
