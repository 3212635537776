<div class="banner">
  <h2>Trainer Enrollment</h2>
</div>
<div class="container">
  <form class="form-sp" *ngIf="enrollForm" [formGroup]="enrollForm" (ngSubmit)="onFromSubmit()">
    <div class="row" *ngIf="formfill">

      <div class="col-md-6 col-sm-6 col-12 form-group">
        <input type="text" name="fname" formControlName="fname" />
        <label for="input" class="control-label">First Name</label>
        <i class="bar"></i>
        <p class="has-error" *ngIf="enrollForm.get('fname').invalid && enrollForm.get('fname').touched">
          <small>
            Enter first user eg. Karan
          </small>
        </p>
      </div>

      <div class="col-md-6 col-sm-6 col-12 form-group">
        <input type="text" name="lname" formControlName="lname" />
        <label for="input" class="control-label">Last Name</label>
        <i class="bar"></i>
        <p class="has-error" *ngIf="enrollForm.get('lname').invalid && enrollForm.get('lname').touched">
          <small>
            Enter last name eg. Sharma
          </small>
        </p>
      </div>

      <div class="col-md-6 col-sm-6 col-12 form-group">
        <input type="date" class="form-control" (click)="d.toggle()" autocomplete="off" ngbDatepicker #d="ngbDatepicker" name="dob" formControlName="dob" [(ngModel)]="model" onkeydown="return false">
        <div class="input-group-append datepickBtn">
          <button class="" (click)="d.toggle()" type="button">
            <i class="fa fa-calendar-o" aria-hidden="true"></i>
          </button>
        </div>

        <label for="input" class="control-label">Date of birth (yyyy-mm-dd)</label>
        <i class="bar"></i>
        <p class="has-error" *ngIf="enrollForm.get('dob').invalid && enrollForm.get('dob').touched">
          <small>
            Select valid date of birth
          </small>
        </p>
      </div>
      <div class="col-md-6 col-sm-6 col-12 form-group">
        <select formControlName="qualification">
          <option value="" selected="selected">Select</option>
          <option *ngFor="let cdata of qualificationlists" [value]="cdata.id">{{cdata.name}}</option>
        </select>
        <label for="select" class="control-label">Select Highest Qualification</label>
        <i class="bar"></i>
        <!-- <p class="has-error" *ngIf="enrollStudentForm.get('personalInfo.highQual').invalid && enrollStudentForm.get('personalInfo.highQual').touched">
                    <small>
                        Select Highest Qualification
                    </small>
                </p> -->
      </div>
      <!-- <div class="col-md-12 col-sm-12 col-12 form-group">
          <ul class="choicebox" formArrayName="desiredSkill">
            <li *ngFor="let skill of desiredSkills; let i = index">
              <input type="checkbox" name="desiredSkill" [formControlName]="i" value="{{skill.id}}" id="ch-{{i}}">
              <label for="ch-{{i}}" class="checklabel">{{skill.courseName}}</label>
            </li>
          </ul>
          <label class="control-label">Select Desired Skills</label>
          <p class="has-error" *ngIf="enrollForm.get('desiredSkill').invalid && enrollForm.get('desiredSkill').touched">
            <small>
              Select atleast one desired skill
            </small>
          </p>
        </div> -->
      <div class="col-md-6 col-sm-6 col-12 form-group">
        <input type="number" name="mobile" formControlName="mobile" (input)="checkMobile($event)" maxlength="10"/>
        <label for="input" class="control-label">Mobile No.</label>
        <i class="bar"></i>
        <p class="has-error" *ngIf="enrollForm.get('mobile').invalid && enrollForm.get('mobile').touched">
          <small>
            Enter valid mobile number eg. 99998 88877
          </small>
        </p>
      </div>

      <div class="col-md-6 col-sm-6 col-12 form-group">
        <input type="email" name="email" formControlName="email" />
        <label for="email" class="control-label">Email</label>
        <i class="bar"></i>
        <p class="has-error" *ngIf="enrollForm.get('email').invalid && enrollForm.get('email').touched">
          <small>
            Enter valid email address eg. useremail@example.com
          </small>
        </p>
      </div>

      <div class="col-md-6 col-sm-6 col-12 form-group">
        <input type="text" id="textarea" formControlName="address" />
        <label for="textarea" class="control-label">Address 1</label>
        <i class="bar"></i>
        <p class="has-error" *ngIf="enrollForm.get('address').invalid && enrollForm.get('address').touched">
          <small>
            Enter valid address
          </small>
        </p>
      </div>
      <div class="col-md-6 col-sm-6 col-12 form-group">
        <input type="text" name="locality" formControlName="locality" />
        <label for="input" class="control-label">Address 2</label>
        <i class="bar"></i>
        <p class="has-error" *ngIf="enrollForm.get('locality').invalid && enrollForm.get('locality').touched">
          <small>
            Enter your locality
          </small>
        </p>
      </div>

      <div class="col-md-6 col-sm-6 col-12 form-group">
        <input type="text" name="city" formControlName="city" />
        <label for="input" class="control-label">City name</label>
        <i class="bar"></i>
        <p class="has-error" *ngIf="enrollForm.get('city').invalid && enrollForm.get('city').touched">
          <small>
            Enter your city
          </small>
        </p>
      </div>
      <!-- CONTRY SELECTOR -->
      <div class="col-md-6 col-sm-6 col-12 form-group">
        <select name="country" formControlName="country" (change)=getStatesByCountry($event)>
            <option value="" selected="selected">Select</option>
            <option *ngFor="let condata of countries" value="{{condata.id}}">{{condata.countryName}}</option>
        </select>
        <label for="select" class="control-label">Select Country</label>
        <i class="bar"></i>
        <p class="has-error" *ngIf="enrollForm.get('country').invalid && enrollForm.get('country').touched">
            <small>
                Select a Country
            </small>
        </p>
      </div>

      <div class="col-md-6 col-sm-6 col-12 form-group" (change)=getDistrictsByState($event)>
        <select name="state" formControlName="state">
          <option value="" selected="selected">Select a state</option>
          <option *ngFor="let cdata of states" value="{{cdata.id}}">{{cdata.state}}</option>
        </select>
        <label for="select" class="control-label">State</label>
        <i class="bar"></i>
        <p class="has-error" *ngIf="enrollForm.get('state').invalid && enrollForm.get('state').touched">
          <small>
            Select valid State
          </small>
        </p>
      </div>

      <!-- DISTRICT SELECTOR -->
      <div class="col-md-6 col-sm-6 col-12 form-group">

        <select name="district" formControlName="district">
          <option value="" selected="selected">Select</option>
          <option *ngFor="let distdata of districtlist" value="{{distdata}}">{{distdata}}</option>
        </select>
        <label for="select" class="control-label">District</label>
        <i class="bar"></i>
        <p class="has-error" *ngIf="enrollForm.get('district').invalid && enrollForm.get('district').touched">
          <small>
            Select an option
          </small>
        </p>
      </div>



    

      <!-- <div class="col-md-6 col-sm-6 col-12 form-group">
        <textarea formControlName="qualification"></textarea>
        <label for="textarea" class="control-label">Qualification</label>
        <i class="bar"></i>
        <p class="has-error" *ngIf="enrollForm.get('qualification').invalid && enrollForm.get('qualification').touched">
          <small>
            Enter your qualification
          </small>
        </p>
      </div> -->

      <div class="col-md-12 col-sm-12 col-12 checkbox">
        <label>
          <input type="checkbox" name="iconfirm" formControlName="iconfirm" (change)="checkConsent($event)"/><i class="helper"></i>I here by agree to
          provide
          my consent to use my details for the registration.
          <!-- <br>
          <a href="" data-toggle="modal" data-target="#modelId">Click here</a> -->
        </label>
      </div>

      <div class="col-md-12 col-sm-12 col-12">
        <button type="submit" class="button button-s" [ngClass]="!enrollForm.valid?'disabled':''" [disabled]="!enrollForm.valid">Submit</button>
      </div>
    </div>
    <div class="dataOutput flex-container flex-d-c flex-pc" *ngIf="!formfill">
      <div class="status flex-container flex-w-w flex-pc flex-d-c">

        <div class="loading flex-container flex-w-w flex-pc flex-d-c" *ngIf="loading==true; else loadingdone">
          <div class="text-center">
            <i class="fa fa-circle-o-notch fa-spin fa-5x fa-fw"></i>
          </div>
          <div class="space-30"></div>
          <h3>Please Wait</h3>
        </div>

        <ng-template #loadingdone>
          <div *ngIf="status==true; else undone" class="done flex-container flex-w-w flex-pc flex-d-c">
            <div *ngIf="responseStatus.status=='Success'" class="flex-container flex-w-w flex-pc flex-d-c">
              <img src="/assets/images/icons/tickicon.png" alt="Info-icons" class="img-fluid">
              <div class="space-30"></div>
              <h4>Success</h4>
              <h6>
                Hi <b>{{responseStatus.response.firstName}}</b>,
                You have been Successfully Registered. Use username :
                <b>{{responseStatus.response.userName}}</b>
                to login.
              </h6>
            </div>

            <div *ngIf="responseStatus.status=='Failed'" class="flex-container flex-w-w flex-pc flex-d-c">
              <img src="/assets/images/icons/crossicon.png" alt="Info-icons" class="img-fluid">
              <div class="space-30"></div>
              <h4>User Already Exist</h4>
              <a class="button button-s" (click)="fillAgain()">Fill Again</a>
            </div>
          </div>

          <ng-template class="undone flex-container flex-w-w flex-pc flex-d-c" #undone>
            <img src="/assets/images/icons/crossicon.png" alt="Info-icons" class="img-fluid">
            <div class="space-30"></div>
            <h4>Something went wrong</h4>
            <p>please refresh the page and try again</p>
            <a class="button button-s" (click)="fillAgain()">Refresh</a>
          </ng-template>
        </ng-template>
      </div>
    </div>
  </form>
</div>

<!-- Modal -->
<div class="modal fade" id="modelId" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="modelTitleId">Terms and Conditions</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Unde omnis aliquid labore voluptatibus, pariatur
          quae odit itaque
          nobis cum consequuntur? Asperiores dolores laborum magnam esse illo rem ipsum, temporibus corrupti! Lorem
          ipsum,
          dolor sit amet consectetur adipisicing elit. Unde omnis aliquid labore voluptatibus, pariatur
          <br> quae odit itaque nobis cum consequuntur? Asperiores dolores laborum magnam esse illo rem ipsum,
          temporibus
          corrupti! Lorem ipsum, dolor sit amet consectetur adipisicing elit. Unde omnis aliquid labore voluptatibus,
          pariatur
          quae odit itaque nobis cum consequuntur? Asperiores dolores laborum magnam esse illo rem ipsum, temporibus
          corrupti!
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Unde omnis aliquid labore
          <br> voluptatibus, pariatur quae odit itaque nobis cum consequuntur? Asperiores dolores laborum magnam esse
          illo
          rem ipsum, temporibus corrupti! Lorem ipsum, dolor sit amet consectetur adipisicing elit. Unde omnis aliquid
          labore
          voluptatibus, pariatur quae odit itaque nobis cum consequuntur? Asperiores dolores laborum
          <br> magnam esse illo rem ipsum, temporibus corrupti! Lorem ipsum, dolor sit amet consectetur adipisicing
          elit.
          Unde omnis aliquid labore voluptatibus, pariatur quae odit itaque nobis cum consequuntur? Asperiores dolores
          laborum
          magnam esse illo rem ipsum, temporibus corrupti! Lorem ipsum, dolor sit amet consectetur
          <br> adipisicing elit. Unde omnis aliquid labore voluptatibus, pariatur quae odit itaque nobis cum
          consequuntur?
          Asperiores dolores laborum magnam esse illo rem ipsum, temporibus corrupti! Lorem ipsum, dolor sit amet
          consectetur
          adipisicing elit. Unde omnis aliquid labore voluptatibus, pariatur quae odit itaque nobis cum consequuntur?
          Asperiores
          dolores laborum magnam esse illo rem ipsum, temporibus
          <br> corrupti! Lorem ipsum, dolor sit amet consectetur adipisicing elit. Unde omnis aliquid labore
          voluptatibus,
          pariatur quae odit itaque nobis cum consequuntur? Asperiores dolores laborum magnam esse illo rem ipsum,
          temporibus
          corrupti!
        </p>
      </div>
      <div class="modal-footer flex-jc-fs">
        <button type="button" class=" button button-s" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
