import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/http/api.service';
import { UtilService } from 'src/app/services/util.service';
import { AccordianService } from '../../../services/accordian.service';

@Component({
  selector: 'app-courses-card',
  templateUrl: './courses-card.component.html',
  styleUrls: ['./style/courses-card-sp.component.less']
})
export class CoursesCardComponent implements OnInit {
  coursesData:any=[];
  constructor(
    private courseList: AccordianService,
    private util:UtilService,
    private api:ApiService
  ) { }

  ngOnInit() {
    this.util.courses.subscribe(data=>{
      this.coursesData = data;
    })
    // this.api.getCourses().subscribe(res=>{
    //   this.coursesData = res.body.filter(course=>course.status == "Active");
    // });
  }
}
