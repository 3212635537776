<div class="container  min-h-100">
  <div class="space-200 formBg">
    <div class="row justify-content-md-center">
      <div class="col-md-12">
        <h3>Create Password</h3>
        <div class="card">
          <div class="card-body">
            <form>
              <div class="form-row row" >
              <div class="form-group col-md-4">
                <label>Set Password</label>
                <input type="password" class="form-control" id="setPassword" placeholder="Enter Password">
              </div>
              <div class="form-group  col-md-4">
                  <label>Confirm Password</label>
                  <input type="password" class="form-control" id="confPassword" placeholder="Confirm Password">
                </div>
                <div class="form-group  col-md-4">
                    <label class="sr-only">Button</label>
                    <button type="submit" class="button button-1 space-25">Create Password</button>
                  </div>
              </div>
                <div class="text-center">
                  
                </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="space-30 displayProdc" >
    <h3>Our Products</h3>
    <div class=" validateMessage">
     <p>Please validate your E-Mail ID to access subscribed apps.</p>
    </div>
    <section class="mail-verg flex-container flex-pc flex-jc-c prodcards">
      <div class="prodLinks flex-w-w">
        <img class="img-fluid" src="assets/images/applicationLogos/ritelogo-campus.png">
        <div class="text-center"><h3>RITE Accounts</h3></div>
        <div class="text-right"><a href="#" class="disabled"><i class="fa fa-long-arrow-right"><!-- --></i></a></div>
      </div>
      <div class="prodLinks flex-w-w">
        <img class="img-fluid" src="assets/images/applicationLogos/ritelogo-campus.png">
        <div class="text-center"><h3>RITE Campus</h3></div>
        <div class="text-right"><a href="#"  class="disabled"><i class="fa fa-long-arrow-right"><!-- --></i></a></div>
      </div>
      <div class="prodLinks flex-w-w">
        <img class="img-fluid" src="assets/images/applicationLogos/logo-blue.png">
        <div class="text-center"><h3>Skill Passport</h3></div>
        <div class="text-right"><a href="#" class="disabled"><i class="fa fa-long-arrow-right"><!-- --></i></a></div>
      </div>
      <div class="prodHelp flex-w-w">
        <img class="img-fluid" src="assets/images/applicationLogos/ritelogo-campus.png">
        <div class="text-center"><h3>Help</h3></div>
        <div class="text-right"><a href="#"><i class="fa fa-long-arrow-right"><!-- --></i></a></div>
      </div>
    </section>
  </div>
</div>