<div class="container-fluid mb-4">
  <div class="row">
    <div class="col-md-4 col-sm-6">
      <div class="form-group">
        <label for="userType">User Type</label>
        <select
          name=""
          id="userType"
          class="form-control"
          [(ngModel)]="userType"
          (change)="onUserTypeChange()"
        >
          <option value="STUDENT" selected>Student</option>
          <option value="PROFESSIONAL">Professional</option>
          <option value="ACADEMIC">Academic</option>
          <option value="ORGANIZATION">Organization</option>
        </select>
      </div>
    </div>
  </div>
</div>
<div *ngIf="userType == 'STUDENT'">
  <table
    id="student"
    class="table table-striped table-bordered"
    style="width: 100%"
  >
    <thead>
      <tr>
        <th>Name</th>
        <th>mobile</th>
        <th>Email</th>
        <th>Query</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of userQueryDetails">
        <td>{{ item.name }}</td>
        <td>{{ item.mobile }}</td>
        <td>{{ item.email }}</td>
        <td>{{ item.query }}</td>
      </tr>
    </tbody>
  </table>
</div>
<div *ngIf="userType == 'PROFESSIONAL'">
  <table
    id="professional"
    class="table table-striped table-bordered"
    style="width: 100%"
  >
    <thead>
      <tr>
        <th>Name</th>
        <th>mobile</th>
        <th>Email</th>
        <th>Query</th>
        <th>Work Experience</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of userQueryDetails">
        <td>{{ item.name }}</td>
        <td>{{ item.mobile }}</td>
        <td>{{ item.email }}</td>
        <td>{{ item.query }}</td>
        <td>{{ item.workExperience }}</td>
      </tr>
    </tbody>
  </table>
</div>
<div *ngIf="userType == 'ACADEMIC'">
  <table
    id="academic"
    class="table table-striped table-bordered"
    style="width: 100%"
  >
    <thead>
      <tr>
        <th>Name</th>
        <th>mobile</th>
        <th>Email</th>
        <th>Query</th>
        <th>Name of the Institution</th>
        <th>Location</th>
        <th>No of Students</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of userQueryDetails">
        <td>{{ item.name }}</td>
        <td>{{ item.mobile }}</td>
        <td>{{ item.email }}</td>
        <td>{{ item.query }}</td>
        <td>{{ item.nameOfTheInstitution }}</td>
        <td>{{ item.location }}</td>
        <td>{{ item.noOfStudents }}</td>
      </tr>
    </tbody>
  </table>
</div>
<div *ngIf="userType == 'ORGANIZATION'">
  <table
    id="organization"
    class="table table-striped table-bordered"
    style="width: 100%"
  >
    <thead>
      <tr>
        <th>Name</th>
        <th>mobile</th>
        <th>Email</th>
        <th>Query</th>
        <th>Name of the Organization</th>
        <th>Location</th>
        <th>No of Employees</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of userQueryDetails">
        <td>{{ item.name }}</td>
        <td>{{ item.mobile }}</td>
        <td>{{ item.email }}</td>
        <td>{{ item.query }}</td>
        <td>{{ item.nameOfTheOrganization }}</td>
        <td>{{ item.location }}</td>
        <td>{{ item.noOfEmployees }}</td>
      </tr>
    </tbody>
  </table>
</div>
