
  <button class="btn btn-primary mb-3" data-toggle="modal" data-target="#faqModal" style="width: 40px;height: 40px;">+</button>

<table
  id="faq"
  *ngIf="faqList"
  class="table table-striped table-bordered"
  style="width: 100%"
>
  <thead>
    <tr>
      <th style="width: 40px;">S No</th>
      <th>Question</th>
      <th>Answer</th>
      <th style="width: 80px; margin: 0 10px;">Action</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of faqList; let i = index">
      <td style="width: 40px;">{{ i + 1 }}</td>
      <td>{{ item.question }}</td>
      <td>
        {{ item.answer }}
      </td>
      <td style="width: 80px; margin: 0 10px;" >
        <div class="d-flex align-items-center justify-content-between actions">
        <i
          *ngIf="item.status == 'Active'"
          class="fa fa-check-circle-o active"
          (click)="toggleStatus(item.id)"
          aria-hidden="true"
          title="Status"
        ></i>
        <i
          *ngIf="item.status == 'Inactive'"
          class="fa fa-times-circle-o inactive"
          (click)="toggleStatus(item.id)"
          aria-hidden="true"
          title="Status"
        ></i>
        <i
          class="fa fa-pencil edit"
          (click)="toEdit(item.id)"
          aria-hidden="true"
          title="Edit"
        ></i>
        <i
          class="fa fa-trash delete"
          (click)="toDelete(item.id)"
          aria-hidden="true"
          title="Delete"
        ></i>
      </div>
      </td>
    </tr>
  </tbody>
</table>

<div class="modal" id="faqModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle"> {{ isupdateMode ? "Update FAQ" : "Add FAQ" }}</h5>
        <button type="button" id="closemodal" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container-fluid">
          <form class="row" [formGroup]="faqForm" (ngSubmit)="onFormSubmit()">
            <div class="form-group col-md-4">
              <label for="">Question <span class="text-danger">*</span> </label>
              <input type="text" class="form-control" formControlName="question" />
            </div>
            <div class="form-group col-md-4">
              <label for="">Answer <span class="text-danger">*</span></label>
              <input type="text" class="form-control" formControlName="answer" />
            </div>
            <div class="col-md-4 form-group d-flex align-items-end">
              <button [disabled]="this.faqForm.invalid" class="btn btn-primary" type="submit">
                {{ isupdateMode ? "Update" : "Add" }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>