<div class="banner">
  <h2>Payment</h2>
  <!-- <h4>A student… A job seeker… A career aspirant…</h4> -->
</div>
<section
  class="section p-5 section--contact-info section--bg-white-smoke"
  data-bg="white-smoke"
>
<form class="d-none" id="paymentform" [action]="paymentUrl" method="POST">
  <input type="text" name="key" placeholder="key value"> <br>
  <input type="text" name="hash" placeholder="hash value"> <br>
  <input type="text" name="txnid" placeholder="txnid value"> <br>
  <input type="text" name="amount" placeholder="amount value"> <br>
  <input type="text" name="firstname" placeholder="firstname value"> <br>
  <input type="text" name="email" placeholder="email value"> <br>
  <input type="text" name="phone" placeholder="phone value"> <br>
  <input type="text" name="productinfo" placeholder="productinfo value"> <br>
  <input type="text" name="surl" placeholder="surl value"> <br>
  <input type="text" name="furl" placeholder="furl value"> <br>
  <input type="text" name="udf1" placeholder="udf1 value"> <br>
  <!--<input type="text" name="salt" placeholder="salt value"> <br>-->
  <!--<input type="text" name="orderId" placeholder="orderId value"> <br>-->
  <!-- <input type="text" name="paymentPageUrl" placeholder="paymentPageUrl value"> <br> -->
 <!-- <input type="text" name="lastname" placeholder="lastname value"> <br>
  <input type="text" name="curl" placeholder="curl value"> <br>
  <input type="text" name="address1" placeholder="address1 value"> <br>
  <input type="text" name="address2" placeholder="address2 value"> <br>
  <input type="text" name="city" placeholder="city value"> <br>
  <input type="text" name="state" placeholder="state value"> <br>
  <input type="text" name="country" placeholder="country value"> <br>
  <input type="text" name="zipcode" placeholder="zipcode value"> <br>-->
  <!--<input type="text" name="udf2" placeholder="udf2 value"> <br> -->
  <!-- <input type="text" name="returnUrl" placeholder="returnUrl value"> <br> -->
</form>
  <div class="container form-container">
    <div class="sender">
      
      <!-- <div class="form-confirmation">
        <div class="form-confirmation__wrapper">
          <div class="form-confirmation__arrow">
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="150px"
              height="150px"
              viewBox="0 0 150 150"
              xml:space="preserve"
            >
              <polyline
                class="form-confirmation__arrow--svg"
                fill="none"
                stroke="#ff5300"
                stroke-linejoin="round"
                stroke-linecap="round"
                stroke-width="12"
                stroke-miterlimit="10"
                points="22,70 60,110 126,25"
              ></polyline>
            </svg>
          </div>
          <div class="form-confirmation__text">
            <p class="form-confirmation__title">Got it!</p>
            <p class="form-confirmation__message">
              Keep an eye on your inbox. We’ll be in touch shortly.
            </p>
            <p class="form-confirmation__message">
              Meanwhile, you can explore our hottest case studies and read
              client feedback on Clutch.
            </p>
            <p
              class="form-confirmation__message form-confirmation__message--mob"
            >
              Keep an eye on your inbox. We’ll be in touch shortly. Meanwhile,
              you can explore our hottest case studies and read client feedback
              on Clutch.
            </p>
          </div>
          <a
            class="btn btn--arrow feedback-link"
            href="https://clutch.co/profile/yalantis"
            rel="nofollow"
            target="_blank"
            >See our Clutch reviews</a
          >
        </div>
      </div> -->
      <div class="sender__content">
        <div class="row">
          <div class="col col-md-6">
            <div class="section__heading">
              <h1 class="section__title">Got a project<br />in mind?</h1>
              <p class="section__intro">
                Share the details of your project – like scope, timeframes, or
                business challenges you'd like to solve. Our team will carefully
                study them and then we’ll figure out the next move together.
              </p>
            </div>
          </div>
          <div class="col col-md-6">
            <form
              ngForm
              method="POST"
              [formGroup]="checkoutForm"
              (ngSubmit)="onSubmit()"
              class="form"
            >
              <div class="row">
                <div class="col col-sm-6">
                  <div class="form-field p-0">
                    <label>First Name</label>
                    <input
                      [ngClass]="{'is-invalid': checkoutForm.get('firstName').errors && (checkoutForm.get('firstName').touched || checkoutForm.get('firstName').dirty)}"
                      formControlName="firstName"
                      class="form-control"
                      tabindex="1"
                      type="text"
                      name="firstName"
                      id="contact_firstname"
                      autocomplete="off"
                    />
                    <span *ngIf="checkoutForm.get('firstName').errors && (checkoutForm.get('firstName').touched || checkoutForm.get('firstName').dirty)" class="text-danger"><small>
                      First Name is required</small></span>
                  </div>
                </div>
                <div class="col col-sm-6">
                  <div class="form-field p-0">
                    <label>Last Name</label>
                    <input
                      [ngClass]="{'is-invalid': checkoutForm.get('lastName').errors && (checkoutForm.get('lastName').touched || checkoutForm.get('lastName').dirty)}"
                      formControlName="lastName"
                      class="form-control"
                      name="lastName"
                      tabindex="2"
                      type="text"
                      id="lastName"
                      autocomplete="off"
                    />
                    <span *ngIf="checkoutForm.get('lastName').errors && (checkoutForm.get('lastName').touched || checkoutForm.get('lastName').dirty)" class="text-danger">
                      <small>Name is required</small></span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col col-sm-6">
                  <div class="form-field p-0">
                    <label>Organization</label>
                    <input
                      [ngClass]="{'is-invalid': checkoutForm.get('organization').errors && (checkoutForm.get('organization').touched || checkoutForm.get('organization').dirty)}"
                      formControlName="organization"
                      class="form-control"
                      name="organization"
                      tabindex="3"
                      type="text"
                      id="organization"
                      autocomplete="off"
                    />
                    <span *ngIf="checkoutForm.get('organization').errors && (checkoutForm.get('organization').touched || checkoutForm.get('organization').dirty)" class="text-danger"><small>Organization is required</small></span>
                  </div>
                </div>
                <div class="col col-sm-6">
                  <div class="form-field p-0">
                    
                    <label>City</label>
                    <input
                      [ngClass]="{'is-invalid': checkoutForm.get('city').errors && (checkoutForm.get('city').touched || checkoutForm.get('city').dirty)}"
                      formControlName="city"
                      class="form-control"
                      name="city"
                      tabindex="4"
                      type="text"
                      id="city"
                      autocomplete="off"
                    />
                    <span *ngIf="checkoutForm.get('city').errors && (checkoutForm.get('city').touched || checkoutForm.get('city').dirty)" class="text-danger"><small>City is required</small></span>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col col-sm-6">
                  <div class="form-field p-0">
                    <label>State</label>
                    <input
                      [ngClass]="{'is-invalid': checkoutForm.get('state').errors && (checkoutForm.get('state').touched || checkoutForm.get('state').dirty)}"
                      formControlName="state"
                      class="form-control"
                      name="state"
                      tabindex="5"
                      type="text"
                      id="state"
                      autocomplete="off"
                    />
                    <span *ngIf="checkoutForm.get('state').errors && (checkoutForm.get('state').touched || checkoutForm.get('state').dirty)" class="text-danger"><small>State is required</small></span>
                  </div>
                </div>
                <div class="col col-sm-6">
                  <div class="form-field p-0">
                    <label>Email</label>
                    <input
                      [ngClass]="{'is-invalid': checkoutForm.get('email').errors && (checkoutForm.get('email').touched || checkoutForm.get('email').dirty)}"
                      formControlName="email"
                      class="form-control"
                      name="email"
                      tabindex="6"
                      type="email"
                      id="email"
                      autocomplete="off"
                      />
                      <span *ngIf="checkoutForm.get('email').errors && (checkoutForm.get('email').touched || checkoutForm.get('email').dirty)" class="text-danger">
                        <span *ngIf="checkoutForm.get('email').errors.required || checkoutForm.get('email').invalid"><small>Email is required e.g: ex@eg.com</small></span>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col col-sm-6">
                    <div class="form-field p-0">
                      <label>Phone</label>
                      <input
                      [ngClass]="{'is-invalid': checkoutForm.get('phone').errors && (checkoutForm.get('phone').touched || checkoutForm.get('phone').dirty)}"
                      formControlName="phone"
                      class="form-control"
                      name="phone"
                      tabindex="7"
                      type="number"
                      (input)="checkMobile($event)"
                      id="phone"
                      autocomplete="off"
                      maxlength="10"
                      />
                      <!-- (focusout)="convertPhoneNumber($event, 3, 6)" -->
                    <span *ngIf="checkoutForm.get('phone').errors && (checkoutForm.get('phone').touched || checkoutForm.get('phone').dirty)" class="text-danger">
                      <span *ngIf="checkoutForm.get('phone').errors.required || checkoutForm.get('phone').invalid"><small>Phone is required e.g: 999-999-9999</small></span>
                    </span>
                  </div>
                </div>
                <div class="col col-sm-6">
                  <div class="form-field p-0">
                    <label>Amount</label>
                    <input
                      [ngClass]="{'is-invalid': checkoutForm.get('amount').errors && (checkoutForm.get('amount').touched || checkoutForm.get('amount').dirty)}"
                      formControlName="amount"
                      class="form-control"
                      name="amount"
                      tabindex="8"
                      type="number"
                      id="amount"
                      maxlength="10"
                      autocomplete="off"
                    />
                    <span *ngIf="checkoutForm.get('amount').errors && (checkoutForm.get('amount').touched || checkoutForm.get('amount').dirty)" class="text-danger">
                      <span *ngIf="checkoutForm.get('amount').errors.required"><small>Amount is required</small></span>
                      <span *ngIf="checkoutForm.get('amount').errors.pattern"><small>Amount should be greater than 0</small></span>
                    </span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <label>Message <small>(Optional)</small></label>
                  <div class="form-field p-0">
                    <input
                    class="form-control"
                    name="message"
                    tabindex="9"
                    type="text"
                    id="message"
                    />
                  </div>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col form-controls text-center">
                  <input
                  style="border-color: #0fa4b9 !important;"
                    type="submit"
                    name="commit"
                    value="Pay"
                    class="btn btn--bordered"
                    id="btnSend"
                    tabindex="14"
                    data-disable-with="Pay"
                    [disabled]="!checkoutForm.valid"
                  />
                </div>

                <!-- <button type="submit" class="btn btn--primary" style="background: #f07615;color: #f8f9fa;">Submit</button> -->
              </div>

              <!-- <div class="row text-center mt-5">
                                <div class="col-lg-12 form-group">
                       
                          <button type="submit" class="btn btn--primary" style="background: #f07615;color: #f8f9fa;">Submit</button>
                        
                        </div></div> -->
            </form>
            <div *ngIf="isPaymaentError" class="alert alert-danger mt-3">
              <strong>Oops! </strong>Cannot make payment at this moment. Please try again later.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>