export class EmployeerRegistrationModel{
    constructor(
        public details: EmployeeDetailModel,
        public address: string,
        public address2: string,
        public address3: string,
        public state: EmployeeStateModel,
        public country: EmployeeCountryModel,
        public city: string,
        public locality: string,
        public postcode: string,
        public phone: string,
        public mobile: string,
        public email: string,
        public website: string,
        public contactDetails: EmployeeContactModel,
        public username: string,
        public password: string,
        public conformPassword: string
    ){}
}

export class EmployeeDetailModel{
    constructor(
        public registeredWith: string,
        public registrationNumber: string,
        public organisationName: string,
        public formerOrganisationName: string,
        public organisationType: string,
        public sector: string,
        public organisationPan: string,
        public description: string,
        public yearOfIncorporation: string,
        public areasOfOperation: string,
        public keyActivities: string
    ){}
}

export class EmployeeStateModel {
    constructor(
        public id: number,
        public stateName: string ,
        public status: string,
        public dateCreated: number,
        public code: string
    ){}
}
export class EmployeeCountryModel {
    constructor(
        public id: number,
        public countryName: string,
        public status: string,
        public countryType: string

    ){}
}
// export class EmployeeDistrictModel {
//     constructor(
//         public id: number,
//         public name: string,
//         public status: string,
//         public state: EmployeeStateModel
//     ){}
// }

export class EmployeeContactModel{
    constructor(
        public identificationType: string,
        public identificationNumber: string,
        public contactPersonName: string,
        public guardianName: string,
        public birthDate: string,
        public contactNumber: string,
        public mobileNumber: string,
        public contactEmail: string,
        public contacPersonDesignation: string
    ){}
}