<div>
  <section class="section" id="section-0">
    <app-slider1></app-slider1>
    <!-- <app-counter></app-counter> -->
  </section>

  <!-- ================================ -->
  <div class="bg-2">
  <section class="section flex-container min-h-100 flex-pc" id="section-1">
    <app-workflow class="width-100"></app-workflow>
  </section>

  <!-- ================================ -->

  <section class="section min-h-100 flex-container flex-pc" id="section-2">
    <div class="">
      <app-courses-card class="width-100 padding-d-100"></app-courses-card>
    </div>
  </section>
</div>
  <!-- ================================ -->

  <section class="section" id="section-8">
    <div class="width-100  flex-pc" id="subscription-form">
      <app-query></app-query>
    </div>
  </section>

  <!-- ================================ -->

  <section id="section-3"></section>

  <!-- ================================ -->
</div>