import { Component, OnInit } from '@angular/core';
import { AccordianService } from './../../services/accordian.service';
declare var $: any;
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.less']
})
export class FAQComponent implements OnInit {

  faqList: any;
  constructor(
    private accordianService: AccordianService
  ) {

  }

  ngOnInit() {
    this.faqList = this.accordianService.getSupportData();
    $(document).ready(function () {
      $('.collapse').on('shown.bs.collapse', function () {
        $(this).parent().find(".fa-plus").removeClass("fa-plus").addClass("fa-minus");
      }).on('hidden.bs.collapse', function () {
        $(this).parent().find(".fa-minus").removeClass("fa-minus").addClass("fa-plus");
      });
    });
  }

}
