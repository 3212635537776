import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.less']
})
export class TeamComponent implements OnInit {

  constructor() { }
  teamList = [

    {
      id: 0,
      name: 'Ravi Kiran Vadlamani',
      imgSrc: 'assets/images/team/Ravi Kiran Photograph2.jpg',
      designation: 'Managing Partner',
      linkedIn: 'https://www.linkedin.com/in/ravikiranvvss',
      bio: [
        'Ravi Kiran brings over 2 decades of overall experience in public accounting, providing assurance and advisory services to multinational SEC-registered Foreign Private Issuers. Having served as a Director with a global Big 4 firm, Ravi Kiran is the ‘Man Friday’ domain specialist when it comes to our collaborations.',
        'He has served global giants in a wide range of industries across many nations. His incisive engagement has been highly promoted as advisor on the application of U.S. Generally Accepted Accounting Principles (GAAP), IFRS and the SEC’s regulations in connection with U.S. capital markets transactions.'
      ]
    },
    {
      id: 1,
      name: 'Ravi Krovvidi',
      imgSrc: 'assets/images/team/Ravi Sir cropped.jpg',
      designation: 'Managing Partner',
      linkedIn: 'https://www.linkedin.com/in/ravi-krovvidi-8a645a13/',
      bio: [
        'Ravi Krovvidi with an astounding career spanning over two decades in the area of Finance & Accounting. He is a tenured Finance & Accounting professonal with a varied industry experience with global Pharma, IT and Service companies.',
        'He is an SME on Financial Planning & Analysis process equipped with wide and extensive insight on F&A domain coupled with business acumen on Service Delivery and Process Transformation. Ravi is instrumental in building ‘4AT Consulting’ as a brand that envisions partnering for success. Having worked in USA and Australia, Ravi has profound understanding of cultural assimilation that plays a key role in strategic partnerships.'
      ]
    },
    // {
    //   id: 2,
    //   name: 'Ron Ramakrishnan',
    //   imgSrc: 'assets/images/team/Ron1.jpg',
    //   designation: 'Operations & HR',
    //   linkedIn: 'https://www.linkedin.com/in/ramakrishnan-s',
    //   bio: [
    //     'With years of niche experience in client services and transition, Ron brings his rich experience onboard to build efficient and effective operations. His tenure with some global corporate houses for client management and SLA driven service delivery emphasizes on seamless execution.'
    //   ]
    // }
  ]
  ngOnInit() {
  }

}
