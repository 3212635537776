import { Component, OnInit , Inject} from '@angular/core';
import { Router } from '@angular/router';
import { DmService } from '../../../dmarketing/dm.service';

declare var $:any;
@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.less']
})
export class OrganizationComponent implements OnInit {

  talentMangegementList = [
    "Let us know your requirements i.e. short-term or long-term, experience and specific skills if any.",
    "Browse through the existing repository of profiles short listed based on skills.",
    "Select suitable profiles and continue with your recruitment process.",
    "If no profiles match your needs, we will train the closest suitable profiles to your needs and follow step 3.",
    "Finalize terms & conditions and sign the contract.",
  ]
  constructor( 
    private router: Router,
    private dmdata: DmService
  ) {
    this.dmdata.setDmTags('organization');
  }

  ngOnInit() {
    $(document).ready(function(){
      const $cardHeader = $(".banner-tabs");
      const eletop = $cardHeader.offset().top;
      
      $(document).on("scroll", checkSlide);

      function checkSlide(){
        var wintop = scrollY;
        if(wintop > ( eletop - 60 )){
          $cardHeader.addClass("sticky");
        }
        else {
          $cardHeader.removeClass("sticky");
        }
      }
      
      $(".banner-tabs .nav-link").on("click", function(){
        $('html, sbody').animate({scrollTop: eletop - 70},'slow');
      })
    });
  }


  startTrialBtn(){
    new Promise((resolve, reject)=>{
      this.router.navigate(["/"]);
      // resolve();
    }).then(
      ()=>{
        setTimeout(() => {
          $('html,body').animate({scrollTop: $("#section-8").offset().top},'slow');
        }, 400);
      }
    );
  }
}
