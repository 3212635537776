<div class="container">
  <div class="row justify-content-center align-items-center" style="min-height: calc(100vh - 90px);">
    <div class="col-md-6 col-lg-6 col-sm-12">
      <form class="p-md-5 p-sm-3 admin-form mx-auto" [formGroup]="adminloginForm" (ngSubmit)="onFormSubmit(adminloginForm.value)">
        <h4 class="text-center mb-5">Admin login</h4>
        <div class="form-group">
          <label for="email">User name  <span class="text-danger">*</span> </label>
          <input
            type="text"
            class="form-control"
            id="email"
            aria-describedby="emailHelp"
            placeholder="Enter email"
            formControlName="email"
          />
          <small class="text-danger" *ngIf="adminloginForm.get('email').invalid && adminloginForm.get('email').touched">Invalid email</small>
        </div>
        <div class="form-group" style="position: relative;">
          <label for="password">Password <span class="text-danger">*</span></label>
          <input
            type="password"
            class="form-control"
            id="password"
            placeholder="Password"
            formControlName="password"
          />
          <small class="text-danger" *ngIf="adminloginForm.get('password').invalid && adminloginForm.get('password').touched">Invalid email</small>

          <i class="fa fa-eye password-icon" *ngIf="!isPassVisible" (click)="passwordToggle()" aria-hidden="true"></i>
          <i class="fa fa-eye-slash password-icon" aria-hidden="true" *ngIf="isPassVisible" (click)="passwordToggle()"></i>
        </div>
        <div class="text-center">
          <button type="submit" class="as-button" [disabled]="adminloginForm.invalid" >Submit</button>
        </div>
      </form>
    </div>
  </div>
</div>
