import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccordianService } from 'src/app/services/accordian.service';
import { ApiService } from 'src/app/services/http/api.service';
import { UtilService } from 'src/app/services/util.service';

declare var $:any;
@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.less']
})
export class SideNavComponent implements OnInit ,AfterViewInit{
  coursesData:any=[];
  constructor(
    private courseList: AccordianService,
    private router:Router,
    private api:ApiService,
    private util:UtilService
  ) { }
  ngAfterViewInit(): void {
    var courseButton = document.querySelector('.course-button');
    var dimmer = document.querySelector('.dimmer');
   
    courseButton.addEventListener('click',(e)=>{
    this.onCourseClick();
    })
    dimmer.addEventListener('click',(e)=>{
    this.onCourseClick();
    })
  }

  ngOnInit() {
    this.util.courses.subscribe(data=>{
      this.coursesData = data;
    })
    // this.api.getCourses().subscribe(
    //   (res) => {
    //     if (res.status == 200) {
    //       this.coursesData = res.body.filter(course=>course.status=="Active");
    //     }
    //   },
    //   (err) => {
    //     console.log(err);
    //   }
    // );
    // this.coursesData = this.courseList.getCoursesData();
  }
  onCourseClick(){
    var dimmer = document.querySelector('.dimmer');
    var sideNav = document.getElementById('side-nav-conatainer');
    sideNav.classList.toggle('open');
    dimmer.classList.toggle('open');
    document.getElementById("header-container").style.top = "0px";
  }
  toCourse(id) {
    this.onCourseClick()
    this.router.navigate(['/course-detail'], { queryParams: { id: id } })
  }
}
