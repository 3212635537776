import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-student-individual",
  templateUrl: "./student-individual.component.html",
  styleUrls: ["./student-individual.component.less"],
})
export class StudentIndividualComponent implements OnInit {
  constructor() { }

  assessmentHelp = [
    {
      data:
        "Assess your existing knowledge and proficiency with focus on identifying the areas which needs intervention.",
      classes: [
        "corner-top-left",
        "corner-bottom-right"
      ]
    },
    {
      data:
        "Wide array of domain areas that are relevant enabling the training need identification analysis more accurate.",
      classes: [
        "corner-bottom-left",
        "corner-top-right"
      ]
    },
    {
      data:
        "Deep insight on developmental areas along with analysis on areas in which the student is proficient.",
      classes: [
        "corner-bottom-left",
        "corner-top-right"
      ]
    },
    {
      data:
        "Get deep dive analysis on performance coupled with rational proposition on learning intervention on developmental areas.",
      classes: [
        "corner-top-left",
        "corner-bottom-right",
      ]
    },
  ];
  ngOnInit() { }
}
