import { Component, OnInit , Inject} from '@angular/core';

declare var $:any;
@Component({
  selector: 'app-carousel2',
  templateUrl: './carousel2.component.html',
  styleUrls: ['./carousel2.component.less']
})
export class Carousel2Component implements OnInit {
  items: Array<any> = []

  constructor() {
    this.items = [
      {
        title: 'GST Practitioner Expert',
        course: [
          'Introduction to G.S.T',
          'Scope of Supply',
          'Levy and Collection',
          'Composite and mixed supply',
          'Time & place of supply of goods',
          'Change in rate of tax',
          'Value of taxable supply',
          'Conditions for ITC',
          'Tax-invoice, credit & debit notes',
          'Accounts & records',
          'Returns',
          'Payment of Tax, TDS & TCS',
          'Miscellaneous',
          'Refund of Tax',
          'Assessment',
          'Audit',
          'Inspection, search, seizure & arrest',
          'Demands and recovery',
          'Liability to pay in certain cases',
          'Advance ruling',
          'Appeals & Revisions',
          'Offence & penalities',
          'Transistional provisions',
          'Miscellaneous provisions',
          'Integrated goods and service tax',
          'Union territory goods and service tax',
          'GST compensation to states',
          'Over-view of GST'
        ]
      },
      {
        title: 'Practical Finance and Accounting Expert',
        course: [
          'Accounting system overview',
          'Accounting equation',
          'Accounting Rules',
          'Recording of transactions',
          'Preparation and presentation of financial statements',
          'Accounting Procedure',
          'Ledger Scrunity',
          'Reconciliation Statement',
          'Bank Reconciliation statement',
          'Debtors / Creditors reconciliation',
          'Cash flow statement',
          'Purchase process',
          'Sales process',
          'Depreciation schedule',
          'E-Filing',
          'Excel basics'
        ]
      },
      {
        title: 'Payroll Accounting and Compliance Expert' ,
        course: [
          'Payroll Terminology',
          'Basic Payroll process',
          'Principles of earnings, deduction ',
          'Procedure to calculate statutory deductions',
          'Statutory deduction e- filings',
          'Introduction to Payroll reports',
          'Accounting procedure'
        ]
      },
      {
        title: 'Indian Accounting standards and application Expert',
        course: [
          'Indian accounting standards',
          'Disclosure and presentation of Financial statements',
          'Accounting policies and fair value',
          'Asset based Standards',
          'Standards impacting items of balance-sheet and profit and loss account',
          'Standards applicable on group companies ',
          'Miscellaneous standards',
          'Difference between Indian accounting standards and IFRS'         
        ]
      },
      {
        title: 'Tax Accounting and Compliance Expert',
        course: [
          'Direct Tax Computation',
          'Indirect Tax Calculation',
          'Accounting for Direct Tax',
          'Accounting for Indirect Tax',
          'Direct Tax filings',
          'Indirect Tax Filings'
        ]
      },
      {
        title: 'Record to Report (R2R) Expert',
        course: [
          'General ledger accounting',
          'Financial statements preparation',
          'Bank reconciliations',
          'Fixed Assets Management',
          'Budgeting and planning maintenance',
          'Project costing',
          'Inter-company accounting',
          'Management reporting'
        ]
      },
      {
        title: 'Statutory and Audit Compliance Expert',
        course: [
          'Internal Controls and Internal Audit',
          'IT Audit',
          'IFRS Guidelines',
          'Audit Reporting',
          'Risk Audit',
          'System and application audit',
          'GAAP and IAS guidelines',
          'Audit Sampling',
          'Transaction Audit',
          'SOX audit guidelines',
          'Audit planning',
          'Audit of Corrective actions and follow-up',
          'Audit conclusions'
        ]
      },
      {
        title: 'Fixed Asset Accounting and Compliance Expert',
        course: [
          'Classification of assets',
          'Capital work in progress',
          'Components of cost',
          'Net block vs gross block',
          'Retirement and disposal',
          'Tangible and Intangible assets',
          'Capitalisation',
          'Revaluation',
          'Assets grouping and sub-ledger concepts',
          'Procurement process of movable assets',
          'Depreciation',
          'Accounting treatment'
        ]
      },
      {
        title: 'P2P',
        course: [
          'Vendor Management',
          'Procurement Process',
          'Service and goods classification',
          'Non P.O. based vendors',
          'Vendor Invoice accounting',
          'Vendor queries calls & emails',
          'E - Invoicing & Manual Invoicing',
          'Self - billing',
          'Payment Processing',
          'Direct debit payments',
          'Duplicate payments',
          'DOP and ACH analysis',
          'Vendor account reconciliation',
          'Travel expenses accouting & payment',
          'Debit / Credit notes',
          'Ageing reporting',
          'Invoice cycle time',
          'First pass yield',
          '3 way & 2 way match',
          'Good receipt invoice receipt (GRIR)'
        ]
      },
      {
        title: 'OTC',
        course: [
          'Customer Order Processing',
          'Credit Terms Customer billing',
          'Disputes and resolutions',
          'Customer Queries',
          'Cash Application',
          'Account Reconciliation',
          'DSO analysis and improvement',
          'Ageing report',
          'Dunning',
          'Factoring',
          'Unapplied receipts',
          'On-accounting reports',
          'Broken promises and follow-up',
          'Collection process ',
          'Debit / Credit notes',
          'Cash & Trade discounts',
          'Tax requirements'
        ]
      }
    ]
  }

  ngOnInit() {
    $(document).ready(function(){
      const $slidebox = $(".slider-box"); 
      const $slide = $(".slider-box .slider"); 
      const $ctrl = $(".slider-box .control.left"); 
      const $ctrr = $(".slider-box .control.right");
      $ctrl.hide();
      // const slideCount1024 = Math.floor($slidebox.width()/$slide.width());
      const slideCount1024 = 4;
      const $window = $(window);
      const totalSlides = 
        ($window.innerWidth<1024)?
        $slide.length-1:slideCount1024;
      var slidecount = 0;

      $slidebox.on("swipeleft", slideLeft);
      $slidebox.on("swiperight", slideRight);
      $ctrl.on("click", slideLeft);
      $ctrr.on("click", slideRight)

      function slideLeft(){
        $slide
        .stop()
        .animate({right: slideresult(false)*100+"%"}, 600);
        controllerCheck();
      }

      function slideRight(){
        $slide
        .stop()
        .animate({right: slideresult(true)*100+"%"}, 600);
        controllerCheck();
      }

      function slideresult(status){
        if(status)
          return slidecount<totalSlides?++slidecount:slidecount;
        else 
          return slidecount>0?--slidecount:slidecount;
      }

      function controllerCheck(){
        if(slidecount==0) $ctrl.hide();
        else if(slidecount == slideCount1024) $ctrr.hide();
        else {
          $ctrl.show();
          $ctrr.show();
        }
      }
    });
  }
}
