<div class="container-fluid">
    <div class="row mainHeader">
        <div class="col-md-2 col-sm-3">
            <div class="burger-container">
                <div class="burger">
                    <div class="burger1"></div>
                    <div class="burger2"></div>
                    <div class="burger3"></div>
                </div>
            </div>
            <!-- <i class="fa fa-bars" aria-hidden="true"></i> -->
            <a href="/" title="ArthaSiksha home">
                <img class="logo-img" alt="ArthaSiksha home" aria-labelledby="arthasiksha-logo"
                    src="../assets/images/applicationLogos/Artha_Siksha_Logo.png" />
            </a>
        </div>
    </div>
</div>