<div class="chatbot">
    <div class="chatIcon">
      <i class="fa fa-comments"></i>
      <div class="chatWindow d-none">
        <div class="chatHeading">
          <div class="flex-container flex-ai-c">
            <img src="/assets/arthaSiksha/icons/man.png" alt="mSiri" class="img-fluid">
            <h6 class="no-margin"><small>{{botname}}</small></h6>
          </div>
            <a class="closeChat">
            <!-- <i class="fa fa-times"  data-toggle="modal" (click)="showModelForEmail()" data-target="#emailPopUp"></i> -->
            <i class="fa fa-trash" title="Clear chat" (click)="clearChat()"></i>
            <i class="fa fa-times" (click)="closeChat()"></i>
            </a>
        </div>
        <div class="chatbody">
          <div class="chatList">
            <ul class="msgList">
                <li class="adminMsg">
                  <label>{{botname}}</label>
                  <div class="adMsg">
                      Hello user, i am <b>{{botname}}</b>. <br> How can I help you
                  </div>
                </li>
                <li  [ngClass]="(chatObj.messageFrom=='admin')?'adminMsg':'userMsg'"  *ngFor="let chatObj of chatHistoryMessages">
                <label>{{chatObj.messageFrom=='admin'?'MShiri' :'User'}}</label>
                <div [ngClass]="(chatObj.messageFrom=='admin')?'adMsg':'usMsg'">
                  {{ chatObj.chatMessage }}
                </div>
                </li>
            </ul>
          </div>
          <ul class="cchat" style="list-style: none;" >
            <li  (click)="submitQuestion(faq.id)" *ngFor="let faq of relatedQuestions">
            {{ faq.question }}
            </li>
          </ul>
          <div class="chatInput">
            <div class="input-group">
                <input type="text" class="form-control"  #userInput id="inputText"   (keyup)="($event.keyCode==13)? submitUserInput($event) :comapareWithQuestions($event)"  placeholder="Enter your query"/>
                <div class="input-group-append">
                  <button class="button send-button" (click)="submitUserInput(userInput)"  type="button"><i class="fa fa-paper-plane"></i></button>
                  <!-- <button class="button send-button" (click)="startRecordingVoice()" type="button"><i class="fa fa-microphone"></i></button>
                  <button class="button send-button" (click)="stopRecordingVoice()" type="button"><i class="fa fa-microphone-slash"></i></button> -->
                </div>
            </div>
          </div>
        </div>
        <div class="modal fade" role="dialog" id="emailPopUp" tab-index="-1">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                  <h6 class="modal-title">Enter your email to send the chat history</h6>
                  <button type="button" id="closeMailPopUp"  (click)="closeChat()" class="close" data-dismiss="modal">&times;</button>
              </div>
              <div class="modal-body">
                <div class="row" id="msForm">
                  <div class="col-md-9">
                    <input type="email" id="userEmailId" #userEmailAddress  class="form-control"  placeholder="Enter your email" />  
                  </div>
                  <div class="col-md-3 text-center">
                    <button class="button button-s"  (click)="sendChatHistoryToUserMail(userEmailAddress)"  type="button">Submit</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  