import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

// Webpage components
import { AboutUsComponent } from "./webpages/about-us/about-us.component";
import { ContactUsComponent } from "./webpages/contact-us/contact-us.component";
import { CoursesComponent } from "src/app/webpages/courses/courses.component";
import { PagenotfoundComponent } from "./webpages/pagenotfound/pagenotfound.component";
import { TeamComponent } from "./webpages/team/team.component";
import { AcademicComponent } from "./webpages/academic/academic.component";
import { FAQComponent } from "./webpages/faq/faq.component";
import { StudentIndividualComponent } from "./webpages/solution/individual/student-individual/student-individual.component";
import { ProfessionalIndividualComponent } from "./webpages/solution/individual/professional-individual/professional-individual.component";
import { BlogDetailsComponent } from "./webpages/blogs/blog-details/blog-details.component";
import { BlogCardComponent } from "./webpages/blogs/blog-card/blog-card.component";
import { OrganizationComponent } from "./webpages/solution/organization/organization.component";
import { FeaturesComponent } from "./webpages/features/features.component";

import { CourseDetailsComponent } from "./webpages/course-details/course-details.component";
import { AdminLoginComponent } from "./webpages/arthaSiksha/admin-panel/admin-login/admin-login.component";
import { LoginGuard } from "./services/login/login.guard";
import { AdminIndexComponent } from "./webpages/arthaSiksha/admin-panel/admin-index/admin-index.component";
import { AdminCourseControllerComponent } from "./webpages/arthaSiksha/admin-panel/admin-course-controller/admin-course-controller.component";
import { AdminRegisterDetailsComponent } from "./webpages/arthaSiksha/admin-panel/admin-register-details/admin-register-details.component";
import { AdminContactDetailsComponent } from "./webpages/arthaSiksha/admin-panel/admin-contact-details/admin-contact-details.component";
import { AdminDashboardComponent } from "./webpages/arthaSiksha/admin-panel/admin-dashboard/admin-dashboard.component";
import { AdminUserQueryComponent } from "./webpages/arthaSiksha/admin-panel/admin-user-query/admin-user-query.component";
import { RegisterComponent } from "./webpages/register/register.component";
import { StudentRegistationComponent } from "./webpages/register/student-registation/student-registation.component";
import { ProfessionalRegistrationComponent } from "./webpages/register/professional-registration/professional-registration.component";
import { AcademicRegistrationComponent } from "./webpages/register/academic-registration/academic-registration.component";
import { OrganizationRegistrationComponent } from "./webpages/register/organization-registration/organization-registration.component";
import { AdminFaqComponent } from "./webpages/arthaSiksha/admin-panel/admin-faq/admin-faq.component";

const appRoutes: Routes = [
  // { path: "", component: IndexComponent, resolve: {server: SlotbookResolverService} },
  {
    // path: "feature",
    path: "",
    component: FeaturesComponent,
  },
  { path: "courses", component: CoursesComponent },
  { path: "student", component: StudentIndividualComponent },
  { path: "professionals", component: ProfessionalIndividualComponent },
  { path: "organization", component: OrganizationComponent },
  { path: "faq", component: FAQComponent },
  { path: "team", component: TeamComponent },
  { path: "contact-us", component: ContactUsComponent },
  { path: "about-us", component: AboutUsComponent },
  { path: "academic", component: AcademicComponent },
  { path: "blogs", component: BlogCardComponent },
  { path: "blog", component: BlogDetailsComponent },
  { path: "admin-login", component: AdminLoginComponent },
  { path: "admin", redirectTo: "admin-login" },
  { path: "register", component: RegisterComponent },
  { path: "student-register", component: StudentRegistationComponent },
  {
    path: "professional-register",
    component: ProfessionalRegistrationComponent,
  },
  { path: "academic-register", component: AcademicRegistrationComponent },
  { path: "organization-register", component: OrganizationRegistrationComponent },
  {
    path: "admin-panel",
    canActivate: [LoginGuard],
    children: [
      { path: "", component: AdminIndexComponent },
      { path: "dashboard", component: AdminDashboardComponent },
      { path: "contact-details", component: AdminContactDetailsComponent },
      { path: "register-details", component: AdminRegisterDetailsComponent },
      { path: "course-data", component: AdminCourseControllerComponent },
      { path: "user-queries", component: AdminUserQueryComponent },
      { path: "faqs", component: AdminFaqComponent },
    ],
  },

  // { path: "individual", component: IndividualComponent },
  // { path: "states", component: StateLoginComponent },
  // { path: "state-login", component: StateLoginFormComponent },
  // {
  //     path: "dashboard",
  //     canActivate: [GuardService],
  //     component: DashboardComponent,
  //     children: [
  //         { path: "", component: HomeComponent },
  //         {
  //             path: "setting", component: SettingComponent, children: [
  //                 { path: "", component: SpSetupStartComponent },
  //                 { path: "sp-setup-form", component: SpSetupFormComponent }
  //             ]
  //         },
  //         { path: "software", component: SoftwareComponent }
  //     ]
  // },
  // { path: "study-programs", component: StudyProgramsComponent },
  // { path: "apply", component: ApplyComponent },
  // { path: "study-programs/card1", component: StudyProgramDetailsComponent },
  // { path: "suscribe", component: SubscriptionComponent },
  // { path: "verifydash", component: EmailverificationComponent },
  // {
  //     path: "student-enroll",
  //     component: Enrollform1Component,
  //     resolve: {
  //         coursedata: CourseDataResolver,
  //         countryList: CountryListResolver,
  //         statelist: StateListResolver
  //     }
  // },
  // {
  //     path: "trainer-enroll",
  //     component: TrainerformComponent,
  //     resolve: {
  //         coursedata: CourseDataResolver,
  //         countryList: CountryListResolver,
  //     }
  // },
  // {
  //     path: "corporate-enroll",
  //     component: EmployerformComponent,
  //     resolve: {
  //         coursedata: CourseDataResolver,
  //         countryList: CountryListResolver,
  //         statelist: StateListResolver
  //     }
  // },
  // {
  //     path: "studentRegister-enroll",
  //     component: StudentRegisterComponent,
  //     resolve: {
  //         coursedata: CourseDataResolver,
  //         countryList: CountryListResolver,
  //         statelist: StateListResolver
  //     }
  // },
  // {
  //     path: "vendor-enroll",
  //     component: VendorCreationComponent,
  //     resolve: {
  //         coursedata: CourseDataResolver,
  //         countryList: CountryListResolver,
  //         statelist: StateListResolver
  //     }
  // },
  {
    path: "course-detail",
    component: CourseDetailsComponent,
  },
  // {
  //     path: "institution-enroll",
  //     component: InstitutionRegistrationComponent,
  //     resolve: {
  //         coursedata: CourseDataResolver,
  //         countryList: CountryListResolver,
  //         statelist: StateListResolver

  //     }
  // },
  // {
  //     path: "checkout-details",
  //     component: CheckoutDetailsComponent
  // },
  // {
  //     path: "transaction-response/:message",
  //     component: TransactionResponseComponent
  // },

  { path: "pagenotfound", component: PagenotfoundComponent },
  { path: "**", redirectTo: "/pagenotfound" },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
