import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Data } from '@angular/router';
import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DmService } from 'src/app/dmarketing/dm.service';
import { ApiService } from 'src/app/services/http/api.service';
import { EmployeerRegistrationModel, EmployeeStateModel, EmployeeCountryModel, EmployeeContactModel, EmployeeDetailModel } from 'src/app/services/model/organizationRegistration.model';

declare var $:any;
@Component({
  selector: 'app-organization-registration',
  templateUrl: './organization-registration.component.html',
  styleUrls: ['./organization-registration.component.less']
})
export class OrganizationRegistrationComponent implements OnInit ,AfterViewInit{

  

  // status
  // 1: loading
  // 2: success
  // 3: warning
  // 4: error
  finalStatus: number = 1;
  statusMessage: string = 'Please wait...';
  formstep: number = 1;
  // loading: boolean = false;
  // status: boolean = true;

  empData: EmployeerRegistrationModel;
  responseStatus: any = {};
  currentPass: string = "";
  enrollForm: FormGroup;
  formValue: any = {};
  countries: any = [];
  countriestates: any = [];
  states: Array<any> = [];

  constructor(
    // private regser: RegisterService,
    config: NgbDatepickerConfig,
    private dmdata: DmService,
    private router: ActivatedRoute,
    private api: ApiService,
    // private AccordianService: AccordianService,
    private http:HttpClient
  ) {
    // this.formValue = this.AccordianService.getRegis();
    // console.log(this.formValue.userData);
    config.minDate = { year: 1900, month: 1, day: 1 };
    config.maxDate = { year: 2010, month: 12, day: 31 };
    // days that don't belong to current month are not visible
    this.dmdata.setDmTags('corporateenroll');
    config.outsideDays = 'hidden';
    // this.router.data.subscribe(
    //   (data: Data) => {
    //     // this.states = data.statelist;
    //     this.countries = data.countryList;
    //   }
    // );
    this.countries = [{
      id:1,
      contryName:"India"
    }];
  }

  ngOnInit() {
    this.enrollForm = new FormGroup({
      'orgInfo': new FormGroup({
        'empRegWith': new FormControl(null, Validators.required),
        'regNumber': new FormControl(null, Validators.required),
        'orgName': new FormControl(null, Validators.required),
        'formerOrgName': new FormControl(null, Validators.required),
        'orgType': new FormControl(null, Validators.required),
        'sector': new FormControl(null, Validators.required),
        'orgPan': new FormControl(null, Validators.required),
        'desc': new FormControl(null, Validators.required),
      }),
      'regOffice': new FormGroup({
        'address1': new FormControl(null, Validators.required),
        'address2': new FormControl(null),
        'address3': new FormControl(null),
        'state': new FormControl(null, Validators.required),
        'country': new FormControl(null, Validators.required),
        'locality': new FormControl(null, Validators.required),
        'city': new FormControl(null, [Validators.required, Validators.pattern(/^[a-zA-Z&][a-zA-Z& ]*[a-zA-Z&]$/)]),
        'pcode': new FormControl(null, [
          Validators.required,
          Validators.pattern(/^\d{6}/)
        ]),
        'mobile': new FormControl(null,
          [
            Validators.required,
            Validators.pattern("^[0-9]{10}$")
          ]
        ),
        'phone': new FormControl(null,
          [
            Validators.required,
            Validators.pattern("^[0-9]{10}$")
          ]
        ),
        'cemail': new FormControl(null, [Validators.required, Validators.email]),
        'cweb': new FormControl(null, Validators.required),
      }),
      'contact': new FormGroup({
        'uid': new FormControl(null, Validators.required),
        'uin': new FormControl(null, Validators.required),
        'pname': new FormControl(null, [
          Validators.required,
          Validators.pattern(/^[a-zA-Z&][a-zA-Z& ]*[a-zA-Z&]$/)
        ]),
        'gname': new FormControl(null, [
          Validators.required,
          Validators.pattern(/^[a-zA-Z&][a-zA-Z& ]*[a-zA-Z&]$/)
        ]),
        'dob': new FormControl(null, Validators.required),
        'gender': new FormControl(null, Validators.required),
        'c_no': new FormControl(null,
          [
            Validators.required,
            Validators.pattern("^[0-9]{10}$")
          ]
        ),
        'm_no': new FormControl(null,
          [
            Validators.required,
            Validators.pattern("^[0-9]{10}$")
          ]
        ),
        'email': new FormControl(null, Validators.required),
        'c_desig': new FormControl(null, Validators.required),
        'userid': new FormControl(null, Validators.required),
        'pass': new FormControl(null, [
          Validators.required,
          Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/),
          Validators.minLength(8)
        ]),
        'cpass': new FormControl(null, [
          Validators.required,
          Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/),
          Validators.minLength(8)
          // this.comparePassword.bind(this)
        ])
      }),
      'mis': new FormGroup({
        'yoc': new FormControl(null, Validators.required),
        'aop': new FormControl(null, Validators.required),
        'kacti': new FormControl(null, Validators.required),
        'iconfirm': new FormControl(null, Validators.required),
      }),
    });

    const uidFormControl = this.enrollForm.get("contact.uid");
    uidFormControl.valueChanges.subscribe(value => {
      console.log(value);
        let element = document.getElementById("uin");
        // let changeEvent = new Event('change');
        // this.enrollForm.patchValue({
        //   'contact': ''
        // });
        // element.dispatchEvent(changeEvent);
        let val1 = element.removeAttribute('readonly');
        let val2 = element.removeAttribute('maxlength');
        let val3 = element.removeAttribute('pattern');
        this.enrollForm.get("contact.uin").setValue("");
        if (value == "AADHAAR") {
          element.setAttribute('type', 'number');
          element.setAttribute('maxlength', "12");
          this.enrollForm.get("contact.uin").setValidators([Validators.required, Validators.pattern(/[0-9]{12}/)]);
        } else if (value == "PAN") {
          element.setAttribute('type', 'text');
          element.setAttribute('maxlength', "10");
          this.enrollForm.get("contact.uin").setValidators([Validators.required, Validators.pattern(/[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}/)]);
          // element.setAttribute('pattern', '[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}');
        } else {
          element.setAttribute('readonly', 'true');
        }        
        this.enrollForm.get("contact.uin").updateValueAndValidity();
    });

    if (this.formValue.userData) {
      this.enrollForm.patchValue({
        contact: {
          'm_no': this.formValue.userData.phone,
          'email': this.formValue.userData.email,
          'pname': this.formValue.userData.name
        }
      });
    }
    // Multistep From


    $.easing.jswing = $.easing.swing;
    $.extend($.easing, {
      easeInOutBack: function (e, f, a, i, h, g) {
        if (g == undefined) {
          g = 1.70158
        }
        if ((f /= h / 2) < 1) {
          return i / 2 * (f * f * (((g *= (1.525)) + 1) * f - g)) + a
        }
        return i / 2 * ((f -= 2) * f * (((g *= (1.525)) + 1) * f + g) + 2) + a
      }
    });

    $(document).ready(function () {
      //$ time
      var current_fs, next_fs, previous_fs; //fieldsets
      var left, opacity, scale; //fieldset properties which we will animate
      var animating; //flag to prevent quick multi-click glitches

      $(".next").click(function () {
        if (animating) return false;
        animating = true;

        current_fs = $(this).parent();
        next_fs = $(this).parent().next();

        //activate next step on progressbar using the index of next_fs
        $("#progressbar li").eq($("fieldset").index(next_fs)).addClass("active");

        //show the next fieldse
        next_fs.show();
        //hide the current fieldset with style
        current_fs.animate({ opacity: 0 }, {
          step: function (now, mx) {
            //as the opacity of current_fs reduces to 0 - stored in "now"
            //1. scale current_fs down to 80%
            scale = 1 - (1 - now) * 0.2;
            //2. bring next_fs from the right(50%)
            left = (now * 50) + "%";
            //3. increase opacity of next_fs to 1 as it moves in
            opacity = 1 - now;
            current_fs.css({
              'transform': 'scale(' + scale + ')',
              'position': 'absolute'
            }).removeAttr('stylr');
            next_fs.css({ 'left': left, 'opacity': opacity, 'position': 'static'});
          },
          duration: 800,
          complete: function () {
            current_fs.hide();
            animating = false;
          },
          //this comes from the custom easing plugin
          easing: 'easeInOutBack'
        });
      });

      $(".previous").click(function () {
        if (animating) return false;
        animating = true;

        current_fs = $(this).parent();
        previous_fs = $(this).parent().prev();

        //de-activate current step on progressbar
        $("#progressbar li").eq($("fieldset").index(current_fs)).removeClass("active");

        //show the previous fieldset
        previous_fs.show();
        //hide the current fieldset with style
        current_fs.animate({ opacity: 0 }, {
          step: function (now, mx) {
            //as the opacity of current_fs reduces to 0 - stored in "now"
            //1. scale previous_fs from 80% to 100%
            scale = 0.8 + (1 - now) * 0.2;
            //2. take current_fs to the right(50%) - from 0%
            left = ((1 - now) * 50) + "%";
            //3. increase opacity of previous_fs to 1 as it moves in
            opacity = 1 - now;
            current_fs.css({ 'left': left, 'position': 'absolute' });
            previous_fs.css({
              'transform': 'scale(' + scale + ')',
              'opacity': opacity,
              'position': 'static'
            }).removeAttr('stylr');;
          },
          duration: 1,
          complete: function () {
            current_fs.hide();
            animating = false;
          },
          //this comes from the custom easing plugin
          easing: 'easeInOutBack'
        });
      });

      $(".submit").click(function () {
        return false;
      })
    });
  }

  ngAfterViewInit(): void {
    let uinElement = document.getElementById("uin");
    uinElement.setAttribute('readonly', 'true');
  }

  convertToModel(data: any): EmployeerRegistrationModel {
    const date = new Date();
    this.currentPass = data['contact']['pass'];

    const stateData = new EmployeeStateModel(
      parseInt(data['regOffice']['state'].split(", ")[0]),
      data['regOffice']['state'].split(", ")[1],
      "Active",
      date.valueOf(),
      "AP"
    );

    const countryData = new EmployeeCountryModel(
      parseInt(data['regOffice']['country'].split(", ")[0]),
      data['regOffice']['country'].split(", ")[1],
      "Active",
      'National'
    )

    const contactData = new EmployeeContactModel(
      data['contact']['uid'],
      data['contact']['uin'],
      data['contact']['pname'],
      data['contact']['gname'],
      `${[data['contact']['dob']['month'] - 1]}/${data['contact']['dob']['day']}/${data['contact']['dob']['year']} `,
      data['contact']['c_no'],
      data['contact']['m_no'],
      data['contact']['email'],
      data['contact']['c_desig']
    )

    const detailData = new EmployeeDetailModel(
      data['orgInfo']['empRegWith'],
      data['orgInfo']['regNumber'],
      data['orgInfo']['orgName'],
      data['orgInfo']['formerOrgName'],
      data['orgInfo']['orgType'],
      data['orgInfo']['sector'],
      data['orgInfo']['orgPan'],
      data['orgInfo']['desc'],
      data['mis']['yoc'],
      data['mis']['aop'],
      data['mis']['kacti'],
    );

    const registrationData = new EmployeerRegistrationModel(
      detailData,
      data['regOffice']['address1'],
      data['regOffice']['address2'],
      data['regOffice']['address3'],
      stateData,
      countryData,
      data['regOffice']['city'],
      data['regOffice']['locality'],
      data['regOffice']['pcode'],
      data['regOffice']['phone'],
      data['regOffice']['mobile'],
      data['regOffice']['cemail'],
      data['regOffice']['cweb'],
      contactData,
      data['contact']['userid'],
      data['contact']['pass'],
      data['contact']['cpass']
    );

    return registrationData;
  }

  onFromSubmit() {
    this.formstep = 2;
    const formdata = this.enrollForm.value;
    var convertdata = this.convertToModel(formdata);
    console.log(formdata, convertdata);
    //  {
    //    "registrationId": "EMP000063"
    //  } 
    // this.regser.doEmployeeRegister(convertdata).subscribe(
    //   data => {
    //     this.finalStatus = 2;
    //     this.statusMessage = `
    //     <h4>Success</h4>
    //     <h6>
    //         Thank you for registration, 
    //         your Registration Id: ${data.response.registrationId}.
    //         Please go to your email for further steps.
    //     </h6>
    //     `;
    //   },
    //   error => {
    //     if (error.status == 401) {
    //       this.finalStatus = 3;
    //       this.statusMessage = "User Already Exist";
    //     } else {
    //       this.finalStatus = 4;
    //       this.statusMessage = "Something went wrong";
    //     }
    //   }
    // )
  }

  resetForm() {
    this.enrollForm.reset();
    this.finalStatus = 1;
    this.statusMessage = "Please wait...";
    this.formstep = 1;
  }

  fillAgain() {
    window.location.reload();
  }

  // validUser(control: FormControl): Promise<any> | Observable<any> {
  //   return new Promise<any>(
  //     (resolve, reject) => this.regser
  //       .checkUserExist(control.value)
  //       .pipe(
  //         debounceTime(1000),
  //         distinctUntilChanged()
  //       )
  //       .subscribe(
  //         (data: any) => data['existed'] ? resolve({ "existed": true }) : resolve(null)
  //       )
  //   )
  // }

  checkLength(event, formName) {
    let element = event.target;
    let formControlName = formName + "." + element.name;
    if (element.value.length > element.maxLength) {
      this.enrollForm.get(formControlName).setValue(element.value.slice(0, element.maxLength));
      // this.enrollForm.controls[formControlName].setValue(element.value.slice(0, element.maxLength));
      // this.enrollForm.controls['regOffice'].controls[element.name].setValue(element.value.slice(0, element.maxLength));
    }
  }

  checkConsent(event, formName) {
    let element = event.target;
    let formControlName = formName + "." + element.name;
    if (event.target.checked) {
      this.enrollForm.get(formControlName).setValue('iaccept');
    } else {
      this.enrollForm.get(formControlName).setValue(null);
    }
  }

  getStatesByCountry(event) {
    this.http.get<any>('assets/json/states.json').subscribe(data => {
      this.states = data.states;
    })
    // this.api.getCountyStateList(event.target.value).subscribe(data => {
    //   this.states = data.response;
    //   console.log(this.states);
    // },
    // error => {
    //   this.states = [];
    //   console.log(error);
    // });
  }

  trimValue(event, elementRef) {
    let value = event.target.value;
    this.enrollForm.get(elementRef).setValue(value.trim());
  }
  // comparePassword(control :FormControl): {[s: string]: boolean}{
  //   if (control.value === this.currentPass) return null; 
  //   else return{"passMatched": true}; 
  // }

}
