<div class="modal fade" id="responseModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body" *ngIf="status">
        <div class="img-container">
          <img src="../../../../assets/images/icons/successful.png" alt="" width="100px">
        </div>
        <div class="message">
          <p>Thank you for contacting us.</p>
        </div>
      </div>
      <div class="modal-body" *ngIf="!status">
        <div class="img-container">
          <img src="assets/images/icons/failure.png" alt="" width="100px">
        </div>
        <div class="message">
          <p>Oops..! Something went Wrong</p>
        </div>
      </div>
    </div>
  </div>
</div>
