<div class="tabs tab1">
  <!-- Nav pills -->
  <ul class="nav nav-pills container" role="tablist">
    <li class="nav-item" *ngFor="let pdata of skilldata">
      <a class="nav-link" [ngClass]="pdata.show?'active':''" data-toggle="pill" href="#{{pdata.sname}}">
        {{pdata.name}}
      </a>
    </li>
  </ul>

  <!-- Tab panes -->
  <div class="tab-content">
    <div id="{{pdata.sname}}" class="tab-pane" [ngClass]="pdata.show?'active':'fade'" *ngFor="let pdata of skilldata">
      <div class="sub-tab">

        <ng-template [ngIf]="pdata.innertab">

          <div class="innertab container-fluid">
            <ul class="nav nav-tabs container">
              <li class="nav-item"  *ngFor="let cdata of pdata.subdata">
                <a class="nav-link" [ngClass]="cdata.active?'active':''" data-toggle="tab" href="#{{cdata.key}}">{{cdata.name}}</a>
              </li>
            </ul>
            
            <!-- Tab panes -->
            <div class="tab-content container">
              <div class="tab-pane" id="{{cdata.key}}" [ngClass]="cdata.active?'active':'fade'" *ngFor="let cdata of pdata.subdata">
                
                <div *ngIf="cdata.name" class="heading">
                  <h2>{{cdata.name}}</h2>
                  <p>{{cdata.content}}</p>
                </div>

                <div class="section-tab" *ngFor="let gcdata of cdata.coursedata">
                  <div class="first">
                    <h3>{{gcdata.name}}</h3>
                    <ul class="describe">
                      <li>{{gcdata.type}}</li>
                      <li>{{gcdata.Duration}}</li>
                      <li>{{gcdata.Access}}</li>
                    </ul>
                    <p>{{gcdata.objective}}</p>
                    <h4 class="overview">
                      Course Overview :
                    </h4>
                    <ul class="overview">
                      <li *ngFor="let gcdatalist of gcdata.overview"> {{gcdatalist}}</li>
                    </ul>
                  </div>
                  <div class="section-tab last flex-pc">
                    <img src="{{gcdata.img}}" alt="{{gcdata.name}} image" title="{{gcdata.name}} image" class="img-fluid">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>


        <ng-template [ngIf]="!pdata.innertab" *ngFor="let cdata of pdata.subdata">
          <div *ngIf="cdata.name" class="heading container">
            <h2>{{cdata.name}}</h2>
            <p>{{cdata.content}}</p>
          </div>
          <div class="section-tab container" *ngFor="let gcdata of cdata.coursedata">
            <div class="first">
              <h3>{{gcdata.name}}</h3>
              <ul class="describe">
                <li>{{gcdata.type}}</li>
                <li>{{gcdata.Duration}}</li>
                <li>{{gcdata.Access}}</li>
              </ul>
              <p>{{gcdata.objective}}</p>
              <h4 class="overview">
                Course Overview :
              </h4>
              <ul class="overview">
                <li *ngFor="let gcdatalist of gcdata.overview"> {{gcdatalist}}</li>
              </ul>
            </div>
            <div class="section-tab last flex-pc">
              <img src="{{gcdata.img}}" alt="{{gcdata.name}} image" title="{{gcdata.name}} image" class="img-fluid">
            </div>
          </div>
        </ng-template>


        </div>
      </div>
    </div>
  </div>