import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable, OnInit } from "@angular/core";
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: "root"
})
export class ChatService implements OnInit{
    constructor(
        private http: HttpClient
    ){}

    getHeaderData(){
        return this.http.get("http://localhost/data/data1.json");
    }

    getFAQList(){
        return this.http.get('assets/json/faq.json');
        // return this.http.get("assets/")
        // .pipe(map((response: Response)=> response.json()));
    } 

    sendChatHistoryToUser(dataObj){
        return this.http.post("http://183.82.117.232:8085/lms-web/data/send/chatHistory/toUser",dataObj)
        .pipe(map((response: Response)=> response));
    } 

    ngOnInit(){

    }
}
