export class TrainerRegModel{
    constructor(
        public firstName: string,
        public lastName: string,
        public dob: string,
        public mobile: string,
        public email: string,
        public locality: string,
        public city: string,
        public stateId: string,
        public district: string,
        public address: string,
        public countryId: string,
        public qualificationId: string,
        public organizationId: string
        // public courses: Array<{id: string}>,
    ){}
}