import { AfterViewInit, Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ApiService } from "src/app/services/http/api.service";
import { UtilService } from "src/app/services/util.service";

declare var $: any;
@Component({
  selector: "app-admin-faq",
  templateUrl: "./admin-faq.component.html",
  styleUrls: ["./admin-faq.component.less"],
})
export class AdminFaqComponent implements OnInit, AfterViewInit {
  faqList: any = [];
  faqForm: FormGroup;
  isupdateMode = false;
  table;
  currentFaq: any;
  constructor(private api: ApiService, private fb: FormBuilder,private util:UtilService) {}
  ngAfterViewInit(): void {
    this.loadFaqData();
  }

  ngOnInit() {
    this.faqForm = this.fb.group({
      answer: ["", Validators.required],
      question: ["", Validators.required],
    });
    this.loadFaqData();
  }
  loadFaqData() {
    var self = this;
    // this.faqList = [];
    this.api.getFaqs().subscribe(
      (res) => {
        if (res.status == 200) {
          this.faqList = res.body;
          if (this.faqList.lenght != 0) {
            $(document).ready(function () {
              setTimeout(() => {
                self.table = $("#faq").DataTable();
              }, 1000);
            });
           
          }
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  onFormSubmit() {
    this.util.setLoadingStatus(true);
    if (!this.isupdateMode) {
      this.api.saveFaq(this.faqForm.value).subscribe(
        (res) => {
          if (res.status == 200) {
            this.loadFaqData();
            this.faqForm.reset();
            this.closeModal();
            this.util.setLoadingStatus(false);
          }
        },
        (err) => {
          console.log(err);
        }
      );
    } else {
      this.currentFaq.answer = this.faqForm.value.answer;
      this.currentFaq.question = this.faqForm.value.question;
      this.api.updateFaq(this.currentFaq).subscribe((res) => {
        if (res.status == 200) {
          this.isupdateMode = false;
          this.loadFaqData();
          this.faqForm.reset();
          this.closeModal();
          this.util.setLoadingStatus(false);
        }
      });
    }
  }
  toEdit(id) {
    this.openModal();
    this.isupdateMode = true;
    this.faqList.forEach((faq) => {
      if (faq.id == id) {
        this.currentFaq = faq;
        this.faqForm.patchValue({
          answer: this.currentFaq.answer,
          question: this.currentFaq.question,
        });
      }
    });
  }
  toDelete(id) {
    if (confirm("Are you sure.? you want to delete this")) {
      this.util.setLoadingStatus(true);
      this.api.deleteFaq(id).subscribe(
        (res) => {
          if (res.status == 200) {
            this.loadFaqData();
            this.util.setLoadingStatus(false);
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }
  toggleStatus(id) {
    this.util.setLoadingStatus(true);
    this.api.toggleFaqStatus(id).subscribe((res) => {
      if (res.status == 200) {
        this.loadFaqData();
        this.util.setLoadingStatus(false);
      }
    });
  }
  openModal() {
    $("#faqModal").modal("show");
  }
  closeModal() {
    $("#closemodal").modal("hide");
    $('body').removeClass('modal-open');
$('.modal-backdrop').remove();
  }
}
