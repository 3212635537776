<div class="bg-white container-fluid px-5 blog-list">
    <div class="row py-3" style="flex-direction: row-reverse;">
        <div class="col-12 py-3 col-sm-12 col-md-3">
            <app-blog-sidebar></app-blog-sidebar>
        </div>
        <div *ngIf="blogData" class="col-12 col-sm-12 col-md-9" style="border-right: 1px solid #dedede;">
            <div class="blog-container">
                <div class="blog-banner" [ngStyle]="{'background-image':'url('+blogData.thumbnail+')'}">
                    <!-- <img class="img-fluid w-100" [src]="blogData.thumbnail" alt=""> -->
                </div>
                <div class="blog-heading wow fadeInUp mt-0 ">
                    <h4 class="m-0 primary-text">{{blogData.title}}</h4>
                </div>
                <div class="blog-auther d-flex">
                    <div class="mb-4 wow fadeInUp flex-grow-1">
                        <b> Author:</b>{{" "+blogData.author}}
                    </div>
                    <div class="date wow fadeInUp mb-4">
                        <b>Published on:</b> {{" "+blogData.date}}
                    </div>
                </div>
                <div class="disclaimer wow fadeInUp my-2">
                    <b>Disclaimer: </b>
                    <i> {{" "+blogData.disclaimer}} </i>
                </div>
                <div class="blog-data" *ngFor="let item of blogData.content">
                    <div *ngIf="item.heading" class="sub-heading wow fadeInUp">
                        <h5> <b> {{item.heading}}</b> </h5>
                    </div>
                    <p class="wow fadeInUp"> &#09; {{item.data}} </p>
                </div>
            </div>
        </div>
    </div>
</div>