import { Component, OnInit } from '@angular/core';

declare var $: any;
@Component({
  selector: 'app-individual',
  templateUrl: './individual.component.html',
  styleUrls: ['./individual.component.less']
})
export class IndividualComponent implements OnInit {
  constructor() { }
  isStudentSelected = true;
  isProfessionalSelected = false;
  ngOnInit() {
    var individualTabs = document.querySelectorAll('.individual-tab');
    individualTabs.forEach(element => {
      element.addEventListener('click', () => {
        individualTabs.forEach(element1 => {
          element1.classList.remove('active')
        })
        element.classList.add('active')
      })
    });
    let allButtons = document.querySelectorAll('.individual-tab');

    allButtons.forEach(button => {
      button.addEventListener('click', toggleAnimationClass);
    })

    function toggleAnimationClass({ target }) {
      allButtons.forEach(button => {
        button.classList.remove('individual-tab--active');
      })
      target.classList.add('individual-tab--active');

      // if(target.classList.contains('btn--active')) {
      //   target.classList.add('setInactive');
      //   target.classList.remove('btn--active');
      // } else {
      //   target.classList.remove('setInactive');
      //   target.classList.add('btn--active');
      // }

    }
  }
  Clicked(no) {
    switch (no) {
      case 1:
        this.isStudentSelected = true;
        this.isProfessionalSelected = false;
        break;
      case 2:
        this.isStudentSelected = false;
        this.isProfessionalSelected = true;
        break;
    }
  }
}
