import { Component, OnInit } from "@angular/core";

declare var $: any;
@Component({
  selector: "app-admin-sidebar",
  templateUrl: "./admin-sidebar.component.html",
  styleUrls: ["./admin-sidebar.component.less"],
})
export class AdminSidebarComponent implements OnInit {
  isSidebarOpen = true;
  sidebarToggleClass = "fa fa-angle-double-left";
  constructor() {}

  ngOnInit() {}
  toggleSidebar() {
    $(".admin-sidebar").toggleClass("expand");
    $(".admin-body").toggleClass("expand");
    $(".toggleButton").toggleClass("expand");
    this.isSidebarOpen = !this.isSidebarOpen;
    this.isSidebarOpen
      ? (this.sidebarToggleClass = "fa fa-angle-double-left")
      : (this.sidebarToggleClass = "fa fa-angle-double-right");
  }
}
