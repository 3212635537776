<div class="container-fluid p-0">
  <div class="image-container">
    <div class="image"></div>
  </div>
</div>
<div class="bg-organization1">
  <div class="container">
    <div class="row entry-content">
      <p class="text-center wow fadeInUp">
        Biggest challenge an organization is facing currently is finding a
        correct resource who is well trained and readily deployable. We at Artha
        Siksha would want to bridge that gap by providing highly skilled
        resources for hire who are with real time on the job experience. We
        offer skilled resources on short-term hire, long-term hire or full-time
        employment with an option to retain the resources hired on a contract.
        <br />
        <br />
        Continuous Learning has become one of the most critical element of
        competency index for any organization. Artha Siksha has a holistic F&A
        domain training service which turns into boon for such organizations
        seeking continuous improvement. In collaboration with an intuitive and
        state-of-the-art LMS platform, Artha Siksha caters to the growing needs
        of the organizations to keep their workforce Reskilled.
      </p>
      <div class="button-wrapper mx-auto wow fadeInUp">
        <button routerLink="/organization-register">Register</button>
      </div>
    </div>
  </div>
  <div class="container py-3">
    <div class="row service-list">
      <div class="col-12">
        <h2 class="text-center wow fadeInUp">
          Service offering by Artha Shiksha
        </h2>
        <hr class="devider wow fadeInUp mb-5" />
      </div>
      <div
        class="col-lg-3 col-md-3 col-sm-6 py-5 wow fadeInUp"
        data-wow-duration="0.5s"
      >
        <div class="service-list-item">
          <p>
            Readily deployable highly skilled resources for hire on a full-time
            basis.
          </p>
          <div class="icon">
            <img
              src="assets/arthaSiksha/organization/icons/high-skilled.svg"
              alt=""
            />
          </div>
        </div>
      </div>
      <div
        class="col-lg-3 col-md-3 col-sm-6 py-5 wow fadeInUp"
        data-wow-duration="1s"
      >
        <div class="service-list-item">
          <p>
            Resources for short-term hire catering to the demands during busy
            season or on a project to project basis.
          </p>
          <div class="icon">
            <img
              src="assets/arthaSiksha/organization/icons/resources for shirt term.svg"
              alt=""
            />
          </div>
        </div>
      </div>
      <div
        class="col-lg-3 col-md-3 col-sm-6 py-5 wow fadeInUp"
        data-wow-duration="1.5s"
      >
        <div class="service-list-item">
          <p>
            Handling of overall Learning and Development function on your
            behalf.
          </p>
          <div class="icon">
            <img
              src="assets/arthaSiksha/organization/icons/handling-of-learning.svg"
              alt=""
            />
          </div>
        </div>
      </div>
      <div
        class="col-lg-3 col-md-3 col-sm-6 py-5 wow fadeInUp"
        data-wow-duration="2s"
      >
        <div class="service-list-item">
          <p>
            Special training programs targeted towards upskilling of your
            resources.
          </p>
          <div class="icon">
            <img
              src="assets/arthaSiksha/organization/icons/speacial-training.svg"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="bg-organization2">
  <div class="container py-3">
    <div class="row py-5 justify-content-center">
      <div class="col-md-8 col-sm-12 text-center">
        <h2 class="wow fadeInUp">
          How does it work <span class="primary-text">?</span>
        </h2>
        <hr class="devider wow fadeInUp" />
      </div>
      <div class="col-md-8 col-sm-12 text-center">
        <p class="wow fadeInUp">
          <!-- Artha Siksha caters to Learning process for the organization for F&A
          domain. The learning journey of the workforce is documented, recorded
          and managed through LMS which gives the organization higher degree of
          control and governance. The overall structure of the program can be
          linked with Employee Learning Path for effective traction. -->
          Artha Siksha caters to talent management and learning management needs
          of an organization in F&A domain. Talent management takes care of
          resource needs by providing readily deployable talented resources.
          Learning management ensures journey of the workforce is documented,
          recorded and managed through LMS which gives the organization higher
          degree of control and governance. The overall structure of the program
          can be linked with employee learning path for effective traction.
        </p>
      </div>
    </div>
  </div>
  <div class="container py-3">
    <div class="row py-5 justify-content-center">
      <div class="col-md-8 col-sm-12 text-center">
        <h2 class="wow fadeInUp">Service offerings</h2>
        <hr class="devider wow fadeInUp" />
      </div>
      <div class="col-md-8 col-sm-12 text-center">
        <p class="wow fadeInUp">
          Our services are classified into two offerings, “Talent Management”
          and “Learning Management”
        </p>
      </div>
    </div>
  </div>
  <div class="container py-3">
    <div class="row py-5 justify-content-center">
      <div class="col-md-12 col-sm-12 text-center">
        <h4 class="wow fadeInUp">Talent Management</h4>
        <hr class="devider wow fadeInUp" />
      </div>
      <div
        class="col-md-2 col-lg-2 col-sm-4 px-3 mx-0 pb-5 talenetManagement wow fadeInRight"
        *ngFor="let item of talentMangegementList; let i = index"
        [attr.data-wow-duration]="i + 's'"
        [ngStyle]="{ 'z-index': 10 - i }"
      >
        <h3 class="heading">Step <span class="number">{{ i + 1 }}</span></h3>
        <div class="triangle triangle1"></div>
        <div class="triangle triangle2"></div>
        {{ item }}
      </div>
    </div>
  </div>
  <div class="container-fluid bg-light">
    <div class="row justify-content-center">
      <div class="col-md-8 col-sm-12 text-center text-dark pt-5">
        <h4 class="wow fadeInUp">Learning Management</h4>
        <hr class="devider wow fadeInUp" />
      </div>
      <div class="col-8 text-center wow fadeInUp">
        <img
          src="/assets/arthaSiksha/organization-howitWorks.png"
          alt=""
          class="img-fluid p-5"
        />
      </div>
    </div>
  </div>
  <div class="container py-5">
    <div class="row benefits-list">
      <div class="col-12">
        <h2 class="text-center wow fadeInUp">Benefits for the Organization</h2>
        <hr class="devider wow fadeInUp mb-5" />
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12 benefits-list-item">
        <div class="content wow fadeInLeft">
          <p>
            Access to well-trained and highly skilled resources on a need basis
          </p>
        </div>
        <div class="content wow fadeInLeft">
          <p>
            Centralized learning and development which increases efficiency
            along with providing access to industry best holistic trainings.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12 benefits-list-item">
        <div class="benefits-image wow fadeInUp"></div>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12 benefits-list-item">
        <div class="content wow fadeInRight">
          <p>
            Reduction in costs by reducing the learning curve and overhead costs
            associated with full-time employee.
          </p>
        </div>
        <div class="content wow fadeInRight">
          <p>Ease in upskilling and/or reskilling of your resources.</p>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="container py-5">
  <div class="row effecting-learning-list py-5">
    <div class="col-12">
      <h2 class="text-center wow fadeInUp">
        What makes Artha Shiksha an effective learning partner
        <span class="primary-text">?</span>
      </h2>
      <hr class="devider wow fadeInUp mb-5" />
    </div>
    <div class="col-lg-3 col-md-3 col-sm-6 text-center">
      <span class="heading">Heading</span>
      <div class="effecting-learning-list-item">
        <div class="icon"></div>
        <div class="text">
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptatibus
          rem ab perferendis sit aliquid! Illo atque laborum dolorum consectetur
          minus.
        </div>
        <span class="bg"></span>
      </div>
    </div>
    <div class="col-lg-3 col-md-3 col-sm-6 text-center">
      <span class="heading">Heading</span>
      <div class="effecting-learning-list-item">
        <div class="icon"></div>
        <div class="text">
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptatibus
          rem ab perferendis sit aliquid! Illo atque laborum dolorum consectetur
          minus.
        </div>
        <span class="bg"></span>
      </div>
    </div>
    <div class="col-lg-3 col-md-3 col-sm-6 text-center">
      <span class="heading">Heading</span>
      <div class="effecting-learning-list-item">
        <div class="icon"></div>
        <div class="text">
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptatibus
          rem ab perferendis sit aliquid! Illo atque laborum dolorum consectetur
          minus.
        </div>
        <span class="bg"></span>
      </div>
    </div>
    <div class="col-lg-3 col-md-3 col-sm-6 text-center">
      <span class="heading">Heading</span>
      <div class="effecting-learning-list-item">
        <div class="icon"></div>
        <div class="text">
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptatibus
          rem ab perferendis sit aliquid! Illo atque laborum dolorum consectetur
          minus.
        </div>
        <span class="bg"></span>
      </div>
    </div>
  </div>
</div>  -->
</div>
