import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ApiService } from "src/app/services/http/api.service";
import { UtilService } from "src/app/services/util.service";

@Component({
  selector: "app-contact-us",
  templateUrl: "./contact-us.component.html",
  styleUrls: ["./contact-us.component.less"],
})
export class ContactUsComponent implements OnInit {
  contactForm: FormGroup;
  constructor(private fb: FormBuilder, private api: ApiService,private util:UtilService) {}

  ngOnInit() {
    this.contactForm = this.fb.group({
      name: ["", [Validators.required,Validators.pattern('[a-zA-Z0-9]+(.*)')]],
      email: ["", [Validators.email, Validators.required]],
      mobile: ["", [Validators.required,Validators.pattern('[a-zA-Z0-9]+(.*)')]],
      message: ["", [Validators.required,Validators.pattern('[a-zA-Z0-9]+(.*)')]],
    });
  }

  isValid(name) {
    if (this.contactForm) {
      if (this.contactForm.get(name)) {
        return (
          this.contactForm.get(name)["invalid"] &&
          this.contactForm.get(name)["touched"]
        );
      }
    }
    return false;
  }
  onSubmit() {
    this.util.setLoadingStatus(true);
    this.api.saveContactDetails(this.contactForm.value).subscribe(
      (res) => {
        this.util.setLoadingStatus(false);
        this.util.showResponseModal(true);
      },
      (err) => {
        this.util.setLoadingStatus(false);
        this.util.showResponseModal(false);
      }
    );
  }
}
