export class RegistrationModel {
    constructor(
        public registrationType: string ,
        public firstName: string ,
        public lastName: string ,
        public birth: string ,
        public age: string ,
        public gender: string ,
        public mobile: string ,
        public email: string ,
        public passwordValue: string ,
        public country: string ,
        public locationId: string ,
        // public userCourses: Array<any>,
        // public userExpectedCourses: Array<any>
    ){}
}