<div class="container-fluid p-0">
  <div class="image-container">
    <div class="image"></div>
  </div>
</div>
<div class="bg-professional1">
  <div class="container">
    <div class="row entry-content">
      <p class="text-center wow fadeInUp">
        Artha Siksha is designed and developed to cater to professionals who are
        in active employment and/or those who are eying for career advancement.
        The course offering and delivery gives flexibility and freedom to an
        individual learner to choose their leaning areas, assess their existing
        competency and opt for learning interventions for a tangible outcome.
        The courses are designed keeping in mind the nature of experienced
        Individual Learner.
      </p>
      <div class="button-wrapper mx-auto wow fadeInUp">
        <button routerLink="/professional-register">Register</button>
      </div>
    </div>
  </div>

  <div class="container py-3">
    <div class="row align-items-center" style="flex-direction: row-reverse">
      <div class="col-md-4 wow fadeInRight">
        <div class="image-wrapper">
          <img
            src="assets/images/icons/image-corner.svg"
            alt=""
            class="img-corner img-top-right"
          />
          <img
            src="assets/arthaSiksha/professionals/professionals3-square.jpg"
            alt=""
            class="img-fluid p-5"
          />
          <img
            src="assets/images/icons/image-corner.svg"
            alt=""
            class="img-corner img-left-bottom"
          />
        </div>
      </div>
      <div class="col-md-8 px-5 wow fadeInLeft">
        <!-- <h2 class="mb-4">
        Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.
      </h2> -->
        <p class="starter-box">
          We all have experienced intensive knowledge attainment during the
          course of our academic career which takes a pause once we transition
          into professional life. As the professional phase has become even more
          competitive, it is imperative to have inclusion of ‘Continuous
          Learning’ and ‘upskilling’ to facilitate career satisfaction,
          advancement and enrichment.
        </p>
      </div>
    </div>
  </div>
  <div class="container py-3">
    <div class="row align-items-center">
      <div class="col-md-4 wow fadeInLeft">
        <div class="image-wrapper">
          <img
            src="assets/images/icons/image-corner.svg"
            alt=""
            class="img-corner img-top-left"
          />
          <img
            src="assets/arthaSiksha/professionals/professionals5-square.jpg"
            alt=""
            class="img-fluid p-5"
          />
          <img
            src="assets/images/icons/image-corner.svg"
            alt=""
            class="img-corner img-right-bottom"
          />
        </div>
      </div>
      <div class="col-md-8 px-5 wow fadeInRight">
        <!-- <h2 class="mb-4">
        Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.
      </h2> -->
        <p class="starter-box">
          Artha Siksha offers course curriculum that is designed and created in
          sync with the changing industry trends, standards and expectations.
          Our course design offers learning solutions for ambitious working
          professionals who are seeking career advancement. The course offering
          for working professionals is the essence of ‘Reskilling’ that become
          an essential element for any aspiring and ambitious professional.
        </p>
      </div>
    </div>
  </div>
</div>
<div class="bg-professional2">
<div class="container py-3">
  <div class="row py-5 justify-content-center align-items-center">
    <div class="col-10 mb-4 text-center">
      <h2 class="wow fadeInUp">
        How does it work <span class="primary-text">?</span>
      </h2>
      <hr class="devider wow fadeInUp" />
    </div>
    <div class="col-md-12 my-5 wow fadeInUp">
      <img
        src="/assets/arthaSiksha/professionalStudent-howitworks.png"
        alt=""
        class="img-fluid"
      />
    </div>
    <div class="col-md-8 col-sm-12 text-center">
      <p class="wow fadeInUp">
        The simplicity and engaging mechanism is deigned keeping in mind the
        paucity of time and yet the need for tangible learning. Hence, the
        process follows simple 5-step journey to equip and reskill a
        professional to stride ahead in career..
      </p>
    </div>
  </div>
</div>
<div class="container-fluid py-3">
  <div class="row py-5 justify-content-center">
    <!-- <div class="col-8 mb-4">
      <h2 class="wow fadeInUp">How does the assessment help <span class="primary-text">?</span></h2>
    </div>
    <div class="col-8">
      <ul class="as-list primary-list">
        <li class="wow fadeInLeft">Identify your existing knowledge and proficiency</li>
        <li class="wow fadeInLeft">Wide array of domain areas that are relevant for emerging trends</li>
        <li class="wow fadeInLeft">Get deep dive analysis on performance</li>
        <li class="wow fadeInLeft">In-depth Training Need Identification analysis</li>
        <li class="wow fadeInLeft">Rational proposition on learning intervention on developmental areas</li>
      </ul>
    </div> -->
    <div class="col-md-8 col-sm-12 col-lg-8" style="max-width: 850px">
      <div class="assessment-container wow fadeInUp">
        <div class="circle-heading">
          <h2 class="">How does the assessment help ?</h2>
        </div>
        <ul class="as-list assessment-list">
          <li class=" item item1">
            <i
              class="fa fa-circle primary-text mr-2 dot"
              aria-hidden="true"
            ></i>
            Identify your existing knowledge and proficiency
          </li>
          <li class=" item item2">
            <i
              class="fa fa-circle primary-text mr-2 dot"
              aria-hidden="true"
            ></i>
            Wide array of domain areas that are relevant for emerging trends
          </li>
          <li class=" item item3">
            <i
              class="fa fa-circle primary-text mr-2 dot"
              aria-hidden="true"
            ></i>
            Get deep dive analysis on performance
          </li>
          <li class=" item item4">
            <i
              class="fa fa-circle primary-text mr-2 dot"
              aria-hidden="true"
            ></i>
            In-depth Training Need Identification analysis
          </li>
          <li class=" item item5">
            <i
              class="fa fa-circle primary-text mr-2 dot"
              aria-hidden="true"
            ></i>
            Rational proposition on learning intervention on developmental areas
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid px-0 py-3">
  <div class="row">
    <div class="col-12 text-center">
      <h2 class="wow fadeInUp">
        How does the learning intervention work
        <span class="primary-text">?</span>
      </h2>
      <hr class="devider wow fadeInUp" />
    </div>
  </div>
  <div class="row how-does-it-work">
    <div class="container">
      <div class="row">
        <div
          class="col-md-3 how-does-it-work-list-item wow fadeInUp"
          data-wow-duration="0.5s"
        >
          <div class="how-does-it-work-icon">
            <img
              src="assets/arthaSiksha/professionals/icons/Training-platform.svg"
              alt=""
            />
          </div>
          <div class="how-does-it-work-text">
            Content developed by highly experienced industry experts and
            delivered through blended training platform (classroom and LMS)
          </div>
        </div>
        <div
          class="col-md-3 how-does-it-work-list-item wow fadeInUp"
          data-wow-duration="1s"
        >
          <div class="how-does-it-work-icon">
            <img
              src="assets/arthaSiksha/professionals/icons/self-learning.svg"
              alt=""
            />
          </div>
          <div class="how-does-it-work-text">
            Self-learning modules along with practice assessments to check
            knowledge retention helping working professionals to manage work and
            learning.
          </div>
        </div>
        <div
          class="col-md-3 how-does-it-work-list-item wow fadeInUp"
          data-wow-duration="1.5s"
        >
          <div class="how-does-it-work-icon">
            <img
              src="assets/arthaSiksha/professionals/icons/new-skill.svg"
              alt=""
            />
          </div>
          <div class="how-does-it-work-text">
            Wide array of areas specially selected keeping in mind the industry
            demands and future changes enabling the learner to either advance
            their knowledge level or learn a new skill.
          </div>
        </div>
        <div
          class="col-md-3 how-does-it-work-list-item wow fadeInUp"
          data-wow-duration="2s"
        >
          <div class="how-does-it-work-icon">
            <img
              src="assets/arthaSiksha/professionals/icons/Learning.svg"
              alt=""
            />
          </div>
          <div class="how-does-it-work-text">
            On a need basis intensive sessions can be arranged both by way of
            classroom trainings or interactive live trainings by experts.
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container py-3">
  <div class="row">
    <div class="col-12 text-center">
      <h2 class="wow fadeInUp">
        Benefits for Working Professionals <span class="primary-text">?</span>
      </h2>
      <hr class="devider wow fadeInUp" />
    </div>
  </div>
  <div class="row benefits">
    <div
      class="col-md-6 col-lg-6 col-sm-12 benefits-list-item wow fadeInUp"
      data-wow-duration="0.5s"
    >
      <div class="benefits-icon">
        <img
          src="assets/arthaSiksha/professionals/icons/Free-to-choose.svg"
          alt=""
        />
      </div>
      <!-- <div class="benefits-header">
        Heading
      </div> -->
      <div class="benefits-text">
        Freedom and flexibility to choose from the most advanced and scientific
        course catalogue with an option to attend via classroom and/or virtual.
      </div>
    </div>
    <div
      class="col-md-6 col-lg-6 col-sm-12 benefits-list-item wow fadeInUp"
      data-wow-duration="1s"
    >
      <div class="benefits-icon">
        <img
          src="assets/arthaSiksha/professionals/icons/intellegent-assessment.svg"
          alt=""
        />
      </div>
      <!-- <div class="benefits-header">
        Heading
      </div> -->
      <div class="benefits-text">
        Intelligent assessment system to check knowledge retention along with
        feedback and corrective actions
      </div>
    </div>
    <div
      class="col-md-6 col-lg-6 col-sm-12 benefits-list-item wow fadeInUp"
      data-wow-duration="1.5s"
    >
      <div class="benefits-icon">
        <img
          src="assets/arthaSiksha/professionals/icons/industry-experts.svg"
          alt=""
        />
      </div>
      <!-- <div class="benefits-header">
        Heading
      </div> -->
      <div class="benefits-text">
        Personalized training sessions by industry experts (both in-person or
        virtual).
      </div>
    </div>
    <div
      class="col-md-6 col-lg-6 col-sm-12 benefits-list-item wow fadeInUp"
      data-wow-duration="2s"
    >
      <div class="benefits-icon">
        <img
          src="assets/arthaSiksha/professionals/icons/Learning-management-system.svg"
          alt=""
        />
      </div>
      <!-- <div class="benefits-header">
        Heading
      </div> -->
      <div class="benefits-text">
        Synchronous and Asynchronous learning by using industry leading learning
        management system.
      </div>
    </div>
  </div>
</div>
</div>