<div class="home-banner">
  <div class="container-fluid" style="height: calc(100vh - 90px)">
    <div class="row h-100 align-items-center pl-5">
      <div class="col-md-6 pl-5" style="background: #ffffffb0;">
        <h1 class="secondary-text wow fadeInLeft">Connecting Campus to Career</h1>
        <hr class="devider ml-0 wow fadeInLeft" />
        <h3 style="line-height: 40px" class="wow fadeInLeft">
          To create a pool of highly-skilled, knowledgeable and marketable
          graduates and professionals in Finance and Accounts domain and prepare
          them for the upcoming challenges by providing them with right tools
          and guidance.
        </h3>
      </div>
    </div>
  </div>
</div>
<!-- <div
    class="container-fluid d-flex justify-content-center"
    style="background: var(--primary-background-color)"
  >
    <h3 class="moto text-center">Connecting Campus with Career</h3>
  </div> -->
<div class="container-fluid py-5 px-md-5">
  <div class="row">
    <div class="col-12">
      <h1 class="text-center wow fadeInUp">Our Services</h1>
      <hr class="devider wow fadeInUp" />
    </div>
  </div>
  <div class="row py-5">
    <div class="col-md-3 col-lg-3 col-sm-6">
      <div
        class="px-sm-3 px-md-5 h-100 py-5 service-box wow fadeInUp"
        data-wow-duration="0.5s"
      >
        <div class="icon">
          <img
            src="assets/arthaSiksha/icons/students.png"
            class="menu-img"
            alt="menu-image"
          />
        </div>
        <div class="heading my-4">
          <h5 class="text-center">Students</h5>
        </div>
        <div class="text">
          Jump start your career with us by experiencing the real-time on the
          job training which empowers you to face the real-world challenges.
        </div>
        <div class="link" routerLink="student">Click to Know more</div>
      </div>
    </div>
    <div class="col-md-3 col-lg-3 col-sm-6">
      <div
        class="px-sm-3 px-md-5 h-100 py-5 service-box wow fadeInUp"
        data-wow-duration="1s"
      >
        <div class="icon">
          <img
            src="assets/arthaSiksha/icons/professional.png"
            class="menu-img"
            alt="menu-image"
          />
        </div>
        <div class="heading my-4">
          <h5 class="text-center">Professionals</h5>
        </div>
        <div class="text">
          Advance your career by upskilling and/or reskilling yourselves with
          the latest and most sought out skills in the areas of finance,
          accounting and auditing.
        </div>
        <div class="link" routerLink="professionals">Click to Know more</div>
      </div>
    </div>
    <div class="col-md-3 col-lg-3 col-sm-6">
      <div
        class="px-sm-3 px-md-5 h-100 py-5 service-box wow fadeInUp"
        data-wow-duration="1.5s"
      >
        <div class="icon">
          <img
            src="assets/arthaSiksha/icons/institution.png"
            class="menu-img"
            alt="menu-image"
          />
        </div>
        <div class="heading my-4">
          <h5 class="text-center">Academic Institutions</h5>
        </div>
        <div class="text">
          Supplement students’ learning by imparting real-world experience into
          day to day learning by utilizing our industry edge talent enhancement
          programs specially curated to bridge the gap between academic world
          and corporate world.
        </div>
        <div class="link" routerLink="academic">Click to Know more</div>
      </div>
    </div>
    <div class="col-md-3 col-lg-3 col-sm-6">
      <div
        class="px-sm-3 px-md-5 h-100 py-5 service-box wow fadeInUp"
        data-wow-duration="2s"
      >
        <div class="icon">
          <img
            src="assets/arthaSiksha/icons/organization.png"
            class="menu-img"
            alt="menu-image"
          />
        </div>
        <div class="heading my-4">
          <h5 class="text-center">Organizations</h5>
        </div>
        <div class="text">
          <!-- Tailored training solutions which include management of your L&D
          function for upskilling, reskilling and initial orientation. We also
          provide readily deployable highly-skilled workforce for your
          short-term needs and/or full-time recruitment. -->
          Readily deployable highly-skilled workforce for your short-term needs
          and/or full-time recruitment. We also provide tailored training
          solutions which include management of your L&D function for
          upskilling, reskilling and initial orientation
        </div>
        <div class="link" routerLink="organization">Click to Know more</div>
      </div>
    </div>
  </div>
</div>

<!-- <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel"  data-interval="3000">
  <ol class="carousel-indicators">
    <li
      data-target="#carouselExampleIndicators"
      data-slide-to="0"
      class="active"
    ></li>
    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
    <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
    <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
  </ol>
  <div class="carousel-inner">
    <div
      class="carousel-item active"
      style="background-image: url(/assets/arthaSiksha/banners/student-banner1.jpg)"
    >
      <div class="container h-100 ml-md-5 carousel-data" >
        <div class="row ml-md-5">
          <div class="col-12 p-4">
            <p style="color: black;">
              Jump start your career with us by experiencing the real-time on the job training which empowers you to face the real-world challenges.
            </p>
            <button class="button-slider" routerLink="student">Click to know more</button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="carousel-item"
      style="background-image: url(/assets/arthaSiksha/banners/professional-banner.jpg)"
    >
    <div class="container h-100 ml-md-5 carousel-data" >
      <div class="row ml-md-5">
        <div class="col-12 p-4">
          <p>
            Advance your career by upskilling and/or reskilling yourselves with the latest and most sought out skills in the areas of finance, accounting and auditing.
          </p>
          <button class="button-slider" routerLink="professionals">Click to know more</button>
        </div>
      </div>
    </div></div>
    <div
      class="carousel-item"
      style="background-image: url(/assets/arthaSiksha/banners/institution-banner.jpg)"
    >
    <div class="container h-100 ml-md-5 carousel-data" >
      <div class="row ml-md-5">
        <div class="col-12 p-4">
          <p style="color: black;">
            Supplement students’ learning by imparting real-world experience into day to day learning by utilizing our industry edge talent enhancement programs specially curated to bridge the gap between academic world and corporate world.
          </p>
          <button class="button-slider" routerLink="academic">Click to know more</button>
        </div>
      </div>
    </div></div>
    <div
      class="carousel-item"
      style="background-image: url(/assets/arthaSiksha/banners/organization-banner.jpg)"
    >
    <div class="container h-100 ml-md-5 carousel-data" >
      <div class="row ml-md-5">
        <div class="col-12 p-4">
          <p>
            Tailored training solutions which include management of your L&D function for upskilling, reskilling and initial orientation.  We also provide readily deployable highly-skilled workforce for your short-term needs and/or full-time recruitment.
          </p>
          <button class="button-slider" routerLink="organization">Click to know more</button>
        </div>
      </div>
    </div>
  </div>

   
  </div>
  <a
    class="carousel-control-prev justify-content-start primary-text"
    href="#carouselExampleIndicators"
    role="button"
    data-slide="prev"
  >
    <i
      class="fa fa-angle-left ml-5"
      style="font-size: 2.5rem"
      aria-hidden="true"
    ></i>
    <span class="sr-only">Previous</span>
  </a>
  <a
    class="carousel-control-next justify-content-end primary-text"
    href="#carouselExampleIndicators"
    role="button"
    data-slide="next"
  >
    <i
      class="fa fa-angle-right mr-5"
      style="font-size: 2.5rem"
      aria-hidden="true"
    ></i>
    <span class="sr-only">Next</span>
  </a>
</div> -->
