import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { contactDetails } from "../model/contactDetails.model";
import { filter, flatMap, mergeMap, tap } from 'rxjs/operators';


// urls
const GET_CONTACT_DETAILS = "/api/contact-details";
const SAVE_CONTACT_DETAILS = "/api/contact-details/save";
const SAVE_USER_QUERY = "/api/user-query/save";
const GET_USER_QUERY = "/api/user-query";
const SAVE_COURSE = "/api/course/save";
const UPDATE_COURSE = "/api/course/update";
const GET_COURSES = "/api/courses";
const GET_COURSE_BY_ID = "/api/course";
const DELETE_COURSE_BY_ID = "/api/course/delete";
const TOGGLE_COURSE_STATUS = "/api/course/toggle/status";
const GET_FAQ = "/api/faqs";
const SAVE_FAQ = "/api/faq/save";
const TOGGLE_FAQ_STATUS = "/api/faq/toggle/status";
const DELETE_FAQ_BY_ID = "/api/faq/delete";
const UPDATE_FAQ = "/api/faq/update";
const GETCOURSENAMESANDORDER = "api/course/names-orders";
@Injectable({
  providedIn: "root",
})
export class ApiService {
  constructor(private http: HttpClient,private router:Router) {}

  post(url, data) {
    return this.http.post(url, data,{
      headers:new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      observe: 'response' as 'response'
    });
  }
  get(url) {
    return this.http.get<any>(url,{
      headers:new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      
      observe: 'response' as 'response'
    });
  }
  adminPost(url, data) {
    var key = localStorage.getItem('key');
    if(!key){
      this.router.navigate(['/admin-login']);
    }
    return this.http.post(url, data,{
      headers:new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': key
      }),
      observe: 'response' as 'response',
      withCredentials: true
    });
  }
  adminGet(url) {
    var key = localStorage.getItem('key');
    if(!key){
      this.router.navigate(['/admin-login']);
    }
    return this.http.get(url,{
      headers:new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': key
      }),
      observe: 'response' as 'response',
      withCredentials: true
    });
  }
  saveContactDetails(contactDetails: contactDetails) {
    return this.post(SAVE_CONTACT_DETAILS, contactDetails);
  }
  getContactDetails(){
      return this.adminGet(GET_CONTACT_DETAILS);
  }

  saveUserQuery(userQuery){
    return this.post(SAVE_USER_QUERY,userQuery)
  }
  getUserQuery(){
    return this.adminGet(GET_USER_QUERY);
  }
  saveCourse(course){
    return this.adminPost(SAVE_COURSE,course);
  }
  updateCoure(course){
    return this.adminPost(UPDATE_COURSE,course);
  }
  getCourses(){
    return this.get(GET_COURSES);
  }
  getCoursesById(id){
    return this.adminPost(GET_COURSE_BY_ID,id);
  }
  getCourseNamesAndOrder(){
    return this.adminGet(GETCOURSENAMESANDORDER);
  }
  deleteCoursesById(id){
    return this.adminPost(DELETE_COURSE_BY_ID,id);
  }
  toggleStatus(id){
    return this.adminPost(TOGGLE_COURSE_STATUS,id);
  }
  getFaqs(){
    return this.get(GET_FAQ);
  }
  saveFaq(faq){
    return this.adminPost(SAVE_FAQ,faq);
  }
  toggleFaqStatus(id){
    return this.adminPost(TOGGLE_FAQ_STATUS,id);
  }
  deleteFaq(id){
    return this.adminPost(DELETE_FAQ_BY_ID,id);
  }
  updateFaq(faq){
    return this.adminPost(UPDATE_FAQ,faq);
  }
  getActiveCourses(){
    return this.getCourses().pipe(mergeMap((res:any)=>res.body.filter(course=>course == "Active")));
    // this.getCourses().pipe(tap(res=>{
    //   var result = res.body 
    //   res.body = filter(course=>course.status ==== "Status")
    // }
    // )
    //   );
  }
}
