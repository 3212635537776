import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-transaction-response',
  templateUrl: './transaction-response.component.html',
  styleUrls: ['./transaction-response.component.less']
})
export class TransactionResponseComponent implements OnInit {

  public transactionMessage: string;
  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.transactionMessage = this.route.snapshot.paramMap.get("message");
  }

}
