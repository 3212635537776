import { Component, OnInit, Input , Inject} from '@angular/core';
import { AccordianService } from '../../../../services/accordian.service';

declare var $:any;
@Component({
  selector: 'app-tab1',
  templateUrl: './tab1.component.html',
  styleUrls: ['./tab1.component.less']
})
export class Tab1Component implements OnInit {
  @Input() sectionData: string;
  selectedItem: any;

  constructor(
    private accService: AccordianService
  ) {}
  
  skilldata = [];
  ngOnInit() {
    this.skilldata = this.accService.getCoursesData();
    var section = this.sectionData;
    $(document).ready(function(){
      const $cardHeader = $(".tab1 .nav-pills");
      const $innerLink = $(".innertab .nav-tabs");
      const $courseList = $(".innertab .course-list");
      const eletop = $cardHeader.offset().top;
      function checkSlide(){
        var wintop = window.scrollY;
        if(wintop > ( eletop - 60 )){
          $cardHeader.addClass("sticky");
          $innerLink.addClass("sticky");
          $courseList.addClass("sticky");
        }
        else {
          $cardHeader.removeClass("sticky");
          $innerLink.removeClass("sticky");
          $courseList.removeClass("sticky");
        }
      }
      $(document).on("scroll", checkSlide);
      $cardHeader.find(".nav-item a").on("click", function(){
        $('html,body').animate({scrollTop: eletop - 70},'slow');
      });

      $innerLink.find("a.nav-link").on("click", function(){
        $('html,body').animate({scrollTop: eletop - 70},'slow');
      });

      (()=>{
        const $navlink = $(".tab1 .nav-link");
        const $tabpane = $(".tab1 .tab-pane");

        if(section!=undefined){
          $navlink.each((id, element)=>{
            const $ele = $(element);
            $ele.removeClass("active");
            if($ele.attr('href')=='#'+section){
              $ele.addClass('active');
            }
          });
          $tabpane.each((id, element)=>{
            const $ele = $(element);
            $ele.removeClass("active");
            if($ele.attr('id')==section){
              $ele.removeClass("fade").addClass("active");
            }
          });
        }
        else return;
      })();
    });
  }
  scrollToContent(i){
    document.querySelector("#content"+i).scrollIntoView({behavior: "smooth", block: "start", inline: "center"});

  }
  scrollToOtherContent(j){
    document.querySelector("#othercontent"+j).scrollIntoView({behavior: "smooth", block: "start", inline: "center"});
      
  }
}
