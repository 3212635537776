<div class="container-fluid p-0">
  <div class="image-container">
    <div class="image"></div>
  </div>
</div>
<div class="bg-academic1">
  <div class="container">
    <div class="row entry-content">
      <div class="col-12">
        <h3 class="text-center wow fadeInUp">
          We supplement your learning mission.
        </h3>
        <hr class="devider wow fadeInUp" />
      </div>
      <p class="text-center wow fadeInUp">
        Globalization was instrumental for several sectors in India witnessing
        high growth and F&A is the latest sector to benefit that demand. An
        increased emphasis on low cost high quality resources in the outsourcing
        sector and with India slowly becoming the most favoured destination in
        BPO sector, the demand for highly skilled entry level professionals is
        the highest ever. Unfortunately the growth in demand could not be met
        with a highly skilled, readily deployable graduates.
        <br />
        <br />
        One of the main reason is that our Academic institutions are compliant
        to the curriculum prescribed and followed by the Board of Higher
        Education. While the curriculum does build a strong foundation, it does
        leave lots of critical aspects empty which are not just critical from a
        well-rounded skilled workforce but also the industry demand and emerging
        trends.
        <br />
        <br />
        We at Artha Siksha want to address this very skill gap by creating
        robust learning processes and interventions that makes a Student well
        equipped with foundational as well as advanced competencies that are
        required for effective and efficient transition from academic career to
        professional career.
      </p>
      <div class="button-wrapper mx-auto wow fadeInUp">
        <button routerLink="/academic-register">Register</button>
      </div>
    </div>
  </div>
  <div class="container py-5">
    <div class="row">
      <div class="col-12">
        <h2 class="text-center wow fadeInUp">Our Courseware essentials</h2>
        <hr class="devider wow fadeInUp" />
      </div>
    </div>
    <div class="row essentials-container justify-content-center text-center">
      <div class="col-md-4 col-lg-4 col-sm-12 p-4 wow fadeInUp">
        <div class="content-container content-container1">
          Every course is curated with industry expectations in mind and in
          consultation with academic institution so that the training is
          targeted specifically to the needs of learner.
        </div>
      </div>
      <div class="col-md-4 col-lg-4 col-sm-12 p-4 wow fadeInUp">
        <div class="content-container content-container-2 content-container2">
          Completely digitized delivery with an option of blended delivery
          mechanism using industry leading LMS.
        </div>
      </div>
    </div>
    <div class="row essentials-container justify-content-center text-center">
      <div class="col-md-4 col-lg-4 col-sm-12 p-4 wow fadeInUp">
        <div class="content-container content-container3">
          Self learning modules with practice assessments to increase the
          learner knowledge retention.
        </div>
      </div>
      <div class="col-md-4 col-lg-4 col-sm-12 p-4 wow fadeInUp">
        <div class="content-container content-container-2 content-container4">
          Post assessment certification along with placement assistance on need
          basis.
        </div>
      </div>
    </div>
  </div>
</div>
<div class="bg-academic2">
  <div class="container py-5">
    <div class="row py-5 justify-content-center">
      <div class="col-md-8 col-sm-12 text-center">
        <h2 class="wow fadeInUp">
          How does it work <span class="primary-text">?</span>
        </h2>
        <hr class="devider wow fadeInUp" />
      </div>
      <div class="col-md-8 col-sm-12 text-center">
        <p class="wow fadeInUp">
          Artha Siksha caters to holistic Learning process for the students in
          the institution for F&A domain. The learning journey of the students
          right from the profile creation till the certification is documented,
          recorded and managed through LMS which gives the institution a higher
          degree of control and governance. The overall structure of the program
          also includes the closing aspect of coaching & mentoring the students
          with essential Employability Skills.
        </p>
      </div>
      <div class="col-12 wow fadeInUp">
        <img
          src="/assets/arthaSiksha/academic-howitWorks.png"
          alt=""
          class="img-fluid p-5"
        />
      </div>
    </div>
  </div>

  <div class="container py-5">
    <div class="row benefits">
      <div class="col-12">
        <h2 class="text-center wow fadeInUp">Benefits for the Institution</h2>
        <hr class="devider wow fadeInUp mb-5" />
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12">
        <div class="benefits-image-container wow fadeInLeft"></div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12">
        <div class="content wow fadeInRight" data-wow-duration="0.5s">
          <!-- <h4>Heading</h4> -->
          <p>
            Most up-to-date course curriculum delivered by experts helps in
            reducing the workload on the institutions’ resources which can be
            best utilized elsewhere.
          </p>
        </div>
        <div class="content wow fadeInRight" data-wow-duration="1s">
          <!-- <h4>Heading</h4> -->
          <p>
            Practical exposure to the students right in the campus which will
            help in upward conversion of students for placements.
          </p>
        </div>
        <div class="content wow fadeInRight" data-wow-duration="1.5s">
          <!-- <h4>Heading</h4> -->
          <p>
            Exposure to global accounting foundations as the training design and
            delivery by highly qualified industry experts.
          </p>
        </div>
        <div class="content wow fadeInRight" data-wow-duration="2s">
          <!-- <h4>Heading</h4> -->
          <p>
            Industry recognized certification with placement assistance as and
            when needed.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <div class="container py-5">
  <div class="row effecting-learning align-items-center py-5">
    <div class="col-lg-6 col-md-6 col-sm-12 pl-5 text-left">
      <h2 class="text-left">Benefits for the Institution</h2>
      <hr class="devider wow fadeInUp mb-3 ml-0" />
      <p>
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repellendus
        pariatur aut magni explicabo cumque adipisci nihil, quasi, veniam natus
        sequi architecto sint id suscipit fugiat doloremque vel, porro commodi
        maxime?
      </p>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-12 effecting-learning-list">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 text-container">
          <div class="text">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere
            explicabo architecto labore reprehenderit vel eos aut sed, est
            voluptatum maxime.
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 text-container">
          <div class="text">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere
            explicabo architecto labore reprehenderit vel eos aut sed, est
            voluptatum maxime.
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 text-container">
          <div class="text">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere
            explicabo architecto labore reprehenderit vel eos aut sed, est
            voluptatum maxime.
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 text-container">
          <div class="text">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere
            explicabo architecto labore reprehenderit vel eos aut sed, est
            voluptatum maxime.
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->
