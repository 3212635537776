import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";

declare var $: any;
@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.less"],
})
export class RegisterComponent implements OnInit {
  userType;
  registerForm: FormGroup;

  isStudent: boolean = false;
  isprofessional: boolean = false;
  isAcedemic: boolean = false;
  isOrganization: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      params.user ? (this.userType = params.user) : "";
      $("#" + this.userType).trigger("click");
    });
  }
  changeUser(userType) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { user: userType },
      queryParamsHandling: "merge",
    });
  }
  loadFormGroup(form) {
    var formData = this.registerForm ? this.registerForm.value : "";
    this.registerForm = this.fb.group(form);
    formData && this.registerForm.patchValue(formData);
  }
  setAllUserFalse() {
    this.isStudent = false;
    this.isprofessional = false;
    this.isAcedemic = false;
    this.isOrganization = false;
  }
}
