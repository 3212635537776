import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.less']
})
export class AboutUsComponent implements OnInit {

  constructor(
    private router:Router
  ) { }

  ngOnInit() {
  }
  
  toAboutUs(id) {
    this.router.navigate(['/about-us']).then(() => {
      document.querySelector('#' + id).scrollIntoView({
        behavior: 'smooth'
      })
    })
  }

}
