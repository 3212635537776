<div class="container-fluid">
  <div id="accordion" class="accordion1">
    <div class="card container" *ngFor="let pdata of skilldata">
      <div class="card-header">
        <a class="card-link" [ngClass]="pdata.show?'active':''" data-toggle="collapse" href="#{{pdata.sname}}">
          {{pdata.name}}
        </a>
      </div>
      <div id="{{pdata.sname}}" [ngClass]="pdata.show?'collapse show content':'collapse content'" data-parent="#accordion">
        <div class="sub-tab" *ngFor="let cdata of pdata.subdata">
          <div *ngIf="cdata.name" class="heading container">
            <h2>{{cdata.name}}</h2>
            <p>{{cdata.content}}</p>
          </div>
          <div class="section-tab container" *ngFor="let gcdata of cdata.coursedata">
            <div class="first">
              <h3>{{gcdata.name}}</h3>
              <ul class="describe">
                <li>{{gcdata.type}}</li>
                <li>{{gcdata.Duration}}</li>
                <li>{{gcdata.Access}}</li>
              </ul>
              <p>{{gcdata.objective}}</p>
              <h4 class="overview">
                Course Overview :
              </h4>
              <ul class="overview">
                <li *ngFor="let gcdatalist of gcdata.overview"> {{gcdatalist}}</li>
              </ul>
            </div>
            <div class="section-tab last flex-pc">
              <img src="{{gcdata.img}}" alt="{{gcdata.name}} image" title="{{gcdata.name}} image" class="img-fluid">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>