import { Component, OnInit } from '@angular/core';

declare var $:any;
@Component({
  selector: 'app-slider1',
  templateUrl: './slider1.component.html',
  styleUrls: ['./slider1.component.less']
})
export class Slider1Component implements OnInit {
  constructor() {
  }

  ngOnInit() {
  //   $(document).ready(function() {
  //     $("#carouselExampleIndicators").swiperight(function() {
  //        $(this).carousel('prev');
  //      });
  //     $("#carouselExampleIndicators").swipeleft(function() {
  //        $(this).carousel('next');
  //     });
  //  });
  }

}
