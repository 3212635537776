<div class="banner">
  <h2>Corporate Enrollment</h2>
</div>

<!-- MultiStep Form -->
<div class="container">
  <div class="flex-container flex-jc-c row">
      <div class="col-md-12 col-sm-12 col-xs-12">
          <!-- <form id="msform" (ngSubmit)="onFormSubmit()" [formGroup]="enrollForm"> -->
          <form class="form-sp" [formGroup]="enrollForm" (ngSubmit)="onFromSubmit()">
              
              <!-- progressbar -->
              <ul id="progressbar">
                  <li class="active">Organization Information</li>
                  <li>Registered Office</li>
                  <li>Contact Details</li>
                  <li>Miscellaneous</li>
                  <li>Done</li>
              </ul>

              <!-- fieldsets 1-->
              <!-- Organization Information -->
              <fieldset  *ngIf="formstep==1" formGroupName="orgInfo">
                  <div class="space-20"></div>
                  <h2 class="fs-title">Organization Information</h2>
                  <div class="space-20"></div>
                  <div class="row">
                      
                      <!-- Employer Registered With -->
                      <div class="col-md-6 col-sm-6 col-12 form-group">
                          <select name="empRegWith" formControlName="empRegWith">
                              <option value="" class="" selected="selected">Select</option>
                              <option label="Economic_Census" value="Economic_Census">Economic_Census</option>
                              <option label="LIN" value="LIN">LIN</option>
                              <option label="MCA" value="MCA">MCA</option>
                          </select>
                          <label for="select" class="control-label">Employer Registered With</label>
                          <i class="bar"></i>
                          <p class="has-error" *ngIf="enrollForm.get('orgInfo.empRegWith').invalid && enrollForm.get('orgInfo.empRegWith').touched">
                              <small>
                                  Select an option
                              </small>
                          </p>
                      </div>
                      <!-- Registration number -->
                      <div class="col-md-6 col-sm-6 col-12 form-group">
                          <input type="text" name="regNumber" formControlName="regNumber" />
                          <label for="input" class="control-label">Registration number</label>
                          <i class="bar"></i>
                          <p class="has-error" *ngIf="enrollForm.get('orgInfo.regNumber').invalid && enrollForm.get('orgInfo.regNumber').touched">
                              <small>
                                  Enter your registration number
                              </small>
                          </p>
                      </div>

                      <!-- Organization name -->
                      <div class="col-md-6 col-sm-6 col-12 form-group">
                          <input type="text" name="orgName" formControlName="orgName" />
                          <label for="input" class="control-label">Organization name</label>
                          <i class="bar"></i>
                          <p class="has-error" *ngIf="enrollForm.get('orgInfo.orgName').invalid && enrollForm.get('orgInfo.orgName').touched">
                              <small>
                                  Enter your organization name
                              </small>
                          </p>
                      </div>
          
                      <!-- Former organization name -->
                      <div class="col-md-6 col-sm-6 col-12 form-group">
                          <input type="text" name="formerOrgName" formControlName="formerOrgName" />
                          <label for="input" class="control-label">Former organization name</label>
                          <i class="bar"></i>
                          <p class="has-error" *ngIf="enrollForm.get('orgInfo.formerOrgName').invalid && enrollForm.get('orgInfo.formerOrgName').touched">
                              <small>
                                  Enter your former organization name
                              </small>
                          </p>
                      </div>
          
                      <!-- Organization type -->
                      <div class="col-md-6 col-sm-6 col-12 form-group">
                          <select name="orgType" formControlName="orgType">
                              <option value="" class="" selected="selected">Select</option>
                              <option label="Private" value="Private">Private</option>
                              <option label="Govt" value="Govt">Govt</option>
                          </select>
                          <label for="select" class="control-label">Organization type</label>
                          <i class="bar"></i>
                          <p class="has-error" *ngIf="enrollForm.get('orgInfo.orgType').invalid && enrollForm.get('orgInfo.orgType').touched">
                              <small>
                                  Select an option
                              </small>
                          </p>
                      </div>
          
                      <!-- Sector -->
                      <div class="col-md-6 col-sm-6 col-12 form-group">
                          <select name="sector" formControlName="sector">
                              <option value="" class="" selected="selected">Select</option>
                              <option label="Private" value="Private">Private</option>
                              <option label="Govt" value="Govt">Govt</option>
                          </select>
                          <label for="select" class="control-label">Sector</label>
                          <i class="bar"></i>
                          <p class="has-error" *ngIf="enrollForm.get('orgInfo.sector').invalid && enrollForm.get('orgInfo.sector').touched">
                              <small>
                                  Select an option
                              </small>
                          </p>
                      </div>
          
                      <!-- Organization PAN -->
                      <div class="col-md-6 col-sm-6 col-12 form-group">
                          <input type="text" name="orgPan" formControlName="orgPan" />
                          <label for="input" class="control-label">Organization PAN</label>
                          <i class="bar"></i>
                          <p class="has-error" *ngIf="enrollForm.get('orgInfo.orgPan').invalid && enrollForm.get('orgInfo.orgPan').touched">
                              <small>
                                  Enter your Organization PAN
                              </small>
                          </p>
                      </div>
                      
                      <!-- Qualification -->
                      <div class="col-md-6 col-sm-6 col-12 form-group">
                          <textarea formControlName="desc" rows="1"></textarea>
                          <label for="textarea" class="control-label">Description</label>
                          <i class="bar"></i>
                          <p class="has-error" *ngIf="enrollForm.get('orgInfo.desc').invalid && enrollForm.get('orgInfo.desc').touched">
                              <small>
                              Enter your Description
                              </small>
                          </p>
                      </div>

                  </div>
                  <button type="button" name="submit" class="next action-button" [ngClass]="!enrollForm.get('orgInfo').valid?'disabled':''"
              [disabled]="!enrollForm.get('orgInfo').valid">submit</button>
                  <!-- <input 
                      type="button" 
                      name="next" 
                      class="next action-button"
                      [ngClass]="enrollForm.get('orgInfo').invalid?'disabled':''"
                      value="Next"
                      /> -->
                      <!-- [disabled]="enrollForm.get('orgInfo').invalid" -->
              </fieldset>
              
              <!-- fieldsets 2-->
              <!-- Registered Office -->
              <fieldset  *ngIf="formstep==1" formGroupName="regOffice">
                  <div class="space240"></div>
                  <h2 class="fs-title">Registered Office</h2>
                  <div class="space240"></div>
                  <div class="row">

                      <!-- Address 1 -->
                      <div class="col-md-4 col-sm-4 col-12 form-group">
                          <textarea formControlName="address1"></textarea>
                          <label for="textarea" class="control-label">Address 1</label>
                          <i class="bar"></i>
                          <p class="has-error" *ngIf="enrollForm.get('regOffice.address1').invalid && enrollForm.get('regOffice.address1').touched">
                              <small>
                              Enter your Address
                              </small>
                          </p>
                      </div>

                      <!-- Address 2 -->
                      <div class="col-md-4 col-sm-4 col-12 form-group">
                          <textarea formControlName="address2"></textarea>
                          <label for="textarea" class="control-label">Address 2 (Optional)</label>
                          <i class="bar"></i>
                      </div>

                      <!-- Address 3 -->
                      <div class="col-md-4 col-sm-4 col-12 form-group">
                          <textarea formControlName="address3"></textarea>
                          <label for="textarea" class="control-label">Address 3 (Optional)</label>
                          <i class="bar"></i>
                      </div>

                      <!-- CONTRY SELECTOR -->
                      <div class="col-md-6 col-sm-6 col-12 form-group">
                          <select name="country" formControlName="country" (change)=getStatesByCountry($event)>
                              <option [ngValue]="null">Select</option>
                              <option *ngFor="let condata of countries" value="{{condata.id}}">{{condata.countryName}}</option>
                          </select>
                          <label for="select" class="control-label">Select Country</label>
                          <i class="bar"></i>
                          <p class="has-error" *ngIf="enrollForm.get('regOffice.country').invalid && enrollForm.get('regOffice.country').touched">
                              <small>
                                  Select a Country
                              </small>
                          </p>
                      </div>

                      <!-- STATE SELECTOR -->
                      <div class="col-md-6 col-sm-6 col-12 form-group">
                          <select name="state" formControlName="state">
                              <option [ngValue]="null">Select a state</option>
                              <option *ngFor="let cdata of states" value="{{cdata.id}}">{{cdata.state}}</option>   
                          </select>
                          <label for="select" class="control-label">State </label>
                          <i class="bar"></i>
                          <p class="has-error" *ngIf="enrollForm.get('regOffice.state').invalid && enrollForm.get('regOffice.state').touched">
                              <small>
                                  Select a State
                              </small>
                          </p>
                      </div>
                      
                      <!-- DISTRICT SELECTOR -->
                      <!-- <div class="col-md-6 col-sm-6 col-12 form-group">
                          <select name="district" formControlName="district">
                              <option value="" class="" selected="selected">Select</option>
                              <option value="1, Anantapur" >Anantapur</option>
                              <option value="2, Guntur" >Guntur</option>
                              <option value="6, East Godavari" >East Godavari</option>
                              <option value="7, West Godavari"  selected="selected">West Godavari</option>
                              <option value="8, Vizianagaram" >Vizianagaram</option>
                              <option value="9, Chittoor" >Chittoor</option>
                              <option value="10, Vishakhapatnam" >Vishakhapatnam</option>
                              <option value="11, Cuddapah" >Cuddapah</option>
                              <option value="12, Krishna" >Krishna</option>
                              <option value="13, Kurnoolll" >Kurnoolll</option>
                              <option value="14, Prakasam" >Prakasam</option>
                              <option value="15, Srikakulam" >Srikakulam</option>
                              <option value="16, Nellore" >Nellore</option>
                              <option value="17, NA" >NA</option>
                          </select>
                          <label for="select" class="control-label">District</label>
                          <i class="bar"></i>
                          <p class="has-error" *ngIf="enrollForm.get('regOffice.district').invalid && enrollForm.get('regOffice.district').touched">
                              <small>
                                  Select an option
                              </small>
                          </p>
                      </div> -->
          
                      <!-- Locality -->
                      <div class="col-md-6 col-sm-6 col-12 form-group">
                          <select name="locality" formControlName="locality">
                              <option value="" class="" selected="selected">Select</option>
                              <option value="1, Urban" >Urban</option>
                              <option value="2, Rural" >Rural</option>
                          </select>
                          <label for="input" class="control-label">Locality</label>
                          <i class="bar"></i>
                          <p class="has-error" *ngIf="enrollForm.get('regOffice.locality').invalid && enrollForm.get('regOffice.locality').touched">
                              <small>
                                  Enter your Locality name
                              </small>
                          </p>
                      </div>
                      <!-- City/Village -->
                      <div class="col-md-6 col-sm-6 col-12 form-group">
                          <input type="text" name="city" formControlName="city" (change)="trimValue($event, 'regOffice.city')"/>
                          <label for="input" class="control-label">City/Village</label>
                          <i class="bar"></i>
                          <p class="has-error" *ngIf="enrollForm.get('regOffice.city').invalid && enrollForm.get('regOffice.city').touched">
                              <small>
                                  Enter your city/village name
                              </small>
                          </p>
                      </div>
                      
                      <!-- Pincode -->
                      <div class="col-md-6 col-sm-6 col-12 form-group">
                          <input type="number" name="pcode" formControlName="pcode" (input)="checkLength($event, 'regOffice')" maxlength="6" />
                          <label for="input" class="control-label">Pincode</label>
                          <i class="bar"></i>
                          <p class="has-error" *ngIf="enrollForm.get('regOffice.pcode').invalid && enrollForm.get('regOffice.pcode').touched">
                              <small>
                                  Enter your Pincode
                              </small>
                          </p>
                      </div>

                      <!-- Phone -->
                      <div class="col-md-6 col-sm-6 col-12 form-group">
                          <input type="number" name="phone" formControlName="phone" (input)="checkLength($event, 'regOffice')" maxlength="10"/>
                          <label for="input" class="control-label">Phone </label>
                          <i class="bar"></i>
                          <p class="has-error" *ngIf="enrollForm.get('regOffice.phone').invalid && enrollForm.get('regOffice.phone').touched">
                              <small>
                                  Enter your phone number
                              </small>
                          </p>
                      </div>

                      <!-- Mobile -->
                      <div class="col-md-6 col-sm-6 col-12 form-group">
                          <input type="number" name="mobile" formControlName="mobile" (input)="checkLength($event, 'regOffice')" maxlength="10"/>
                          <label for="input" class="control-label">Mobile</label>
                          <i class="bar"></i>
                          <p class="has-error" *ngIf="enrollForm.get('regOffice.mobile').invalid && enrollForm.get('regOffice.mobile').touched">
                              <small>
                                  Enter your mobile 
                              </small>
                          </p>
                      </div>
                      
                      <!-- Company Email -->
                      <div class="col-md-6 col-sm-6 col-12 form-group">
                          <input type="text" name="cemail" formControlName="cemail" />
                          <label for="input" class="control-label">Company Email</label>
                          <i class="bar"></i>
                          <p class="has-error" *ngIf="enrollForm.get('regOffice.cemail').invalid && enrollForm.get('regOffice.cemail').touched">
                              <small>
                                  Enter your company email
                              </small>
                          </p>
                      </div>

                      <!-- Company Website -->
                      <div class="col-md-6 col-sm-6 col-12 form-group">
                          <input type="text" name="cweb" formControlName="cweb" />
                          <label for="input" class="control-label">Company Website</label>
                          <i class="bar"></i>
                          <p class="has-error" *ngIf="enrollForm.get('regOffice.cweb').invalid && enrollForm.get('regOffice.cweb').touched">
                              <small>
                                  Enter your company website
                              </small>
                          </p>
                      </div>
                  </div>
                  <input 
                      type="button" 
                      name="previous" 
                      class="previous action-button-previous" 
                      value="Previous"
                  />
                  <button type="button" name="submit" class="next action-button" [ngClass]="!enrollForm.get('regOffice').valid?'disabled':''"
                  [disabled]="!enrollForm.get('regOffice').valid">submit</button>
                 <!--  <input 
                      type="button" 
                      name="next" 
                      class="next action-button" 
                      value="Next"
                      [ngClass]="enrollForm.get('regOffice').invalid?'disabled':''"
                      /> -->
                      <!-- [disabled]="enrollForm.get('regOffice').invalid" -->
              </fieldset>
              
              <!-- fieldsets 3-->
              <!-- Contact Details -->
              <fieldset  *ngIf="formstep==1" formGroupName="contact">
                  <div class="space240"></div>
                  <h2 class="fs-title">Contact Details</h2>
                  <div class="space240"></div>
                  <div class="row">
                      <!-- DIUnique Identification(UID) Type SELECTOR -->
                      <div class="col-md-6 col-sm-6 col-12 form-group">
                          <select name="uid" formControlName="uid">
                                  <option value="" class="">Select</option>
                                  <option label="AADHAAR" value="AADHAAR">AADHAAR</option>
                                  <option label="PAN" value="PAN">PAN</option>
                          </select>
                          <label for="select" class="control-label">Unique Identification(UID) Type</label>
                          <i class="bar"></i>
                          <p class="has-error" *ngIf="enrollForm.get('contact.uid').invalid && enrollForm.get('contact.uid').touched">
                              <small>
                                  Select an Unique Identification(UID) 
                              </small>
                          </p>
                      </div>

                      <!-- Unique Identification Number -->
                      <div class="col-md-6 col-sm-6 col-12 form-group">
                          <input type="text" id="uin" name="uin" formControlName="uin" (input)="checkLength($event, 'contact')"/>
                          <label for="input" class="control-label">Unique Identification Number</label>
                          <i class="bar"></i>
                          <p class="has-error" *ngIf="enrollForm.get('contact.uin').invalid && enrollForm.get('contact.uin').touched">
                              <small>
                                  Enter your Unique Identification Number
                              </small>
                          </p>
                      </div>

                      <!-- Person Name (as per selected UID) -->
                      <div class="col-md-6 col-sm-6 col-12 form-group">
                          <input type="text" name="pname" formControlName="pname" (change)="trimValue($event, 'contact.pname')"/>
                          <label for="input" class="control-label">Person Name (as per selected UID)</label>
                          <i class="bar"></i>
                          <p class="has-error" *ngIf="enrollForm.get('contact.pname').invalid && enrollForm.get('contact.pname').touched">
                              <small>
                                  Enter Person Name (as per selected UID)
                              </small>
                          </p>
                      </div>

                      <!-- Guardian/Father's Name -->
                      <div class="col-md-6 col-sm-6 col-12 form-group">
                          <input type="text" name="gname" formControlName="gname" (change)="trimValue($event, 'contact.gname')"/>
                          <label for="input" class="control-label">Guardian/Father's Name</label>
                          <i class="bar"></i>
                          <p class="has-error" *ngIf="enrollForm.get('contact.gname').invalid && enrollForm.get('contact.gname').touched">
                              <small>
                                  Enter Guardian/Father's Name
                              </small>
                          </p>
                      </div>

                      <!-- DOB -->
                      <div class="col-md-6 col-sm-6 col-12 form-group">
                          <input type="date" class="form-control" ngbDatepicker #d="ngbDatepicker"  name="dob" min="1970-01-02" formControlName="dob" onkeydown="return false">
                          <div class="input-group-append datepickBtn">
                              <button class="" (click)="d.toggle()" type="button">
                                  <i class="fa fa-calendar-o" aria-hidden="true"></i>
                              </button>
                          </div>
                          <label for="input" class="control-label">Date of birth (dd-mm-yyyy)</label>
                          <i class="bar"></i>
                          <p class="has-error" *ngIf="enrollForm.get('contact.dob').invalid && enrollForm.get('contact.dob').touched">
                              <small>
                                  Select valid date of birth
                              </small>
                          </p>
                      </div>

                      <!-- Gender -->
                      <div class="col-md-6 col-sm-6 col-12 form-radio">
                          <span class="radio-span">
                              Gender
                          </span>
                          <div class="radio">
                              <label>
                                  <input type="radio" name="gender" [value]="'Male'" formControlName="gender" />
                                  <i class="helper"></i>Male
                              </label>
                          </div>
                          <div class="radio">
                              <label>
                                  <input type="radio" name="gender" [value]="'Female'" formControlName="gender" />
                                  <i class="helper"></i>Female
                              </label>
                          </div>
                      </div>

                      <!-- Contact Number -->
                      <div class="col-md-6 col-sm-6 col-12 form-group">
                          <input type="number" name="c_no" formControlName="c_no" (input)="checkLength($event, 'contact')" maxlength="10"/>
                          <label for="input" class="control-label">Contact Number</label>
                          <i class="bar"></i>
                          <p class="has-error" *ngIf="enrollForm.get('contact.c_no').invalid && enrollForm.get('contact.c_no').touched">
                              <small>
                                  Enter Contact Number 
                              </small>
                          </p>
                      </div>

                      <!-- Mobile Number -->
                      <div class="col-md-6 col-sm-6 col-12 form-group">
                          <input type="number" name="m_no" formControlName="m_no" (input)="checkLength($event, 'contact')" maxlength="10"/>
                          <label for="input" class="control-label">Mobile Number</label>
                          <i class="bar"></i>
                          <p class="has-error" *ngIf="enrollForm.get('contact.m_no').invalid && enrollForm.get('contact.m_no').touched">
                              <small>
                                  Enter Mobile Number
                              </small>
                          </p>
                      </div>

                      <!-- Email Id  -->
                      <div class="col-md-6 col-sm-6 col-12 form-group">
                          <input type="text" name="email" formControlName="email" />
                          <label for="input" class="control-label">Email Id</label>
                          <i class="bar"></i>
                          <p class="has-error" *ngIf="enrollForm.get('contact.email').invalid && enrollForm.get('contact.email').touched">
                              <small>
                                  Enter Email Id 
                              </small>
                          </p>
                      </div>

                      <!-- Contact Designation -->
                      <div class="col-md-6 col-sm-6 col-12 form-group">
                          <input type="text" name="c_desig" formControlName="c_desig" />
                          <label for="input" class="control-label">Contact Designation</label>
                          <i class="bar"></i>
                          <p class="has-error" *ngIf="enrollForm.get('contact.c_desig').invalid && enrollForm.get('contact.c_desig').touched">
                              <small>
                                  Enter Contact Designation 
                              </small>
                          </p>
                      </div>

                      <!-- Check User ID -->
                      <div class="col-md-6 col-sm-6 col-12 form-group">
                          <input type="text" name="userid" formControlName="userid" />
                          <label for="input" class="control-label">Check User ID</label>
                          <i class="bar"></i>
                          <p class="has-error" *ngIf="enrollForm.get('contact.userid').invalid && enrollForm.get('contact.uid').touched">
                              <small>
                                  Enter User ID 
                              </small>
                          </p>
                          <!-- <p class="has-error" *ngIf="enrollForm.get('contact.userid').userExist">
                              <small>
                                  User ID already Exist 
                              </small>
                          </p> -->
                      </div>

                      <!-- Password -->
                      <div class="col-md-6 col-sm-6 col-12 form-group">
                          <input type="password" name="pass" formControlName="pass" />
                          <label for="password" class="control-label">Password</label>
                          <i class="bar"></i>
                          <p class="has-error" *ngIf="enrollForm.get('contact.pass').invalid && enrollForm.get('contact.pass').touched">
                              <small>
                                  The password must contain atleast 8 chatacters eg. User@1234
                              </small>
                          </p>
                      </div>
                      
                      <!-- Confirm Password -->
                      <div class="col-md-6 col-sm-6 col-12 form-group">
                          <input type="password" name="cpass" formControlName="cpass" appConfirmEqualValidator="pass" />
                          <label for="password" class="control-label">Confirm Password</label>
                          <i class="bar"></i>
                          <p class="has-error" *ngIf="enrollForm.get('contact.cpass').invalid && enrollForm.get('contact.cpass').touched">
                              <small>
                                  Enter matching password
                              </small>
                          </p>
                      </div>

                  </div>
                  <input 
                      type="button" 
                      name="previous" 
                      class="previous action-button-previous" 
                      value="Previous"
                  />
                  <button type="button" name="submit" class="next action-button" [ngClass]="!enrollForm.get('contact').valid?'disabled':''"
                  [disabled]="!enrollForm.get('contact').valid">submit</button>
                  <!-- <input 
                      type="button" 
                      name="next" 
                      class="next action-button" 
                      value="Next"
                      [ngClass]="enrollForm.get('contact').invalid?'disabled':''"
                      /> -->
                      <!-- [disabled]="enrollForm.get('contact').invalid" -->
              </fieldset>

              <!-- fieldsets 4-->
              <!-- Miscellaneous -->
              <fieldset  *ngIf="formstep==1" formGroupName="mis">
                  <div class="space240"></div>
                  <h2 class="fs-title">Miscellaneous</h2>
                  <div class="space240"></div>
                  <div class="row">
                      <!-- Year of Incorporation -->
                      <div class="col-md-6 col-sm-6 col-12 form-group">
                          <select name="yoc" formControlName="yoc">
                              <option value="" selected="selected">Select</option>
                              <option value="2010">2010</option>
                              <option value="2011">2011</option>
                              <option value="2012">2012</option>
                              <option value="2013">2013</option>
                              <option value="2014">2014</option>
                              <option value="2015">2015</option>
                              <option value="2016">2016</option>
                              <option value="2017">2017</option>
                              <option value="2018">2018</option>
                              <option value="2019">2019</option>
                              <option value="2020">2020</option>
                              <option value="2021">2021</option>
                          </select>
                          <label for="select" class="control-label">Year of Incorporation</label>
                          <i class="bar"></i>
                          <p class="has-error" *ngIf="enrollForm.get('mis.yoc').invalid && enrollForm.get('mis.yoc').touched">
                              <small>
                                  Select an Year of Incorporation
                              </small>
                          </p>
                      </div>

                      <!-- Areas Of Operation -->
                      <div class="col-md-6 col-sm-6 col-12 form-group">
                          <input type="text" name="aop" formControlName="aop" />
                          <label for="input" class="control-label">Areas Of Operation</label>
                          <i class="bar"></i>
                          <p class="has-error" *ngIf="enrollForm.get('mis.aop').invalid && enrollForm.get('mis.aop').touched">
                              <small>
                                  Areas Of Operation 
                              </small>
                          </p>
                      </div>

                      <!-- Key Activities -->
                      <div class="col-md-6 col-sm-6 col-12 form-group">
                          <input type="text" name="kacti" formControlName="kacti" />
                          <label for="input" class="control-label">Key Activities</label>
                          <i class="bar"></i>
                          <p class="has-error" *ngIf="enrollForm.get('mis.kacti').invalid && enrollForm.get('mis.kacti').touched">
                              <small>
                                  Enter Key Activities
                              </small>
                          </p>
                      </div>
  
                      <div class="col-md-12 col-sm-12 col-12 checkbox">
                          <label>
                              <input type="checkbox" name="iconfirm" formControlName="iconfirm" (change)="checkConsent($event,'mis')"/><i class="helper"></i>I here by agree
                              to provide my consent to use my details for the registration.
                              <!-- <br>
                              <a href="" data-toggle="modal" data-target="#modelId">Click here</a> -->
                          </label>
                      </div>
              
                  </div>
                  <input 
                      type="button" 
                      name="previous" 
                      class="previous action-button-previous" 
                      value="Previous"
                  />
                  <button 
                      type="submit" 
                      name="submit" 
                      class="next action-button" 
                      [ngClass]="!enrollForm.get('mis').valid?'disabled':''"
                      [disabled]="!enrollForm.get('mis').valid"
                  >submit</button>
                 <!--  <input 
                      type="submit" 
                      name="submit" 
                      class="next action-button" 
                      value="submit"
                      [ngClass]="enrollForm.get('mis').invalid?'disabled':''"
                      [disabled]="enrollForm.get('mis').invalid"
                  /> -->
              </fieldset>

              
              <fieldset id="form-2" *ngIf="formstep==2">
                  <div class="flex-container flex-d-c flex-pc">
                  <i class="fa fa-circle-o-notch fa-spin fa-5x fa-fw text-primary" aria-hidden="true" *ngIf="finalStatus==1"></i>
                  <i class="fa fa-check fa-5x text-success" aria-hidden="true" *ngIf="finalStatus==2"></i>
                  <i class="fa fa-exclamation fa-5x text-warning" aria-hidden="true" *ngIf="finalStatus==3"></i>
                  <i class="fa fa-times fa-5x text-danger" aria-hidden="true" *ngIf="finalStatus==4"></i>
                  <p class="text-center" [innerHTML]="statusMessage"></p>
                  <a class="text-center text-primary" *ngIf="finalStatus==3 || finalStatus==4" (click)="resetForm()">Try again</a>
                  </div>
              </fieldset>
          </form>
      </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="modelId" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
      <div class="modal-dialog" role="document">
          <div class="modal-content">
              <div class="modal-header">
                  <h4 class="modal-title" id="modelTitleId">Terms and Conditions</h4>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                  </button>
              </div>
              <div class="modal-body">
                  <p>
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit. Unde omnis aliquid labore voluptatibus, pariatur quae odit itaque
                      nobis cum consequuntur? Asperiores dolores laborum magnam esse illo rem ipsum, temporibus corrupti! Lorem
                      ipsum, dolor sit amet consectetur adipisicing elit. Unde omnis aliquid labore
                      voluptatibus, pariatur
                      <br>
                      quae odit itaque nobis cum consequuntur? Asperiores dolores laborum magnam esse illo rem ipsum, temporibus
                      corrupti! Lorem ipsum, dolor sit amet consectetur adipisicing elit. Unde omnis aliquid labore voluptatibus,
                      pariatur quae odit itaque nobis cum consequuntur? Asperiores dolores laborum magnam esse illo rem ipsum,
                      temporibus corrupti! Lorem ipsum, dolor sit amet consectetur adipisicing elit. 
                      Unde omnis aliquid labore
                      <br>
                      voluptatibus, pariatur quae odit itaque nobis cum consequuntur? Asperiores dolores laborum magnam esse
                      illo rem ipsum, temporibus corrupti! Lorem ipsum, dolor sit amet consectetur adipisicing elit. Unde omnis
                      aliquid labore voluptatibus, pariatur quae odit itaque nobis cum consequuntur? 
                      Asperiores dolores laborum
                      <br>
                      magnam esse illo rem ipsum, temporibus corrupti! Lorem ipsum, dolor sit amet consectetur adipisicing
                      elit. Unde omnis aliquid labore voluptatibus, pariatur quae odit itaque nobis cum consequuntur? Asperiores
                      dolores laborum magnam esse illo rem ipsum, temporibus corrupti! Lorem ipsum, 
                      dolor sit amet consectetur
                      <br>
                      adipisicing elit. Unde omnis aliquid labore voluptatibus, pariatur quae odit itaque nobis cum consequuntur?
                      Asperiores dolores laborum magnam esse illo rem ipsum, temporibus corrupti! 
                      Lorem ipsum, dolor sit amet
                      consectetur adipisicing elit. Unde omnis aliquid labore voluptatibus, pariatur quae odit itaque nobis
                      cum consequuntur? Asperiores dolores laborum magnam esse illo rem ipsum, temporibus 
                      <br>
                      corrupti! Lorem ipsum,
                      dolor sit amet consectetur adipisicing elit. Unde omnis aliquid labore voluptatibus, pariatur quae odit
                      itaque nobis cum consequuntur? Asperiores dolores laborum magnam esse illo rem ipsum, 
                      temporibus corrupti!
                  </p>
              </div>
              <div class="modal-footer flex-jc-fs">
                  <button type="button" class=" button button-s" data-dismiss="modal">Close</button>
              </div>
          </div>
      </div>
  </div>