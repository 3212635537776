<div class="flex-container min-h-100 flex-w-w" *ngIf="!loading && !loginStatus">
  <app-header class="layer-1 width-100"></app-header>
  <app-side-nav></app-side-nav>
  <div class="width-100 layer-2 quaternary-bg-color" style="margin-top: 90px">
    <router-outlet></router-outlet>
  </div>
  <app-gotop></app-gotop>
  <app-footer class="flex-ais-fe layer-3 width-100"></app-footer>
  <app-chat-app></app-chat-app>
</div>
<div class="loading" *ngIf="loading">
  <svg
    class="logo"
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 74.91 40.42"
  >
    <defs>
      <style>
        .cls-1,
        .cls-2 {
          fill: none;
          stroke-miterlimit: 10;
        }
        .cls-1 {
          stroke: #88292f;
        }
        .cls-2 {
          stroke: #2b4570;
        }
      </style>
    </defs>
    <title>Artha Siksha Logos updated</title>
    <path
      class="cls-1 path"
      d="M775.59,736.28v37.47h-9v-18.7a10.72,10.72,0,0,0-10.77-10.77,10.85,10.85,0,0,0-4.15.82,10.67,10.67,0,0,0-3.48,2.32,10.76,10.76,0,0,0,0,15.19,9.62,9.62,0,0,0,3.48,2.35,11,11,0,0,0,4.15.79,10.39,10.39,0,0,0,4-.75l3.22,8a17.82,17.82,0,0,1-8,1.72A18.49,18.49,0,0,1,741.48,769a19.5,19.5,0,0,1-4-6.29,20.65,20.65,0,0,1-1.42-7.63,20.87,20.87,0,0,1,1.42-7.7,19.5,19.5,0,0,1,4-6.29,18.34,18.34,0,0,1,6-4.22,18.65,18.65,0,0,1,7.59-1.54,17.55,17.55,0,0,1,6.51,1.13,19,19,0,0,1,5,2.84v-3Z"
      transform="translate(-735.59 -734.8)"
    />
    <path
      class="cls-2 path"
      d="M797.21,750.56a28.51,28.51,0,0,1,3,1.05,23.37,23.37,0,0,1,4.3,2.28,15.6,15.6,0,0,1,3.85,3.63,8.29,8.29,0,0,1,1.65,5.16,13,13,0,0,1-.86,4.79,11,11,0,0,1-6.55,6.36,15.18,15.18,0,0,1-5.38.9,21.71,21.71,0,0,1-8.49-1.65,19.33,19.33,0,0,1-6.32-4l6.21-6.21a8.81,8.81,0,0,0,4.15,3,13.1,13.1,0,0,0,3.7.68,5.44,5.44,0,0,0,3.44-.9,2.69,2.69,0,0,0,1.12-2.17,2.07,2.07,0,0,0-.74-1.57,6.7,6.7,0,0,0-1.76-1.12,12.25,12.25,0,0,0-2.06-.71c-.7-.18-1.27-.34-1.72-.49l-3-1a20.69,20.69,0,0,1-4.34-2.17,14.89,14.89,0,0,1-3.85-3.59,8.38,8.38,0,0,1-1.65-5.23,12.44,12.44,0,0,1,1.09-5.43,10.8,10.8,0,0,1,2.88-3.77,11.68,11.68,0,0,1,4.11-2.21,16.39,16.39,0,0,1,4.79-.71,20.32,20.32,0,0,1,6.47.9,21.67,21.67,0,0,1,4.38,1.94,15.86,15.86,0,0,1,3.29,2.84l-6.29,6.29a9.57,9.57,0,0,0-3.29-2.85,8.5,8.5,0,0,0-3.81-.89,7,7,0,0,0-3.11.75,2.48,2.48,0,0,0-1.53,2.39,2,2,0,0,0,.67,1.53,5.3,5.3,0,0,0,1.65,1.05,17.17,17.17,0,0,0,2.06.71c.72.2,1.38.37,2,.52Z"
      transform="translate(-735.59 -734.8)"
    />
  </svg>
</div>
<!-- <div class="loading" *ngIf="loading">
  <svg
    class="logo"
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 56.17 31.62"
  >
    <defs>
      <style>
        .cls-1,
        .cls-2 {
          fill: none;
          stroke-linejoin: round;
        }

        .cls-1 {
          stroke: #dc493a;
        }

        .cls-2 {
          stroke: #006daa;
        }
      </style>
    </defs>
    <title>loading-logo</title>
    <path
      class="cls-1 path"
      d="M668.47,374.3H651.7l-3.61,8h-3.31l13.76-30.1h3.13l13.77,30.1h-3.36Zm-1.16-2.58-7.23-16.17-7.22,16.17Z"
      transform="translate(-644.28 -351.48)"
    />
    <path
      class="cls-2 path"
      d="M682.29,381.52a11.8,11.8,0,0,1-4.75-2.84l1.25-2.45a12.07,12.07,0,0,0,4.34,2.65,16,16,0,0,0,5.59,1q4,0,6.06-1.49a4.57,4.57,0,0,0,2-3.85,3.87,3.87,0,0,0-1.1-2.88A7.53,7.53,0,0,0,693,370a43.11,43.11,0,0,0-4.43-1.27,44.48,44.48,0,0,1-5.42-1.66,9,9,0,0,1-3.46-2.42,6.47,6.47,0,0,1-1.44-4.43,7.44,7.44,0,0,1,1.21-4.15,8.17,8.17,0,0,1,3.69-3,15.11,15.11,0,0,1,6.2-1.12,18.33,18.33,0,0,1,5,.71,14.29,14.29,0,0,1,4.28,2l-1.08,2.53a15,15,0,0,0-4-1.87,15.28,15.28,0,0,0-4.21-.62q-4,0-6,1.53a4.72,4.72,0,0,0-2,3.93,3.93,3.93,0,0,0,1.1,2.9,7.25,7.25,0,0,0,2.75,1.68c1.1.39,2.59.81,4.45,1.27a43.72,43.72,0,0,1,5.35,1.61,8.81,8.81,0,0,1,3.47,2.41,6.39,6.39,0,0,1,1.41,4.36,7.37,7.37,0,0,1-1.22,4.15,8.23,8.23,0,0,1-3.76,3,15.77,15.77,0,0,1-6.24,1.1A19.29,19.29,0,0,1,682.29,381.52Z"
      transform="translate(-644.28 -351.48)"
    />
  </svg>
</div> -->

<!-- admin header -->
<div class="min-h-100" *ngIf="!loading && loginStatus">
  <div class="admin-panel min-h-100">
    <div class="admin-header">
      <app-admin-header></app-admin-header>
    </div>
    <div class="admin-container">
      <div class="admin-sidebar">
        <app-admin-sidebar></app-admin-sidebar>
      </div>
      <div class="admin-body p-5">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
<!-- admin sidebar -->
<!-- admin body -->

<app-start-trial></app-start-trial>
<app-response></app-response>
