import { Component, OnInit, DoCheck} from '@angular/core';
import { AuthenticationService } from '../../../services/authentication/authentication.service';
import { urlPortService } from '../../../services/url-port.service';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./style/topbar-sp.component.less']
})

export class TopbarComponent implements OnInit, DoCheck {
  loginStatus: boolean;
  currentTime: any;
  days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  applicationUrl = environment.apiUrl;

  constructor(
    private authService: AuthenticationService,
    private porturl: urlPortService
  ) { }
  
  ngOnInit() {
    function checkTime(i) {
      if (i < 10) {i = "0" + i};  // add zero in front of numbers < 10
      return i;
    }
    setInterval(
      ()=>{
        const today = new Date();
        var D = today.getDate();
        var d = today.getDay();
        var M = today.getMonth();
        var Y = today.getFullYear();
        var h = today.getHours();
        var m = today.getMinutes();
        var s = today.getSeconds();
        m = checkTime(m);
        s = checkTime(s);
        this.currentTime = `${this.days[d]} ${D}/${M}/${Y} ${h}:${m}:${s}`;
      }
    ,1000);
  }

  ngDoCheck() {
    this.loginStatus = this.authService.getLoginStatus();
  }

  logout(){
    this.authService.doLogout();
    this.loginStatus = this.authService.getLoginStatus();
  }
}
