<!-- <div class="mt-account-switcher">
  <div class="mt-account-container">
      <div class="mt-account-info">
          <span class="mt-account-info-text">
              financial
          </span>
          <a class="mt-account-info-arrow">
              <i class="fa fa-chevron-right" aria-hidden="true"></i>
          </a>
      </div>
  </div>
</div> -->
<nav class="mt-nav">
  <ul class="mt-nav--list accordion" id="accordionExample">
    <!-- <li class="mt-nav--list__item">
          <a class="mt-nav--list__item__link" routerLink="/admin-panel/dashboard" routerLinkActive="active">Dashboard</a></li> -->

    <!-- for accordning dropdown in sidebar -->
    <!-- <li class="mt-nav--list__item">
          <a class="mt-nav--list__item__link collapsed" data-toggle="collapse" data-target="#collapseOne"
              aria-expanded="true" aria-controls="collapseOne">Sales
              <i class="fa fa-chevron-right accordian-arrow" aria-hidden="true"></i>
          </a>
          <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
              <ul class="mt-nav-nested">
                  <li class="mt-nav-nested--item"><a class="mt-nav-nested--item__link">Dashboard</a></li>
                  <li class="mt-nav-nested--item"><a class="mt-nav-nested--item__link">Dashboard</a></li>
                  <li class="mt-nav-nested--item"><a class="mt-nav-nested--item__link">Dashboard</a></li>
                  <li class="mt-nav-nested--item"><a class="mt-nav-nested--item__link">Dashboard</a></li>
              </ul>
          </div>
      </li> -->
    <li class="mt-nav--list__item">
      <a
        class="mt-nav--list__item__link"
        routerLink="/admin-panel/contact-details"
        routerLinkActive="active"
        >Contact us details</a
      >
    </li>
    <li class="mt-nav--list__item">
      <a
        class="mt-nav--list__item__link"
        routerLink="/admin-panel/user-queries"
        routerLinkActive="active"
        >User Queries</a
      >
    </li>
    <!-- <li class="mt-nav--list__item">
          <a class="mt-nav--list__item__link" routerLink="/admin-panel/register-details" routerLinkActive="active">Register Details</a></li> -->
    <li class="mt-nav--list__item">
      <a
        class="mt-nav--list__item__link"
        routerLink="/admin-panel/course-data"
        routerLinkActive="active"
        >Courses control</a
      >
    </li>
    <li class="mt-nav--list__item">
      <a
        class="mt-nav--list__item__link"
        routerLink="/admin-panel/faqs"
        routerLinkActive="active"
        >FAQ</a
      >
    </li>
  </ul>
</nav>
<button class="toggleButton" (click)="toggleSidebar()">
  <i
    [class]="sidebarToggleClass"
    aria-hidden="true"
  ></i>
</button>
