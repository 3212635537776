<div class="tab-sp">
    <div id='stars'></div>
    <div id='stars2'></div>
    <div id='stars3'></div>
    <div class="container">
      <div class="tab-1 tabs">
        <ul class="nav nav-pills">
          <li><a data-toggle="pill" class="active" href="#home">
            AI Enabled LMS
            </a>
          </li>
          <li><a data-toggle="pill" href="#onlinec">
            Online Course Creation
            </a>
          </li>
          <li><a data-toggle="pill" href="#testa">
            Tests and Assessments
            </a>
          </li>
          <li><a data-toggle="pill" href="#elearn">
            e-Learning
            </a>
          </li>
          <li><a data-toggle="pill" href="#virtualc">
            Virtual Classroom
            </a>
          </li>
        </ul>
        <div class="tab-content">
          <div id="home" class="tab-pane active show">
            <div class="flex-pc heading">
                <h2>High impact trainings from industry experts.</h2>
            </div>
            <img src="/assets/images/banner/ai.png" alt="tab-image" class="img-fluid"/>
          </div>
          <div id="onlinec" class="tab-pane">
            <div class="flex-pc heading">
                <h2>A unified gateway to an awarding career.</h2>
            </div>
            <img src="/assets/images/banner/online.png" alt="tab-image" class="img-fluid"/>
          </div>
          <div id="testa" class="tab-pane">
            <div class="flex-pc heading">
                <h2> A methodical approach for holistic development.</h2>
            </div>
            <img src="/assets/images/banner/test.png" alt="tab-image" class="img-fluid"/>
          </div>
          <div id="elearn" class="tab-pane">
            <div class="flex-pc heading">
                <h2> A methodical approach for holistic development.</h2>
            </div>
            <img src="/assets/images/banner/elearning.png" alt="tab-image" class="img-fluid"/>
          </div>
          <div id="virtualc" class="tab-pane">
            <div class="flex-pc heading">
                <h2> A methodical approach for holistic development.</h2>
            </div>
            <img src="/assets/images/banner/virtual.png" alt="tab-image" class="img-fluid"/>
          </div>
        </div>
      </div>
    </div>
  </div>