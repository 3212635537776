
    <div class="d-flex justify-content-between w-100 mb-3">
      <h3>Course List</h3>
      <button class="btn btn-primary" style="width: 40px;height: 40px;" (click)="openModal('CREATE')">+</button>
    </div>


    <table
  id="courseTable"
  *ngIf="coursesList"
  class="table table-striped table-bordered"
  style="width: 100%;"
>
  <thead>
    <tr>
      <th style="width: 80px;">S No</th>
      <th style="width: 80px;">Course Order</th>
      <th>Course Name</th>
      <th style="width: 100px; margin: 0 10px;">Action</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let course of coursesList; let i = index">
      <td style="width: 80px;">{{ i + 1 }}</td>
      <td>{{ course[1] }}</td>
      <td>{{ course[2] }}</td>
      <td style="width: 100px; margin: 0 10px;" >
        <div class="d-flex align-items-center justify-content-between actions">

        <i
          *ngIf="course[3] == 0"
          class="fa fa-check-circle-o active"
          (click)="toggleStatus(course.id)"
          aria-hidden="true"
          title="Status"
        ></i>
        <i
          *ngIf="course[3] == 1"
          class="fa fa-times-circle-o inactive"
          (click)="toggleStatus(course[0])"
          aria-hidden="true"
          title="Status"
        ></i>
        <i
          class="fa fa-pencil edit"
          (click)="toEditCourse(course[0])"
          aria-hidden="true"
          title="Edit"
        ></i>
        <i
          class="fa fa-trash delete"
          (click)="deleteCourseById(course[0])"
          aria-hidden="true"
          title="Delete"
        ></i>
      </div>

      </td>
    </tr>
  </tbody>
</table>

<!-- 
    <ul class="list-group w-100 course-list" *ngIf="coursesList.length != 0 else nocourse">
      <li class="list-group-item d-flex justify-content-between align-content-center pr-5" *ngFor="let course of coursesList">
        <span>{{course.courseName}}</span>
        <button class="btn btn-primary" (click)="toEditCourse(course.id)">Edit</button>
        <button class="btn btn-primary" (click)="deleteCourseById(course.id)">Delete</button>
        <button class="btn btn-primary" (click)="toggleStatus(course.id)">{{course.status == 'Active'?'Disabled':'Enable'}}</button>
      </li>
    </ul>
    <ng-template #nocourse>
      <span>No courses are found</span>
    </ng-template> -->

 
  <div class="modal fade" id="courseModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">Course Update</h5>
          <button type="button" class="close" (click)="formClear()" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form [formGroup]="courseForm" (ngSubmit)="onFormSubmit()">
            <div class="row">
                <div class="col-md-6 col-sm-12 row">
                  <div class="form-group col-12">
                    <label for="course-name">Course Name</label>
                    <input formControlName="courseName" type="text" class="form-control" id="couse-name" />
                  </div>
                  <div class="form-group col-6">
                    <label for="course-duration">Course Duration</label>
                    <input formControlName="courseDuration" type="text" class="form-control" id="couse-duration" />
                  </div>
                  <div class="form-group col-6">
                    <label for="course-order">Course Order</label>
                    <input formControlName="courseOrder" type="text" class="form-control" id="couse-order" />
                  </div>
                  <div class="form-group col-12">
                    <label for="course-batch">Course Batch Start From</label>
                    <input formControlName="courseBatchDate" type="date" class="form-control" id="couse-batch" />
                  </div>
                  <div class="form-group col-12">
                    <label for="course-desc">Course Description</label>
                    <textarea  formControlName="courseDescription" type="text" class="form-control" id="couse-desc" ></textarea>
                  </div>
                </div>
                <div class="col-md-6 col-sm-12">
                  <div class="form-group">
                    <label for="course-img">Course Image
                      <br>
                      <div class="d-flex">
                        <div class="btn btn-primary" style="white-space: nowrap;"> <i class="fa fa-upload mr-2" aria-hidden="true"></i>Upload</div>
                        <span *ngIf="filedata">{{filedata['fileName']}}</span>
                      </div>
                    </label>
                    <button [ngClass]="{'d-none':!isCropped,'btn btn-primary ml-2':true}" (click)="toCrop()">Crop</button>
                    <div class="d-flex">
                      <input type="file" class="form-control d-none" (change)="getFileData($event)" accept="image/x-png,image/jpeg" id="course-img" />
                     
                    </div>
                  </div>
                  <div class="d-flex justify-content-center align-items-center border" style="width: 60%; margin: auto;">
                    <img [src]="imgData" alt="" class="w-100 h-100" *ngIf="imgData else nodata">
                    <ng-template #nodata>
                      Preview
                    </ng-template>
                  </div>
                </div>
                <div class="col-12">
                  <div class="d-flex justify-content-between align-items-center">
                    <span>Course Overview</span>
                    <button class="btn btn-primary mr-5" type="button" (click)="addItemToCourseOverviewList()">+</button>
                  </div>
                </div>
                <div class="col-md-4 py-2" formArrayName="courseOverview" *ngFor="let item of courseOverviewList;let i = index">
                  <div class="d-flex">
                    <input type="text" class="form-control" [formControlName]="i"  [id]="'courseOverview'+i" />
                    <button class="btn btn-danger ml-3" type="button" (click)="removeItemInCourseOverviewList(i)" ><i class="fa fa-trash-o" aria-hidden="true"></i></button>
                  </div>
                </div>
                <div class="col-md-4 py-2">
                  
                </div>
                <div class="col-12 text-center my-5">
                  <button [disabled]="courseForm.invalid || !filedata" class="btn btn-primary" type="submit"> Submit</button>
                </div>
              </div>
            </form> 
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="cropModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">Crop image</h5>
        </div>
        <div class="modal-body d-flex justify-content-center bg-secondary">
          <div>
            <img src="" id="cropImage" alt=""> 
            <div class="btn-group my-3" role="group">
              <button class="btn btn-secondary" (click)="rotateRight()"><i class="fa fa-repeat" aria-hidden="true"></i></button>
              <button class="btn btn-secondary" (click)="rotateLeft()"><i class="fa fa-undo" aria-hidden="true"></i></button>
              <button class="btn btn-secondary" (click)="zoomIn()"><i class="fa fa-search-plus" aria-hidden="true"></i></button>
              <button class="btn btn-secondary" (click)="zoomOut()"><i class="fa fa-search-minus" aria-hidden="true"></i></button>
            </div>
            <button class="btn btn-primary m-2" style="float: right;" (click)="saveCrop($event)" data-dismiss="modal">Crop</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <form>
  <div class="row">
      <div class="col-md-6 col-sm-12 row">
        <div class="form-group col-12">
          <label for="course-name">Couse Name</label>
          <input type="text" class="form-control" id="couse-name" />
        </div>
        <div class="form-group col-12">
          <label for="course-duration">Couse Duration</label>
          <input type="text" class="form-control" id="couse-duration" />
        </div>
        <div class="form-group col-12">
          <label for="course-batch">Couse Batch</label>
          <input type="text" class="form-control" id="couse-batch" />
        </div>
        <div class="form-group col-12">
          <label for="course-desc">Couse Description</label>
          <textarea type="text" class="form-control" id="couse-desc" ></textarea>
        </div>
      </div>
      <div class="col-md-6 col-sm-12">
        <div class="form-group">
          <label for="course-img">Course Image</label>
          <input type="file" class="form-control" id="course-img" />
        </div>
      </div>
    </div>
  </form> -->
