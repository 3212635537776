<div class="h-100 d-flex justify-content-between align-items-center mr-5">
  <div class="logo"></div>
  <div class="profile">
    <a class="logout" href="#" (click)="logout()">Log out
      <i class="fa fa-sign-out" aria-hidden="true"></i>
    </a>
    <!-- <i
      class="fa fa-user profile-icon"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      aria-hidden="true"
    ></i>
    <div class="dropdown-menu">
      <a class="dropdown-item" href="#" (click)="logout()">Log out</a>
    </div> -->
  </div>
</div>
