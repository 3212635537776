import { Component, OnInit, Input, AfterViewInit } from "@angular/core";
import { Router } from "@angular/router";

declare var $: any;
@Component({
  selector: "app-courses-carousel",
  templateUrl: "./courses-carousel.component.html",
  styleUrls: ["./courses-carousel.component.less"],
})
export class CoursesCarouselComponent implements OnInit, AfterViewInit {
  @Input() cdata: any;
  constructor(private router: Router) {}
  ngAfterViewInit(): void {
  }

  ngOnInit() {}
  toCourse(id) {
    this.router.navigate(["/course-detail"], { queryParams: { id: id } });
  }
  MouseOveronCourse(index) {
    var courseCard = $('.profile')[index];
    var courseDetails = courseCard.children[0].children[1];
    var courseDetailsRects = courseDetails.getBoundingClientRect();
    $(courseCard.children[0]).css({
      position: "relative",
      transform: "translateY(-" + courseDetailsRects.height + "px)",
      left: "0",
      right: "0",
    });
  }
  MouseLeaveonCourse(index) {
    var courseCard = $('.profile')[index];
    $(courseCard.children[0]).css({
      position: "relative",
      transform: "translateY(0)",
    });
  }
  toOpenCourses() {
    var dimmer = document.querySelector(".dimmer");
    var sideNav = document.getElementById("side-nav-conatainer");
    sideNav.classList.toggle("open");
    dimmer.classList.toggle("open");
  }
}
