<div class="container" id='team'>
  <h1 class="main-heading text-center  wow fadeInUp">Leadership team</h1>
  <hr class="devider wow fadeInUp">
  <div class="row justify-content-center">
    <p class="col-md-8 col-lg-8 col-sm-12 text-center wow fadeInUp">
      Our leadership team consist of experts in various fields of finance and accounting domain with overall experience of more than 50 years in total. With experience ranging from Big4s, MNCs and world leading KPOs, they bring the relevant insight and expertise which enable unleashing the potential in each and every learner.
    </p>
  </div>
  <div class="row py-5 team-container" *ngFor="let team of teamList;let i = index">
    <div class="col-md-3">
      <img [src]="team.imgSrc" alt="" class="img-fluid">
    </div>
    <div class="col-md-9">
      <h3 class="m-0">{{team.name}}</h3>
      <p> {{team.designation}} </p>
      <p *ngFor="let item of team.bio"> {{item}} </p>
      <a target="_blank" class="social-link mt-3" [href]="team.linkedIn">
        <i class="fa fa-linkedin"></i>
      </a>
    </div>
  </div>
</div>