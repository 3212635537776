import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Data } from "@angular/router";
import { NgbDatepickerConfig } from "@ng-bootstrap/ng-bootstrap";
import { Subject } from "rxjs";
import { DmService } from "src/app/dmarketing/dm.service";
import { ApiService } from "src/app/services/http/api.service";
import { RegistrationModel } from "src/app/services/model/registration.model";

@Component({
  selector: "app-student-registation",
  templateUrl: "./student-registation.component.html",
  styleUrls: ["./student-registation.component.less"],
})
export class StudentRegistationComponent implements OnInit {
  enrollForm: FormGroup;

  loader: boolean = false;
  status: boolean = true;
  responseStatus: any = {};
  formValue: any = {};
  formfill: boolean = true;
  orgInData: any;
  convertdata: RegistrationModel;
  states: Array<any> = [];
  countries: any = [];

  // DESIRED SKILL DATA--------

  // desiredSkills: any;
  // desiredSkillsCtrl: any;
  // selectedDesiredSkills = "No Course Selected";
  // selectedDesiredList: any = [];
  // otherSelected: boolean = false;
  // otherSkillData: any;

  // DESIRED SKILL DATA--------

  constructor(
    // private regser: RegisterService,
    config: NgbDatepickerConfig,
    private dmdata: DmService,
    private router: ActivatedRoute,
    private api: ApiService,
    private fb: FormBuilder,
    private http:HttpClient
  ) // private AccordianService: AccordianService,
  {
    // this.formValue = this.AccordianService.getRegis();
    // console.log(`UserData : ${this.formValue.userData}`);
    config.minDate = { year: 1900, month: 1, day: 1 };
    config.maxDate = { year: 2010, month: 12, day: 31 };
    // days that don't belong to current month are not visible

    this.dmdata.setDmTags("studentenroll");
    config.outsideDays = "hidden";

    // this.router.data.subscribe((data: Data) => {
    //   if(data.coursedata){
    //     this.desiredSkills = [...data.coursedata];

    //   }else{
    //     this.desiredSkills = [];
    //   }
    //   // this.desiredSkills = [...data.coursedata, {
    //   //   "id": -1,
    //   //   "courseName": "Others"
    //   // }];
    //   this.desiredSkillsCtrl = this.desiredSkills.map(
    //     () => new FormControl(false)
    //   );
    //   //this.states = data.statelist;
    //   this.countries = data.countryList;
    // });
    this.countries = [{
      id:1,
      countryName:"India"
    }];
  }

  countryLists() {
    this.countries = [{
      id:1,
      contryName:"India"
    }];
    // this.api.getCountyList().subscribe(data => {
    //   this.countries = data.response;
    //   console.log(this.countries);
    // });
  }

  getStatesByCountry(event) {
    this.http.get<any>('assets/json/states.json').subscribe(data => {
      this.states = data.states;
    })
    // this.api.getCountyStateList(event.target.value).subscribe(
    //   data => {
    //     this.states = data.response;
    //     console.log(this.states);
    //   },
    //   error => {
    //     this.states = [];
    //     console.log(error);
    //   });
  }

  ngOnInit() {
    this.enrollForm = this.fb.group({
      package: new FormControl(null, Validators.required),
      fname: new FormControl(null, Validators.required),
      lname: new FormControl(null, Validators.required),
      dob: new FormControl(null, Validators.required),
      gender: new FormControl(null, Validators.required),
      mobile: new FormControl(null, Validators.required),
      email: new FormControl(null, [Validators.required, Validators.email]),
      pass: new FormControl(null, [
        Validators.required,
        Validators.pattern(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
        ),
      ]),
      cpass: new FormControl(null, [
        Validators.required,
        Validators.pattern(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
        ),
        Validators.minLength(8),
      ]),
      country: new FormControl(null, Validators.required),
      state: new FormControl(null, Validators.required),
      // desiredSkill: new FormArray([...this.desiredSkillsCtrl]),
      iconfirm: new FormControl(null, Validators.required),
    });

    // if (this.formValue.userData) {
    //   this.enrollForm.patchValue({
    //     'mobile': this.formValue.userData.phone,
    //     'email': this.formValue.userData.email,
    //     'fname': this.formValue.userData.name.substr(0,this.formValue.userData.name.indexOf(' ')),
    //     'lname': this.formValue.userData.name.substr(this.formValue.userData.name.indexOf(' ')+1)
    //   });
    // }
    // this.enrollForm.controls["desiredSkill"].valueChanges.subscribe((data) => {
    //   let result = [];
    //   Array.from(data).map((data, i) => {
    //     if (data == true) {
    //       this.otherSelected = this.desiredSkills[i].id == -1 ? true : false;
    //       result.push(this.desiredSkills[i]);
    //     }
    //   });

    //   // To show course list on the Selected Skill
    //   this.selectedDesiredSkills =
    //     result.length != 0
    //       ? result.map((data) => data.courseName).join(" - ")
    //       : "No Course Selected";
    //   if (!this.otherSelected) {
    //     this.otherSkillData = null;
    //   }
    //   if (result.find((data) => data.id == -1)) result.splice(-1, 1);
    //   this.selectedDesiredList = result.map((data: any) => {
    //     return { id: `${data.id}` };
    //   });
    // });
  }

  makeOtherSkillData(event: any) {
    // this.otherSkillData = event.target.value.split(",").map((data) => {
    //   return { courseName: `${data.trim()}` };
    // });
  }

  onFromSubmit() {
    this.loader = true;
    this.formfill = false;
    const formdata = this.enrollForm.value;
    let age = new Date().getFullYear() - parseInt(formdata["dob"]["year"]);

    this.convertdata = new RegistrationModel(
      formdata["package"],
      formdata["fname"],
      formdata["lname"],
      `${formdata["dob"]["day"]}-${formdata["dob"]["month"]}-${formdata["dob"]["year"]} `,
      `${age}`,
      formdata["gender"],
      formdata["mobile"],
      formdata["email"],
      formdata["pass"],
      formdata["country"],
      formdata["state"],
      // this.selectedDesiredList,
      // this.otherSkillData
    );
    window.scrollTo(0, 400);
    console.log(this.convertdata);
    // this.regser.doStudentRegister(this.convertdata).subscribe(
    //   (data: Response) => {
    //     this.loader = false;
    //     this.status = true;
    //     this.responseStatus = data;

    //   },
    //   error => {
    //     this.loader = false;
    //     this.status = false;
    //     window.scrollBy(0, 400);
    //   }
    // )
  }

  fillAgain() {
    window.location.reload();
  }

  checkMobile(event) {
    let element = event.target;
    if (element.value.length > element.maxLength) {
      this.enrollForm.controls["mobile"].setValue(
        element.value.slice(0, element.maxLength)
      );
    }
  }

  checkConsent(event) {
    console.log(event.target.name);
    if (event.target.checked) {
      this.enrollForm.controls[event.target.name].setValue("iaccept");
    } else {
      this.enrollForm.controls[event.target.name].setValue(null);
    }
  }
}
