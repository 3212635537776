import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-upcoming-events',
  templateUrl: './upcoming-events.component.html',
  styleUrls: ['./upcoming-events.component.less']
})
export class UpcomingEventsComponent implements OnInit {
  ngOnInit() {
  }
}
