import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BlogsService, BlogStruct } from 'src/app/services/blogs.service';

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.less']
})
export class BlogDetailsComponent implements OnInit {

  
  blogData: any;
  blogList: any = [];
  blogid: number;
  searchBox: any;
  constructor(
    private blog: BlogsService,
    private route: ActivatedRoute,
    private router: Router,
    private http:HttpClient
  ) { }

  ngOnInit(): void {
    // this.util.title.next("Consult-4AT | Blogs")
    this.http.get<BlogStruct[]>('assets/json/blogs.json').subscribe(data => {
      this.blogList = data;
      this.blogList.sort(this.blog.blogCompare)
      this.route.queryParams.subscribe(param => {
        this.blogid = param['blog']
        this.blogList.forEach(blog => {
          if (blog.id == this.blogid) {
            this.blogData = blog;
          }
        });
      })
    })
    // this.blogList = this.blog.blogs;
  }
  toIndividualBlog(id) {
    this.router.navigate(['/blog'], { queryParams: { blog: id } })
  }

}
