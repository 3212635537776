<div class="banner">
  <h2>Institution Enrollment</h2>
</div>
<div class="container">
  <form class="form-sp" *ngIf="showForm" [formGroup]="enrollForm" (ngSubmit)="formSubmit(enrollForm.value)">
    <div class="row">
      <div class="col-md-6 col-sm-6 col-12 form-group">
        <input type="text" name="firstName" formControlName="firstName" />
        <label for="input" class="control-label">First Name</label>
        <i class="bar"></i>
        <p class="has-error" *ngIf="enrollForm.get('firstName').invalid && enrollForm.get('firstName').touched">
          <small>
            Enter first Name
          </small>
        </p>
      </div>
      <div class="col-md-6 col-sm-6 col-12 form-group">
        <input type="text" name="lastName" formControlName="lastName" />
        <label for="input" class="control-label">Last Name</label>
        <i class="bar"></i>
        <p class="has-error" *ngIf="enrollForm.get('lastName').invalid && enrollForm.get('lastName').touched">
          <small>
            Enter last Name
          </small>
        </p>
      </div>
      <div class="col-md-6 col-sm-6 col-12 form-group">
        <input type="email" name="email" formControlName="email" />
        <label for="email" class="control-label">Email</label>
        <i class="bar"></i>
        <p class="has-error" *ngIf="enrollForm.get('email').invalid && enrollForm.get('email').touched">
          <small>
            Enter valid Email Address
          </small>
        </p>
      </div>

      <div class="col-md-6 col-sm-6 col-12 form-group">
        <input type="number" name="phone" formControlName="phone" (input)="checkMobile($event)" maxlength="10" />
        <label for="input" class="control-label">Mobile No.</label>
        <i class="bar"></i>
        <p class="has-error" *ngIf="enrollForm.get('phone').invalid && enrollForm.get('phone').touched">
          <small>
            Enter valid Mobile Number
          </small>
        </p>
      </div>
      <div class="col-md-6 col-sm-6 col-12 form-group">
        <input type="text" name="orgname" formControlName="orgname" />
        <label for="input" class="control-label">Organization Name</label>
        <i class="bar"></i>
        <p class="has-error" *ngIf="enrollForm.get('orgname').invalid && enrollForm.get('orgname').touched">
          <small>
            Enter Organization Name
          </small>
        </p>
      </div>
      <div class="col-md-6 col-sm-6 col-12 form-group">
        <input type="number" name="usersNo" formControlName="usersNo" (input)="checkMobile($event)" maxlength="4" />
        <label for="input" class="control-label">No. of Users</label>
        <i class="bar"></i>
        <p class="has-error" *ngIf="enrollForm.get('usersNo').invalid && enrollForm.get('usersNo').touched">
          <small>
            Enter No of Users
          </small>
        </p>
      </div>

      <ng-template [ngIf]="acadmicshow">
        <div class="label text-left">
        </div>
        <div class="form-radio text-left flex-container">
          <input type="radio" class="form-check-input" formControlName="acatype" name="acatype" id="Engineering"
            value="Engineering">
          <label for="Engineering" class="form-check-label">
            Engineering Type
          </label>

          <input type="radio" class="form-check-input" formControlName="acatype" name="acatype" id="Degree"
            value="Degree">
          <label for="Degree" class="form-check-label">
            Degree Type
          </label>
        </div>
      </ng-template>
      <div class="col-md-6 col-sm-6 col-12 form-group">
        <select (ngModelChange)="getCountry($event)" formControlName="countryname">
          <option [ngValue]="null">Select Country</option>
          <option *ngFor="let condata of countries" value="{{condata.id}}">{{condata.countryName}}</option>
        </select>
        <label for="select" class="control-label">Select Country</label>
        <i class="bar"></i>
        <!-- <p *ngIf="subscriptionFrom.get('countryname').invalid && subscriptionFrom.get('countryname').touched"
          class="warn-text">Enter a valid Country Name eg. india</p> -->
      </div>
      <div class="col-md-6 col-sm-6 col-12 form-group">
        <select formControlName="statename">
          <option [ngValue]="null">Select</option>
          <option *ngFor="let cssdata of countriestates" value="{{cssdata.id}}">{{cssdata.state}}</option>
        </select>
        <label for="select" class="control-label">Select State</label>
        <i class="bar"></i>
        <!-- <p *ngIf="subscriptionFrom.get('statename').invalid && subscriptionFrom.get('statename').touched"
          class="warn-text">Enter a valid State Name</p> -->
      </div>
      <div class="col-md-6 col-sm-6 col-12 form-group">
        <input type="number" name="pino" class="frm-cntrl" formControlName="pino" (input)="checkMobile($event)"
          maxlength="6" />
        <i class="bar"></i>
        <label for="input" class="control-label">Zip Code</label>
        <p class="has-error" *ngIf="enrollForm.get('pino').invalid && enrollForm.get('pino').touched">
          <small>
            Enter Valid Zip Code
          </small>
        </p>
      </div>
      <div class="col-md-12 col-sm-12 col-12 checkbox">
        <label>
          <input type="checkbox" name="iconfirm" formControlName="iconfirm" (change)="checkConsent($event)" /><i
            class="helper"></i>I here by agree
          to provide my consent to use my details for the registration.
          <!-- <br>
            <a href="" data-toggle="modal" data-target="#modelId">Click here</a> -->
        </label>
      </div>
      <div class="col-md-12 col-sm-12 col-12 text-center">
        <button type="submit" class="button button-s" [ngClass]="!enrollForm.valid?'disabled':''"
          [disabled]="!enrollForm.valid">Submit</button>
      </div>
    </div>


  </form>

  <fieldset *ngIf="formstep == 2">
    <div class="status">
      <div class="loading flex-container flex-d-c flex-pc" *ngIf="loading">
        <i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i>
        <span>
          Please Wait...
        </span>
      </div>

      <div class="done">
        <img *ngIf="submitPostData.status=='OK'" src="/assets/images/icons/tickicon.png" alt="Info-icons"
          class="img-fluid">
        <h4 *ngIf="submitPostData.status=='OK'">Success</h4>
        <h6 *ngIf="submitPostData.status=='OK'">We have sent an email to {{submitPostData.response.emailId}}.
          Please validate your email to get access.</h6>


        <img *ngIf="submitPostData.message=='Already Exist'" src="/assets/images/icons/crossicon.png" alt="Info-icons"
          class="img-fluid">
        <h4 *ngIf="submitPostData.message=='Already Exist'">Already Exist</h4>
        <h6 *ngIf="submitPostData.message=='Already Exist'">{{submitPostData.response}}</h6>
        <button *ngIf="submitPostData.message=='Already Exist'" class="button button-s"
          (click)="fillAgain()">Refresh</button>
      </div>
      <div *ngIf="error">

        <img src="/assets/images/icons/crossicon.png" alt="Info-icons" class="img-fluid">
        <h4>Oops! something went wrong</h4>
        <h6>Please refresh page and try again.</h6>
      </div>



    </div>
  </fieldset>


</div>
