import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ApiService } from "src/app/services/http/api.service";
declare var $: any;
@Component({
  selector: "app-admin-contact-details",
  templateUrl: "./admin-contact-details.component.html",
  styleUrls: ["./admin-contact-details.component.less"],
})
export class AdminContactDetailsComponent implements OnInit {
  constructor(private http: HttpClient, private api: ApiService) {}

  contactDetails: any = [];

  ngOnInit() {
    this.api.getContactDetails().subscribe(
      (res) => {
        this.contactDetails = res.body;
        if (this.contactDetails) {
          $(document).ready(function () {
            $("#example").DataTable();
          });
        }
      },
      (err) => {
      }
    );
  }
}
