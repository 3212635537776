import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ApiService } from "src/app/services/http/api.service";
import { UtilService } from "src/app/services/util.service";
import { AccordianService } from "./../../services/accordian.service";
@Component({
  selector: "app-course-details",
  templateUrl: "./course-details.component.html",
  styleUrls: ["./course-details.component.less"],
})
export class CourseDetailsComponent implements OnInit {
  id: number;
  allCourses: any;
  course: any;
  isNext = true;
  isBack = true;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private accordianService: AccordianService,
    private api: ApiService,
    private util:UtilService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.id = +params.id;
      this.loadCourse();
      // this.allCourses = this.accordianService.getCoursesData();
      // this.course = this.allCourses[this.id];
      this.allCourses && this.allCourses[this.id + 1]
        ? (this.isNext = true)
        : (this.isNext = false);
      this.allCourses && this.allCourses[this.id - 1]
        ? (this.isBack = true)
        : (this.isBack = false);
      // this.allCourses.forEach(course => {
      //   if (this.id == course.id) {
      //     this.course = course;
      //   }
      // });
    });
    document.onkeydown = (e) => {
      if (e.key == "ArrowLeft") {
        this.goBack();
      } else if (e.key == "ArrowRight") {
        this.next();
      }
    };
  }
  loadCourse() {
    if(this.util.CourseList){
      this.allCourses = this.util.CourseList;
      this.allCourses? this.course = this.allCourses[this.id]:'';
    }else{
      this.util.courses.subscribe(data=>{
        this.allCourses = data;
        this.course = this.allCourses[this.id];
      })
    }
    // this.api.getCourses().subscribe(
    //   (res) => {
    //     if (res.status == 200) {
    //       this.allCourses = res.body.filter(course=>course.status=="Active");
    //       this.course = this.allCourses[this.id];
    //     }
    //   },
    //   (err) => {
    //     console.log(err);
    //   }
    // );
  }
  goBack() {
    //  this.router.navigate(['']);
    var next = this.id - 1;
    if (this.allCourses[next]) {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { id: next },
        queryParamsHandling: "merge",
      });
    }
  }
  next() {
    var next = this.id + 1;
    if (this.allCourses[next]) {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { id: next },
        queryParamsHandling: "merge",
      });
    }
  }
}
