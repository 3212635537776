import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { ActivatedRoute, Data } from '@angular/router';
import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { DmService } from 'src/app/dmarketing/dm.service';
import { ApiService } from 'src/app/services/http/api.service';
import { TrainerRegModel } from 'src/app/services/model/trainer.reg.model';

@Component({
  selector: 'app-professional-registration',
  templateUrl: './professional-registration.component.html',
  styleUrls: ['./professional-registration.component.less']
})
export class ProfessionalRegistrationComponent implements OnInit {

  
  convertdata: TrainerRegModel;
  desiredSkills: any;
  formfill: boolean = true;
  loading: boolean = false;
  status: boolean = true;
  responseStatus: any = {};
  enrollForm: FormGroup;
  states: Array<any> = [];
  desiredSkillsCtrl: any;
  countries: any = []; 
  formValue: any = {};
  countriestates: any = [];
  districtlist: any = [];
  MandalList: any = [];
  qualificationlists: any = [];
  model:any;
  constructor(
    // private AccordianService: AccordianService,
    private dmdata: DmService,
    // private regser: RegisterService,
    config: NgbDatepickerConfig,
    private router: ActivatedRoute,
    private api: ApiService,
    private http:HttpClient
  ) {
    // this.formValue = this.AccordianService.getRegis();
    // console.log(this.formValue.userData);


    this.dmdata.setDmTags('trainerenroll');
    config.minDate = { year: 1900, month: 1, day: 1 };
    config.maxDate = { year: 2010, month: 12, day: 31 };
    // days that don't belong to current month are not visible
    config.outsideDays = 'hidden';

    // this.router.data.subscribe(
    //   (data: Data) => {
    //     this.desiredSkills = data.coursedata;
    //     // this.states = data.statelist;
    //     // this.districtList(this.states.id)
    //     this.countries = data.countryList;

    //     this.desiredSkillsCtrl = data.coursedata.map(
    //       () => new FormControl(false)
    //     );
    //   }
    // );
    this.getQual();
  }

  ngOnInit() {
    this.enrollForm = new FormGroup({
      'fname': new FormControl(null, Validators.required),
      'lname': new FormControl(null, Validators.required),
      'dob': new FormControl(null, Validators.required),
      'mobile': new FormControl(null, [
        Validators.required,
        Validators.pattern("^[0-9]{10}$")
      ]),
      'email': new FormControl(null, [
        Validators.required,
        Validators.email
      ]),
      'locality': new FormControl(null, Validators.required),
      'city': new FormControl(null, Validators.required),
      'state': new FormControl(null, Validators.required),
      'district': new FormControl(null, Validators.required),
      'address': new FormControl(null, Validators.required),
      'qualification': new FormControl(null, Validators.required),
      'country': new FormControl(null, Validators.required),
      // 'desiredSkill': new FormArray([...this.desiredSkillsCtrl]),
      'iconfirm': new FormControl(null, Validators.required),
    });

    this.countries = [{
      id:1,
      countryName:"India"
    }];
    // this.enrollForm.patchValue({
    //   'mobile': this.formValue.userData.phone,
    //   'email': this.formValue.userData.email,
    //   'fname': this.formValue.userData.name.substr(0,this.formValue.userData.name.indexOf(' ')),
    //   'lname': this.formValue.userData.name.substr(this.formValue.userData.name.indexOf(' ')+1)
    // });

    // this.api.getCountyList().subscribe(data => this.countries = data.response);

    // this.enrollForm.valueChanges.subscribe(value => {
    //   if (value.country)
    //     this.api.getCountyStateList(value.country).subscribe(
    //       data => this.countriestates = data.response
    //     );

    //   if (value.state)
    //     this.api.getDistrictList(value.state).subscribe(
    //       data => this.districtlists = data.response
    //     );
    // })

  }
  getQual() {
    // this.api.getQualificationList().subscribe(data => {
    //   this.qualificationlists = [...data.response];
    //   console.log(this.qualificationlists);
    // });
  }
  onFromSubmit() {
    const formdata = this.enrollForm.value;
    let age = new Date().getFullYear() - parseInt(formdata['dob']['year']);
    let month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    this.convertdata = new TrainerRegModel(
      formdata['fname'],
      formdata['lname'],
      `${formdata['dob']['day']}-${month[formdata['dob']['month'] - 1]}-${formdata['dob']['year']} `,
      formdata['mobile'],
      formdata['email'],
      formdata['locality'],
      formdata['city'],
      formdata['state'],
      formdata['district'],
      // dsData(formdata['desiredSkill'], this.desiredSkills),
      formdata['address'],
      formdata['country'],
      formdata['qualification'],
      null
    );

    this.formfill = false;
    this.loading = true;
    // {
    //     "status": "Success",
    //     "response": {
    //         "firstName": "Admin",
    //         "userName": "admin@mail.com"
    //     },
    //     "message": "You are successfully registered."
    // }

    function dsData(data, cmpdata) {
      const result = [];
      var i = 0;
      Array.from(data).map(
        data => {
          if (data == true) { result.push({ 'id': cmpdata[i].id.toString() }) }
          i++;
        }
      );
      return result;
    }

    window.scrollBy(0, 400);
    // {"status":"Success","response":{"firstName":"testmonxz","userName":"testmonxz@mail.com"},"message":"You are successfully registered."}
    // {"status":"Failed","message":"This email id is already registered.Please give another email"}
    // this.regser.doTrainerRegister(this.convertdata).subscribe(
    //   (data: Response) => {
    //     this.loading = false;
    //     this.status = true;
    //     this.responseStatus = data;
    //   },
    //   error => {
    //     this.loading = false;
    //     this.status = false;
    //     window.scrollBy(0, 400);
    //   }
    // )
  }

  fillAgain() {
    this.enrollForm.reset();
    this.formfill = true;
  }

  getStatesByCountry(event) {
    this.http.get<any>('assets/json/states.json').subscribe(data => {
      this.states = data.states;
    })
    // this.api.getCountyStateList(event.target.value).subscribe(data => {
    //   this.states = data.response;
    //   console.log(this.states);
    // });
  }
  
  getDistrictsByState(event) {
    this.states.forEach(state=>{
      if(state.id == event.target.value){
        this.districtlist = state.districts;
      }
    })
    // this.api.getDistrictList(event.target.value).subscribe(data => {
    //   this.districtlist = data.response;
    //   console.log(this.states);
    // });
  }

  checkMobile(event) {
    let element = event.target;
    if ( element.value.length > element.maxLength) {
      this.enrollForm.controls[element.name].setValue(element.value.slice(0, element.maxLength));
    }
  }

  checkConsent(event) {
    if(event.target.checked) {
      this.enrollForm.controls[event.target.name].setValue('iaccept');
    } else {
      this.enrollForm.controls[event.target.name].setValue(null);
    }
  }

}
