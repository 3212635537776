export class UserQuery{
    constructor(
    public userType:'STUDENT' | 'PROFESSIONAL' | 'ACADEMIC' | 'ORGANIZATION',
    public name:string,
    public mobile:number,
    public email:string,
    public query:string,
    public workExperience:number=null,
    public nameOfTheInstitution:string = null,
    public noOfStudents:number=null,
    public location:string=null,
    public nameOfTheOrganization:string=null,
    public noOfEmployees:number=null
){}
}