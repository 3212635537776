<section class="team card1">
  <app-courses-carousel *ngIf="coursesData" [cdata]="coursesData"></app-courses-carousel>
    <!--<div class="container">
    <div class="row">
     <div class="col-auto flex-container flex-ai-c">
        <h2>Course Offerings</h2>
      </div>
      <div class="col-auto flex-container flex-ai-c">
        <a routerLink="/student-enroll" class="button button-s">Enroll now</a>
      </div>
      <div class="col-lg-12">
        <app-carousel2></app-carousel2>
      </div> 
      <div class="col-lg-12">
        <div class="course-carousal">
        </div>
      </div>
    </div>
  </div>-->
</section>