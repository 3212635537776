<div class="side-nav-conatainer" id="side-nav-conatainer">
  <div class="nav-course-container">
    <ul *ngIf="coursesData">
      <li *ngFor="let course of coursesData;let i = index" (click)="toCourse(i)">
        <div class="name">
          {{course.courseName}}
        </div>
        <span class="arrow-right"></span>
      </li>
    </ul>
  </div>
  <div class="course-button">
    Courses
  </div>
</div>
    <div class="dimmer">
      
    </div>