import { TitleCasePipe } from "@angular/common";
import { Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Subject } from "rxjs";

declare var $:any;
@Injectable({
  providedIn: "root",
})
export class UtilService {
  loading = new Subject<boolean>();
  status = new Subject<boolean>();
  title = new Title(document);
  courses = new Subject<any>();
  CourseList:any;
  constructor() {}

  getLoadingStatus() {
    return this.loading;
  }
  setLoadingStatus(loading: boolean) {
    this.loading.next(loading);
  }
  
  setStatus(status:boolean){
    this.status.next(status);
  }

  showResponseModal(status:boolean){
    this.status.next(status);
    $('#responseModal').modal('show');
    setTimeout(() => {
      $('#responseModal').modal('hide');
    }, 3000);

  }
  setTitle(newTitle){
    this.title.setTitle(newTitle);
  }

  getTitle(){
    return this.title.getTitle();
  }
  getCourses(){
    return this.courses;
  }
  setCourses(courses){
    this.courses.next(courses);
  }
  compareValues(key, order = 'asc') {
    return function innerSort(a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        // property doesn't exist on either object
        return 0;
      }
  
      const varA = (typeof a[key] === 'string')
        ? a[key].toUpperCase() : a[key];
      const varB = (typeof b[key] === 'string')
        ? b[key].toUpperCase() : b[key];
  
      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return (
        (order === 'desc') ? (comparison * -1) : comparison
      );
    };
  }
}
