import { Component, OnInit, Inject } from "@angular/core";
import {
  Router,
  NavigationEnd,
  ActivatedRoute,
  NavigationStart,
} from "@angular/router";
import { ApiService } from "./services/http/api.service";
import { NgwWowService } from "ngx-wow";
import { filter } from "rxjs/operators";
import { UtilService } from "./services/util.service";
import { AuthenticationService } from "./services/authentication/authentication.service";
import { LoginService } from "./services/login/login.service";

declare var $:any;
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.less"],
})
export class AppComponent implements OnInit {
  loading = true;
  loginStatus = false;
  isAdminLoggingIn = true;
  constructor(
    private router: Router,
    private wow: NgwWowService,
    private route: ActivatedRoute,
    private util: UtilService,
    private auth: AuthenticationService,
    private login: LoginService,
    private api: ApiService
  ) {
    util.loading.subscribe((res) => {
      this.loading = res;
    });
    this.auth.adminLoginStatus.subscribe((res) => {
      this.loginStatus = res;
    });
    // this.api.getCourses().subscribe(
    //   (res) => {
    //     if (res.status == 200) {
    //       this.util.setCourses(res.body);
    //     }
    //   },
    //   (err) => {
    //     console.log(err);
    //   }
    // );
    // this.propertyColor = this.apiservice.getApiData();
    const navEndEvents = router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    );
    navEndEvents.subscribe((event: NavigationEnd) => {
      (<any>window).ga("set", "page", event.urlAfterRedirects);
      (<any>window).ga("send", "pageview");
    });
  }
  // title = 'Look jQuery Animation working in action!';
  // propertyColor = [];

  public ngOnInit() {
    var coursesData;
   
    // this.api.getCourses().subscribe((res) => {
    //   coursesData = res.body.filter((course) => course.status == "Active").sort(this.util.compareValues('courseOrder'));
    //   coursesData && this.util.courses.next(coursesData);
    //   this.util.CourseList = coursesData;
    // });

    this.wow.init();

    // var changeTheme = () =>{
    //     const documentpath = document.documentElement.style;
    //     this.propertyColor.forEach(function(element){
    //     var property = `--${element.color}-color`;
    //     var propertybg = `--${element.color}-background-color`;
    //     var propertyborder = `--${element.color}-border-color`;
    //     var value = `rgb(${element.value.r},${element.value.g},${element.value.b})`;
    //     documentpath.setProperty( property, value);
    //     documentpath.setProperty( propertybg, value);
    //     documentpath.setProperty( propertyborder, value);
    //     });
    // }
    // setTimeout( changeTheme, 5000 );

    this.router.events.subscribe((e) => {
      if (e instanceof NavigationStart) {
        this.loading = true;
      }
      if (e instanceof NavigationEnd) {
        this.loading = false;
        var url = this.router.url;
        if (url.startsWith("/admin-login") || url.startsWith("/admin-panel")) {
          this.login.login(false);
          if (url.startsWith("/admin-login")) {
            this.auth.getAdminLoginStatus()
              ? this.router.navigate(["/admin-panel/contact-details"])
              : "";
          }
        } else {
          this.auth.setAdminLoginStatus(false);
        }

        if(!url.startsWith('/admin-panel') && !this.util.CourseList){
          this.api.getCourses().subscribe((res) => {
            coursesData = res.body.filter((course) => course.status == "Active").sort(this.util.compareValues('courseOrder'));
            coursesData && this.util.courses.next(coursesData);
            this.util.CourseList = coursesData;
          });
        }

      }
    });
    window.addEventListener("DOMContentLoaded", () => {
      setTimeout(() => {
        this.loading = false;
      }, 2000);
    });

    $(document).ready(function () {
      $(".filter-button").click(function () {
        var value = $(this).attr("data-filter");

        if (value == "all") {
          //  $('.filter').removeClass('hidden');
          $(".filter").show("1000");
        } else {
          //  $('.filter[filter-item="'+value+'"]').removeClass('hidden');
          //  $(".filter").not('.filter[filter-item="'+value+'"]').addClass('hidden');
          $(".filter")
            .not("." + value)
            .hide("3000");
          $(".filter")
            .filter("." + value)
            .show("3000");
        }
      });
      var toggleAffix = function (affixElement, scrollElement, wrapper) {
        var height = affixElement.outerHeight(),
          top = wrapper.offset().top;

        if (scrollElement.scrollTop() >= top) {
          wrapper.height(height);
          affixElement.addClass("affix");
        } else {
          affixElement.removeClass("affix");
          wrapper.height("auto");
        }
      };

      $('[data-toggle="affix"]').each(function () {
        var ele = $(this),
          wrapper = $("<div></div>");

        ele.before(wrapper);
        $(window).on("scroll resize", function () {
          toggleAffix(ele, $(this), wrapper);
        });

        // init
        toggleAffix(ele, $(window), wrapper);
      });
    });

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}
