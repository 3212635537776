<div class="bg-1">
  <!-- <div
    class="container-fluid d-flex justify-content-center"
    style="background: var(--primary-background-color)"
  >
    <h3 class="moto text-center">Connecting Campus with Career</h3>
  </div> -->
  <!-- <div class="container d-flex align-items-center" style="margin: 50px auto">
    <div class="row">
      <div class="col-12 wow fadeInUp">
        <blockquote>
          To create a pool of highly-skilled, knowledgeable and marketable
          graduates and professionals in Finance and Accounts domain and prepare
          them for the upcoming challenges by providing them with right tools
          and guidance
        </blockquote>
      </div>
    </div>
  </div> -->
  <div class="workbook">
    <div class="container pt-5">
      <div class="row justify-content-center">
        <div class="col-lg-8 col-md-10 col-sm-10 col-12 wow fadeInUp">
          <h1 class="text-center">
            How does it work<span class="primary-text">?</span>
          </h1>
          <hr class="devider wow fadeInUp">
        </div>
        <div class="col-lg-8 col-md-10 col-sm-10 col-12 wow fadeInUp">
          <h5 class="text-center">
            The fundamental philosophy and the mantra of Artha Siksha is a 5
            stage journey of a learner towards an aspiring professional career.
          </h5>
        </div>
        </div>
    </div>
  <div class="container-fluid">
    <div class="row justify-content-center">
        <div class="col-md-12 col-sm-12 col-12 my-5 wow fadeInUp d-flex justify-content-center">
          <img
            src="assets/arthaSiksha/HowItWorks-mainPage-2.png"
            class="img-fluid w-100"
            alt="Smiley face"
            style="max-width: 1500px;"
          />
        </div>
        </div>
      </div>
        <div class="container">
        <div class="row justify-content-center">
        <div class="col-md-8 col-sm-12 text-center wow fadeInUp">
          Designed as a comprehensive learning service for F&A domain, Artha
          Siksha is designed to achieve tangible outcome for Learners. The
          service offerings cater not just to the primary consumer i.e. the
          Individual Learners, but also brings value proposition for the
          institution for large scale learning and organizations looking for
          niche Reskilling services in F&A domain.
        </div>
        <!-- <div class="col-md-12 col-sm-12 col-12 my-4">

        <h3 class="my-5 text-center">What makes Artha Siksha a viable Learning Service in F&A domain</h3>
        <ul class="as-list domain-list row">
          <li class="wow fadeInUp col-md-4 col-sm-6" >
            <div class="text">Curated by industry experts with decades of global exposure and experience</div>
          </li>
          <li class="wow fadeInUp col-md-4 col-sm-6" data-wow-delay="0.1s">
            <div class="text">Provides highly competent and in-demand F&A courses</div>
          </li>
          <li class="wow fadeInUp col-md-4 col-sm-6" data-wow-delay="0.2s">
            <div class="text">Robust methodology to record Skill Inventory</div>
          </li>
          <li class="wow fadeInUp col-md-4 col-sm-6" data-wow-delay="0.3s">
            <div class="text">Comprehensive ‘Pre-Assessment’ across various F&A categories</div>
          </li>
          <li class="wow fadeInUp col-md-4 col-sm-6" data-wow-delay="0.4s">
            <div class="text">Blended training delivery (Self-Learning + Instructor led training)</div>
          </li>
          <li class="wow fadeInUp col-md-4 col-sm-6" data-wow-delay="0.5s">
            <div class="text">Flexible platform to suit learner’s requirement</div>
          </li>
          <li class="wow fadeInUp col-md-4 col-sm-6" data-wow-delay="0.6s">
            <div class="text">Competitive pricing with inclusion of Learning Management platform</div>
          </li>
          <li class="wow fadeInUp col-md-4 col-sm-6" data-wow-delay="0.7s">
            <div class="text">Constantly evolving content to stay in pace with emerging trends and industry demand</div>
          </li>
        </ul>
      </div> -->
      </div>
    </div>
  </div>
</div>

  <div class="container" id="test">
    <div class="row py-5 justify-content-center">
      <div class="col-md-8 col-lg-8 col-sm-12">
        <h1 class="main-heading text-center wow fadeInUp">
          What makes Artha Siksha a viable Learning Service in F&A domain
        </h1>
        <hr class="devider wow fadeInUp" />
        <p class="text-center wow fadeInUp">
          Designed as a comprehensive learning service for F&A domain, Artha
          Siksha is developed to achieve tangible outcome for Learners. The
          service offerings cater not just to the Individual Learners, but also
          brings value proposition for the educational institutions and
          organizations.
        </p>
      </div>
    </div>
    <div class="row p-0 section-1">
      <div class="col-md-6 col-lg-6 col-sm-12 p-0 anim-left">
        <div class="about-image-1 about-image wow fadeInLeft"></div>
      </div>
      <div class="col-md-6 col-lg-6 col-sm-12 p-0 anim-right">
        <div class="p-5 content wow fadeInRight">
          <p class="my-auto">
            One of a kind holistic training program, combining the academic
            curriculum with on the job practical experience provides a platform
            to jumpstart your professional journey.
          </p>
        </div>
      </div>
    </div>
    <div class="row p-0 section-2">
      <div class="col-md-6 col-lg-6 col-sm-12 p-0 anim-right">
        <div class="about-image-2 about-image wow fadeInRight"></div>
      </div>
      <div class="col-md-6 col-lg-6 col-sm-12 p-0 anim-left">
        <div class="p-5 content wow fadeInLeft">
          <p class="my-auto">
            ‘Campus to career’ approach helping students by providing them with
            right guidance from the day they start with us to the day they are
            recruited and further using world class L&D lab coupled with
            industry leading Learning Management System for the most efficient
            learning.
          </p>
        </div>
      </div>
    </div>
    <div class="row p-0 section-3">
      <div class="col-md-6 col-lg-6 col-sm-12 p-0 anim-left">
        <div class="about-image-3 about-image wow fadeInLeft"></div>
      </div>
      <div class="col-md-6 col-lg-6 col-sm-12 p-0 anim-right">
        <div class="p-5 content wow fadeInRight">
          <p class="my-auto">
            Readily deployable highly-skilled talent who can be recruited for a
            short-term, long-term or on a permanent basis.
          </p>
        </div>
      </div>
    </div>
    <div class="row p-0 section-4">
      <div class="col-md-6 col-lg-6 col-sm-12 p-0 anim-right">
        <div class="about-image-4 about-image wow fadeInRight"></div>
      </div>
      <div class="col-md-6 col-lg-6 col-sm-12 p-0 anim-left">
        <div class="p-5 content wow fadeInLeft">
          <p class="my-auto">
            Nurture the talent and connect them with the right kind of
            opportunities to help them become the nexgen commerce professionals.
            Helping the professionals in reskilling and upskilling to advance in
            their career.
          </p>
        </div>
      </div>
    </div>
  </div>

<!-- <div class="container-fluid py-5">
  <div class="row domain-container">
    <div class="col-12 p-0 row justify-content-center" style="backdrop-filter: brightness(0.5)">
      <h2 class="mt-5 text-center wow fadeInUp">
        What makes Artha Siksha a viable Learning Service in F&A domain
      </h2>
      <p class="col-8 mb-5 text-center">
        Designed as a comprehensive learning service for F&A domain, Artha
        Siksha is developed to achieve tangible outcome for Learners. The
        service offerings cater not just to the Individual Learners, but also
        brings value proposition for the educational institutions and
        organizations.
      </p>
    </div>
    <div class="col-md-4 col-lg-4 col-sm-6 domain-list-item">
      <div class="domain-icon"></div>
      <div class="domain-text">
        One of a kind training program, combining the academic curriculum with on the job practical experience provides a platform to jumpstart your professional journey.
      </div>
    </div>
    <div class="col-md-4 col-lg-4 col-sm-6 domain-list-item">
      <div class="domain-icon"></div>
      <div class="domain-text">‘Campus to career’ approach helping students by providing them with right guidance from the day they start with us to the day they are recruited and further using world class L&D lab coupled with industry leading Learning Management System for the most efficient learning.</div>
    </div>
    <div class="col-md-4 col-lg-4 col-sm-6 domain-list-item">
      <div class="domain-icon"></div>
      <div class="domain-text">
        	Readily deployable highly-skilled talent who can be recruited for a short-term, long-term or on a permanent basis.  </div>
    </div>
    <div class="col-md-4 col-lg-4 col-sm-6 domain-list-item">
      <div class="domain-icon"></div>
      <div class="domain-text">
         	World class L&D lab coupled with industry leading Learning Management System for most efficient learning. 
      </div>
    </div>
    <div class="col-md-4 col-lg-4 col-sm-6 domain-list-item">
      <div class="domain-icon"></div>
      <div class="domain-text">
         	Nurture the talent and connect them with the right kind of opportunities to help them become the nexgen commerce professionals.
      </div>
    </div>
    <div class="col-md-4 col-lg-4 col-sm-6 domain-list-item">
      <div class="domain-icon"></div>
      <div class="domain-text">
         Helping the professionals in reskilling and upskilling to advance in their career.
      </div>
    </div>
  </div>
</div> -->
