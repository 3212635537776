import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(blogs: any[], searchField: any): any[] {
    if (!blogs) return [];
    if (!searchField) return blogs;

    return blogs.filter(item => {
      return Object.keys(item).some(key => {
        return String(item[key]).toLowerCase().includes(searchField.toLowerCase());
      });
    });
  }

}
