import { Component, OnInit, Input } from '@angular/core';
import { ViewEncapsulation } from '@angular/compiler/src/core';

@Component({
  selector: 'app-heading1',
  templateUrl: './heading1.component.html',
  styleUrls: ['./heading1.component.less']
})

export class Heading1Component implements OnInit {
  ngOnInit(){
  }
}
