<section class="flex-container upcomingevents1 container flex-d-c flex-ai-c">
	<app-heading1>
		<h4>heading title3</h4>
		<h2>heading heading3 </h2>
		<p>heading description3</p>
	</app-heading1>  
	<div class="sh-column sh-column-ddbaa141de8a5cbec5b19435b4a7a82b fw-col-xs-12 sh-column-mobile-padding">
		<div class="sh-column-wrapper">
			<div class="sh-timeline-2 sh-timeline-style2" id="timeline-eab9dc772225ed982c56a8363d69a3a1">
				<div class="sh-timeline-item">
					<div class="sh-timeline-item-container">
						<div class="sh-timeline-box sh-timeline-box-left">
							<div class="sh-table">
								<div class="sh-table-cell sh-timeline-image">
									<a 
										href="//cdn.jevelin.shufflehound.com/education/wp-content/uploads/sites/20/2018/06/andres-gerlotti-560529-unsplash.jpg" 
										rel="lightbox" 
										class="sh-timeline-image-container" 
										style="background-image: url(https://cdn.jevelin.shufflehound.com/wp-content/uploads/sites/20/2018/06/andres-gerlotti-560529-unsplash.jpg);"
									></a>
								</div>
								<div class="sh-timeline-content-container sh-table-cell">
									<h3>
										<i class="ti-user"></i>
										Campus Student Meeting								
									</h3>
									<div class="sh-timeline-content">
										<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean mattis ultrices turpis, nec vulputate metus luctus 
											pharetra. Duis suscipit iaculis tempus. Aliquam egestas, libero sit amet ullamcorper ultrices.
										</p>
									</div>
								</div>
							</div>
							<div class="sh-timeline-box-circle"></div>
							<div class="sh-timeline-box-tale"></div>
							<div class="sh-timeline-date">
								July 23, 10:30 A.M.						
							</div>
						</div>
						<div class="sh-timeline-box sh-timeline-box-right">
							<div class="sh-table">
								<div class="sh-timeline-content-container sh-table-cell">
									<h3>
                    Campus Student Meeting																			
                    <i class="ti-user"></i>
									</h3>
									<div class="sh-timeline-content">
										<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean mattis ultrices turpis, nec vulputate metus luctus 
											pharetra. Duis suscipit iaculis tempus. Aliquam egestas, libero sit amet ullamcorper ultrices.
										</p>
									</div>
								</div>
								<div class="sh-table-cell sh-timeline-image">
									<a 
										href="//cdn.jevelin.shufflehound.com/education/wp-content/uploads/sites/20/2018/06/andres-gerlotti-560529-unsplash.jpg" 
										rel="lightbox"
										class="sh-timeline-image-container" 
										style="background-image: url(https://cdn.jevelin.shufflehound.com/wp-content/uploads/sites/20/2018/06/andres-gerlotti-560529-unsplash.jpg);"
									></a>
								</div>
							</div>
							<div class="sh-timeline-box-circle"></div>
							<div class="sh-timeline-box-tale"></div>
							<div class="sh-timeline-date">
								July 23, 10:30 A.M.						
							</div>
						</div>
					</div>
				</div>
				<div class="sh-timeline-item">
					<div class="sh-timeline-item-container">
						<div class="sh-timeline-box sh-timeline-box-left">
							<div class="sh-table">
								<div class="sh-table-cell sh-timeline-image">
									<a href="//cdn.jevelin.shufflehound.com/education/wp-content/uploads/sites/20/2018/06/rawpixel-626050-unsplash.jpg" 
									rel="lightbox" class="sh-timeline-image-container" style="background-image: url(https://cdn.jevelin.shufflehound.com/wp-content/uploads/sites/20/2018/06/rawpixel-626050-unsplash.jpg);"></a>
								</div>
								<div class="sh-timeline-content-container sh-table-cell">
									<h3>
										<i class="ti-calendar"></i>
										Graduation Party Planning								
									</h3>
									<div class="sh-timeline-content">
										<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean mattis ultrices turpis, nec vulputate metus 
											luctus pharetra. Duis suscipit iaculis tempus. Aliquam egestas, libero sit amet ullamcorper ultrices.
										</p>
									</div>
								</div>
							</div>
							<div class="sh-timeline-box-circle"></div>
							<div class="sh-timeline-box-tale"></div>
							<div class="sh-timeline-date">
								July 30, 12:00 A.M.						
							</div>
						</div>
						<div class="sh-timeline-box sh-timeline-box-right">
							<div class="sh-table">
								<div class="sh-timeline-content-container sh-table-cell">
									<h3>
										Graduation Party Planning																			
										<i class="ti-calendar"></i>
									</h3>
									<div class="sh-timeline-content">
										<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean mattis ultrices turpis, nec vulputate metus 
											luctus pharetra. Duis suscipit iaculis tempus. Aliquam egestas, libero sit amet ullamcorper ultrices.
										</p>
									</div>
								</div>
								<div class="sh-table-cell sh-timeline-image">
									<a 
										href="//cdn.jevelin.shufflehound.com/education/wp-content/uploads/sites/20/2018/06/rawpixel-626050-unsplash.jpg" 
										rel="lightbox" 
										class="sh-timeline-image-container" 
										style="background-image: url(https://cdn.jevelin.shufflehound.com/wp-content/uploads/sites/20/2018/06/rawpixel-626050-unsplash.jpg);"
									></a>
								</div>
							</div>
							<div class="sh-timeline-box-circle"></div>
							<div class="sh-timeline-box-tale"></div>
							<div class="sh-timeline-date">
								July 30, 12:00 A.M.						
							</div>
						</div>
					</div>
				</div>
				<div class="sh-timeline-item">
					<div class="sh-timeline-item-container">
						<div class="sh-timeline-box sh-timeline-box-left">
							<div class="sh-table">
								<div class="sh-table-cell sh-timeline-image">
									<a 
									href="//cdn.jevelin.shufflehound.com/education/wp-content/uploads/sites/20/2018/06/tanialee-gonzalez-667998-unsplash.jpg" 
									rel="lightbox" 
									class="sh-timeline-image-container" 
									style="background-image: url(https://cdn.jevelin.shufflehound.com/wp-content/uploads/sites/20/2018/06/tanialee-gonzalez-667998-unsplash.jpg);"
									></a>
								</div>
								<div class="sh-timeline-content-container sh-table-cell">
									<h3>
										<i class="ti-blackboard"></i>
										New Headquarters Presentation								
									</h3>
									<div class="sh-timeline-content">
										<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean mattis ultrices turpis, nec vulputate metus luctus 
											pharetra. Duis suscipit iaculis tempus. Aliquam egestas, libero sit amet ullamcorper ultrices.
										</p>
									</div>
								</div>
							</div>
							<div class="sh-timeline-box-circle"></div>
							<div class="sh-timeline-box-tale"></div>
							<div class="sh-timeline-date">
								August 10, 14:00 P.M.						
							</div>
						</div>
						<div class="sh-timeline-box sh-timeline-box-right">
							<div class="sh-table">
								<div class="sh-timeline-content-container sh-table-cell">
									<h3>
										New Headquarters Presentation																			<i class="ti-blackboard"></i>
									</h3>
									<div class="sh-timeline-content">
										<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean mattis ultrices turpis, nec vulputate metus luctus 
											pharetra. Duis suscipit iaculis tempus. Aliquam egestas, libero sit amet ullamcorper ultrices.
										</p>
									</div>
								</div>
								<div class="sh-table-cell sh-timeline-image">
									<a 
										href="//cdn.jevelin.shufflehound.com/education/wp-content/uploads/sites/20/2018/06/tanialee-gonzalez-667998-unsplash.jpg" 
										rel="lightbox" 
										class="sh-timeline-image-container" 
										style="background-image: url(https://cdn.jevelin.shufflehound.com/wp-content/uploads/sites/20/2018/06/tanialee-gonzalez-667998-unsplash.jpg);"
									></a>
								</div>
							</div>
							<div class="sh-timeline-box-circle"></div>
							<div class="sh-timeline-box-tale"></div>
							<div class="sh-timeline-date">
								August 10, 14:00 P.M.						
							</div>
						</div>
					</div>
				</div>
				<div class="sh-timeline-item">
					<div class="sh-timeline-item-container">
						<div class="sh-timeline-box sh-timeline-box-left">
							<div class="sh-table">
								<div class="sh-table-cell sh-timeline-image">
									<a 
										href="//cdn.jevelin.shufflehound.com/education/wp-content/uploads/sites/20/2018/06/logan-isbell-672551-unsplash.jpg"
										rel="lightbox" 
										class="sh-timeline-image-container"
										style="background-image: url(https://cdn.jevelin.shufflehound.com/wp-content/uploads/sites/20/2018/06/logan-isbell-672551-unsplash.jpg);"
									></a>
								</div>
								<div class="sh-timeline-content-container sh-table-cell">
									<h3>
										<i class="ti-comment"></i>
										Graduates Tell Their Story								
									</h3>
									<div class="sh-timeline-content">
										<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean mattis ultrices turpis, nec vulputate metus luctus 
											pharetra. Duis suscipit iaculis tempus. Aliquam egestas, libero sit amet ullamcorper ultrices.
										</p>
									</div>
								</div>
							</div>
							<div class="sh-timeline-box-circle"></div>
							<div class="sh-timeline-box-tale"></div>
							<div class="sh-timeline-date">
								September 3, 09:30 A.M.						
							</div>
						</div>
						<div class="sh-timeline-box sh-timeline-box-right">
							<div class="sh-table">
								<div class="sh-timeline-content-container sh-table-cell">
									<h3>
										Graduates Tell Their Story																			<i class="ti-comment"></i>
									</h3>
									<div class="sh-timeline-content">
										<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean mattis ultrices turpis, nec vulputate metus luctus 
											pharetra. Duis suscipit iaculis tempus. Aliquam egestas, libero sit amet ullamcorper ultrices.
										</p>
									</div>
								</div>
								<div class="sh-table-cell sh-timeline-image">
									<a 
										href="//cdn.jevelin.shufflehound.com/education/wp-content/uploads/sites/20/2018/06/logan-isbell-672551-unsplash.jpg" 
										rel="lightbox" 
										class="sh-timeline-image-container" 
										style="background-image: url(https://cdn.jevelin.shufflehound.com/wp-content/uploads/sites/20/2018/06/logan-isbell-672551-unsplash.jpg);"
									></a>
								</div>
							</div>
							<div class="sh-timeline-box-circle"></div>
							<div class="sh-timeline-box-tale"></div>
							<div class="sh-timeline-date">
								September 3, 09:30 A.M.						
							</div>
						</div>
					</div>
				</div>
      </div>
		</div>
	</div>
  <button class="button button-1 space-40" href="#">VIEW All</button>
</section>