<div class="container-fluid new-courses py-5">
  <div class="row">
    <div class="col-12 text-center">
      <h1 class="wow fadeInUp text-white" >Course Offerings</h1>
    <hr class="devider wow fadeInUp">
    </div>
  </div>
  <div class="row justify-content-center" *ngIf="cdata">
    <div class=" course-slider col-lg-4 col-md-4 col-sm-6 col-xs-12 profile m-0 p-0 wow fadeInUp" (mouseover)="MouseOveronCourse(i)" (mouseleave)="MouseLeaveonCourse(i)"
      *ngFor="let course of cdata;let i = index;" (click)="toCourse(i)" [attr.data-wow-duration]="(i+1)+'s'">
      <div class="course-container" *ngIf="i < 3">

        <div class="course-image">
          <img src="{{course.courseImage.fileData}}" class="img-fluid d-mobi-none w-100 p-2" alt="card image for component">
        </div>
        <div class="course-details">
          <div>
            <h6 class="p-3 m-0"> {{course.courseName}}</h6>
          </div>
          <div>
            <strong>Duration:</strong>
            <label class=" ">{{course.courseDuration}}</label>
          </div>
        </div>
      </div>
      <!-- <div class="img-box h-100 mx-auto">
        <div class="course-img-title">
          <img src="{{course.imgSrc}}" class="img-fluid d-mobi-none" alt="card image for component">
        </div>
        <div class="course-name">
          <h5 class="p-3 m-0"> {{course.name}}</h5>
        </div>
        <hr class="m-0">
        <div class="text-box p-3">
          <strong>Duration:</strong><br>
          <label class=" ">{{course.duration}}</label>
        </div>
      </div> -->
    </div>
    <div class="col-12 text-center py-4">
      <button class="view-more-courses" (click)="toOpenCourses()">View More</button>
    </div>
  </div>
</div>
<!-- <div class="courses-card">
  <h3 class="mb-1">{{cdata.name}}</h3>
  <div class="course-slider-box" id="course{{cdata.sname}}" *ngFor=" let courseSubdata of cdata.subdata;index as i">
    <div class="row pt-md ">
      <div class=" course-slider col-lg-3 col-md-3 col-sm-4 col-xs-12 profile"
        *ngFor="let cdata of courseSubdata.coursedata">
        <div class="img-box">
          <div class="course-img-title">
            <img src="{{cdata.img}}" class="img-fluid d-mobi-none" alt="card image for component">
            <h4 class=""> {{cdata.name}}</h4>
          </div>
          <div class="text-box p-3">
            <label class="mb-3">{{cdata.Date}}</label>
            <div class="flex-container flex-jc-sb flex-ai-c">
              <h5> <i class="fa fa-inr"></i> {{cdata.Amount}}</h5>
              <i title="View Details" class="fa fa-ellipsis-h" routerLink="/course-detail"
                [queryParams]="{course: cdata.key}"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="course-control left"><i class="fa fa-angle-left" aria-hidden="true"></i></div>
    <div class="course-control right" *ngIf="rightMove"><i class="fa fa-angle-right" aria-hidden="true"></i></div>
  </div>
</div> -->