import { AfterViewInit, Component, OnInit } from '@angular/core';

declare var $:any;
@Component({
  selector: 'app-admin-register-details',
  templateUrl: './admin-register-details.component.html',
  styleUrls: ['./admin-register-details.component.less']
})
export class AdminRegisterDetailsComponent implements OnInit ,AfterViewInit{

  constructor() { }
  ngAfterViewInit(): void {
    $(document).ready(function() {
      $('#example').DataTable();
  } );
  }

  ngOnInit() {
    
  }

}
