<footer class="footer2">
  <div class="backimg_footer footer_border">
    <div class="container">
      <div class="row">
        <div class="col-md-5 col-sm-5 col-xs-12">
          <h5>Who We Are</h5>
          <p>
            Artha Siksha is one of a kind learning & development platform
            started with a main moto “connecting campus to career” be that
            campus a college or an existing work place. Our objective is to
            prepare commerce graduates and professionals to face and conquer
            NexGen challenges by offering them bespoke training programs
            targeting their specific needs.
          </p>
        </div>
        <div class="col-md-2 col-sm-2 col-xs-12">
          <h5>Links</h5>
          <ul class="ul_footer">
            <li><a routerLink="student">Students</a></li>
            <li><a routerLink="professionals">Professionals</a></li>
            <li><a routerLink="academic">Academic Institution</a></li>
            <li><a routerLink="organization">Organization</a></li>
            <li><a routerLink="about-us">About us</a></li>
            <li><a routerLink="contact-us">Contact us</a></li>
          </ul>
        </div>
        <div class="col-md-2 col-sm-2 col-xs-12">
          <h5>Account</h5>
          <ul class="ul_footer">
            <li>
              <a
                href="{{ applicationUrl }}/"
                target="_blank"
                title="student-login"
                >Student Login</a
              >
            </li>
            <li>
              <a
                href="{{ applicationUrl }}/trainer-login"
                target="_blank"
                title="trainer-connect"
              >
                Professional Login</a
              >
            </li>
            <li>
              <a
                href="{{ applicationUrl }}/corporate-login"
                target="_blank"
                title="corporate-connect"
                >Academic Login</a
              >
            </li>
            <li>
              <a
                href="{{ applicationUrl }}/admin-login"
                target="_blank"
                title="student-login"
                >Organization Login</a
              >
            </li>
          </ul>
        </div>
        <div class="col-md-3 col-sm-3 col-xs-12">
          <h5>Connect with us</h5>
          <div class="flex-container flex-w-w social-links">
            <a href="http://www.facebook.com/" title="facebook" target="_black">
              <i class="fa fa-facebook fa_footer" aria-hidden="true"></i>
            </a>
            <a href="http://www.twitter.com/" title="twitter" target="_black">
              <i class="fa fa-twitter fa_footer_twitter" aria-hidden="true"></i>
            </a>
            <a href="http://www.linkedin.com/" title="linkedin" target="_black">
              <i
                class="fa fa-linkedin fa_footer_linkidin"
                aria-hidden="true"
              ></i>
            </a>
            <a href="http://www.youtube.com/" title="youtube" target="_black">
              <i class="fa fa-youtube fa_footer_youtube" aria-hidden="true"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-bar">
    <div class="container">
      <span class="navbar-text pull-left no-margin"
        >© 2021 -
        <a href="#">Artha Siksha</a>
        . All rights reserved.
      </span>
      <!-- <div class="flex-container flex-w-w social-links ml-5 pull-left">
                <a href="http://www.facebook.com/" title="facebook" target="_black">
                    <i class="fa fa-facebook fa_footer" aria-hidden="true"></i>
                </a>
                <a href="http://www.twitter.com/" title="twitter" target="_black">
                    <i class="fa fa-twitter fa_footer_twitter" aria-hidden="true"></i>
                </a>
                <a href="http://www.linkedin.com/" title="linkedin" target="_black">
                    <i class="fa fa-linkedin fa_footer_linkidin" aria-hidden="true"></i>
                </a>
                <a href="http://www.youtube.com/" title="youtube" target="_black">
                    <i class="fa fa-youtube fa_footer_youtube" aria-hidden="true"></i>
                </a>
            </div> -->
      <span class="navbar-text pull-right">
        <a href="https://b-esharp.com/" target="_blank">
          A product of <strong>BE Sharp Consulting</strong></a
        >
      </span>
    </div>`
  </div>
</footer>
