<form class="form-container">
  <fieldset>
    <div class="term-condition-block">
      <div class="conditions">
        <h4>Terms and Conditions</h4>
        <p>
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eligendi numquam, voluptatibus adipisci asperiores labore provident. Alias mollitia quo sunt consequuntur corrupti impedit ut aut vero ea, maxime, sed modi ipsa!
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eligendi numquam, voluptatibus adipisci asperiores labore provident. Alias mollitia quo sunt consequuntur corrupti impedit ut aut vero ea, maxime, sed modi ipsa!
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eligendi numquam, voluptatibus adipisci asperiores labore provident. Alias mollitia quo sunt consequuntur corrupti impedit ut aut vero ea, maxime, sed modi ipsa!
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eligendi numquam, voluptatibus adipisci asperiores labore provident. Alias mollitia quo sunt consequuntur corrupti impedit ut aut vero ea, maxime, sed modi ipsa!
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eligendi numquam, voluptatibus adipisci asperiores labore provident. Alias mollitia quo sunt consequuntur corrupti impedit ut aut vero ea, maxime, sed modi ipsa!
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eligendi numquam, voluptatibus adipisci asperiores labore provident. Alias mollitia quo sunt consequuntur corrupti impedit ut aut vero ea, maxime, sed modi ipsa!
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eligendi numquam, voluptatibus adipisci asperiores labore provident. Alias mollitia quo sunt consequuntur corrupti impedit ut aut vero ea, maxime, sed modi ipsa!
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eligendi numquam, voluptatibus adipisci asperiores labore provident. Alias mollitia quo sunt consequuntur corrupti impedit ut aut vero ea, maxime, sed modi ipsa!
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eligendi numquam, voluptatibus adipisci asperiores labore provident. Alias mollitia quo sunt consequuntur corrupti impedit ut aut vero ea, maxime, sed modi ipsa!
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eligendi numquam, voluptatibus adipisci asperiores labore provident. Alias mollitia quo sunt consequuntur corrupti impedit ut aut vero ea, maxime, sed modi ipsa!
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eligendi numquam, voluptatibus adipisci asperiores labore provident. Alias mollitia quo sunt consequuntur corrupti impedit ut aut vero ea, maxime, sed modi ipsa!
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eligendi numquam, voluptatibus adipisci asperiores labore provident. Alias mollitia quo sunt consequuntur corrupti impedit ut aut vero ea, maxime, sed modi ipsa!
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eligendi numquam, voluptatibus adipisci asperiores labore provident. Alias mollitia quo sunt consequuntur corrupti impedit ut aut vero ea, maxime, sed modi ipsa!
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eligendi numquam, voluptatibus adipisci asperiores labore provident. Alias mollitia quo sunt consequuntur corrupti impedit ut aut vero ea, maxime, sed modi ipsa!
        </p>
      </div>
      <label class="form-check-label">
        <input type="checkbox" class="form-check-input" name="" id="" value="checkedValue" checked>
        I accept the terms and conditions
      </label>
      <button class="button button-1 next">Next</button>
    </div>
  </fieldset>

  <fieldset class="d-none">
    <div class="form-group row">
      <label for="inputName" class="col-sm-1-12 col-form-label"></label>
      <div class="col-sm-1-12">
        <input type="text|password|email|number|submit|date|datetime|datetime-local|month|color|range|search|tel|time|url|week" class="form-control" name="inputName" id="inputName" placeholder="">
      </div>
    </div>
    <fieldset class="form-group row">
      <legend class="col-form-legend col-sm-1-12">Group name</legend>
      <div class="col-sm-1-12">
        
      </div>
    </fieldset>
    <div class="form-group row">
      <div class="offset-sm-2 col-sm-10">
        <button type="submit" class="btn btn-primary">Action</button>
      </div>
    </div>
  </fieldset>
</form>