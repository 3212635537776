<div class="container-fluid p-0">
  <div class="image-container">
    <div class="image"></div>
  </div>
</div>
<div class="bg-student1">
  <div class="container">
    <div class="row entry-content ">
      <p class="text-center wow fadeInUp">
        Artha Siksha is designed and developed to cater to students who are
        nearing completion of their education and/or those who are eying for
        their first career opportunity. The course offering and delivery gives
        flexibility and freedom to an individual learner to identify their
        leaning areas, assess their existing competency and choose learning
        interventions for a tangible outcome. The courses are designed keeping
        in mind the nature of Individual Learner.
      </p>
      <div class="button-wrapper mx-auto wow fadeInUp" routerLink="/student-register">
        <button>Register</button>
      </div>
    </div>
  </div>

  <div class="container py-3">
    <div class="row align-items-center" style="flex-direction: row-reverse">
      <div class="col-md-4 wow fadeInRight">
        <div class="image-wrapper">
          <img
            src="assets/images/icons/image-corner.svg"
            alt=""
            class="img-corner img-top-right"
          />
          <img
            src="assets/arthaSiksha/student/student5-square.jpg"
            alt=""
            class="img-fluid p-5"
          />
          <img
            src="assets/images/icons/image-corner.svg"
            alt=""
            class="img-corner img-left-bottom"
          />
        </div>
      </div>
      <div class="col-md-8 px-5 wow fadeInLeft">
        <!-- <h2 class="mb-4">
        Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.
      </h2> -->
        <p class="starter-box">
          Living in this highly competitive world, it’s imperative to be on top
          of the league in order to get noticed and excel in a desired career.
          Artha Siksha serves as a platform to assess and attain domain skills
          that assists a learner to be equipped with requisite knowledge in F&A
          domain. We have often seen the growing discontent and skill gap in
          students who are in the final year of academic career or even after
          graduating. Most of the times students are not aware of the gap
          between their existing skillset and the expectations from corporate
          world.
        </p>
      </div>
    </div>
  </div>
  <div class="container py-3">
    <div class="row align-items-center">
      <div class="col-md-4 wow fadeInLeft">
        <div class="image-wrapper">
          <img
            src="assets/images/icons/image-corner.svg"
            alt=""
            class="img-corner img-top-left"
          />
          <img
            src="assets/arthaSiksha/student/student2-square.jpg"
            alt=""
            class="img-fluid p-5"
          />
          <img
            src="assets/images/icons/image-corner.svg"
            alt=""
            class="img-corner img-right-bottom"
          />
        </div>
      </div>
      <div class="col-md-8 px-5 wow fadeInRight">
        <!-- <h2 class="mb-4">
        Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.
      </h2> -->
        <p class="starter-box">
          Artha Siksha offers course curriculum (short term and long term) that
          are in sync with the industry demand. Our course design does
          complement the academic curriculum but holistically speaking, our
          curriculum goes beyond what was taught in the classroom. And the
          reason is precisely to bridge the skill gap. What a student learns as
          a part of academic curriculum is only a foundational wisdom. Artha
          Siksha transforms the wisdom to knowledge and transitions into
          employable skills.
        </p>
      </div>
    </div>
  </div>
</div>
<div class="bg-student2">
  <div class="container py-3">
    <div class="row py-5 justify-content-center align-items-center">
      <div class="col-md-10 mb-4 text-center">
        <h2 class="wow fadeInUp">
          How does it work <span class="primary-text">?</span>
        </h2>
        <hr class="devider wow fadeInUp" />
      </div>
      <div class="col-md-12 my-5 wow fadeInUp">
        <img
          src="/assets/arthaSiksha/individualStudent-howitworks.png"
          alt=""
          class="img-fluid"
        />
      </div>
      <div class="col-md-8 col-sm-12 text-center">
        <p class="wow fadeInUp">
          We understand the challenges you face when you transition from your
          academic world to the professional arena. Recognizing the finer
          aspects of the challenges, Artha Siksha is designed to bridge the
          skill gap and empower you with the required competencies.
        </p>
      </div>
    </div>
  </div>
</div>

<div class="container py-3">
  <div class="row assessment-help">
    <div class="col-md-4 col-lg-4 col-sm-12 pt-4">
      <h2 class="wow fadeInUp">
        How does the assessment help <span class="primary-text">?</span>
      </h2>
      <hr class="devider wow fadeInUp ml-0" />
    </div>
    <div class="col-md-8 col-lg-8 col-sm-12 row assessment-help-list">
      <div
        class="col-md-6 py-4 wow fadeInUp"
        *ngFor="let item of assessmentHelp"
      >
        <div class="assessment-help-list-item p-md-4">
          <span class="corner {{ item.classes[0] }}"></span>
          {{ item.data }}
          <span class="corner {{ item.classes[1] }}"></span>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="bg-student3">
  <div class="container py-5">
    <div class="row intervention-work px-4">
      <div class="">
        <div class="intervention-work-image wow fadeInLeft">
          <img
            src="assets/arthaSiksha/student/student6-square.jpg"
            alt=""
            class="img-fluid"
          />
          <div class="w-100 h-100 bg-circle bg-circle-1"></div>
          <div class="w-100 h-100 bg-circle bg-circle-2"></div>
        </div>
      </div>
      <div class="intervention-list">
        <h3 class="intervention-heading ">
          How does the learning intervention work ?
        </h3>
        <p class="">
          Artha Siksha doesn’t stop at just assessing you. It also identifies
          the learning areas and provides high calibre training on all the key
          F&A areas.
        </p>
        <ul>
          <li class="">
            <span class="corner top-right"></span>
            <p>
              Combine above two – Content developed by highly experienced
              industry experts and delivered through blended training platform
              (classroom and LMS)
            </p>
          </li>
          <li class="">
            <span class="corner top-right"></span>
            <p>
              Self-learning modules along with practice assessments to check
              knowledge retention.
            </p>
          </li>
          <li class="">
            <span class="corner top-right"></span>
            <p>
              On the Job Trainings for higher retention coupled with interactive
              live trainings by experts.
            </p>
          </li>
          <li class="">
            <span class="corner top-right"></span>
            <p>
              Post learning assessment, certification and placement assistance
              (when opted).
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="container py-5">
    <div class="row">
      <div class="col-12 text-center">
        <h2 class="wow fadeInUp">Benefits for Student community</h2>
        <hr class="devider" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-3 col-lg-3 col-sm-6 my-4 wow fadeInUp">
        <div class="benfits-container">
          <div class="benfits-icon">
            <img src="assets/arthaSiksha/student/icons/skill-gap.svg" alt="" />
          </div>
          <div class="benfits-text">
            Assessments to check current level of proficiency coupled with need
            based learning interventions curated to address specific student
            skill gaps.
          </div>
        </div>
      </div>
      <div class="col-md-3 col-lg-3 col-sm-6 my-4 wow fadeInUp">
        <div class="benfits-container">
          <div class="benfits-icon">
            <img
              src="assets/arthaSiksha/student/icons/practice-assessment.svg"
              alt=""
            />
          </div>
          <div class="benfits-text">
            Practice assessments to check knowledge retention along with
            feedback and corrective course of actions.
          </div>
        </div>
      </div>
      <div class="col-md-3 col-lg-3 col-sm-6 my-4 wow fadeInUp">
        <div class="benfits-container">
          <div class="benfits-icon">
            <img
              src="assets/arthaSiksha/student/icons/methodology.svg"
              alt=""
            />
          </div>
          <div class="benfits-text">
            Blended learning methodology - classroom delivered by industry
            experts; and virtual which enables the learner to pace their
            learning suitable to them.
          </div>
        </div>
      </div>
      <div class="col-md-3 col-lg-3 col-sm-6 my-4 wow fadeInUp">
        <div class="benfits-container">
          <div class="benfits-icon">
            <img src="assets/arthaSiksha/student/icons/Training.svg" alt="" />
          </div>
          <div class="benfits-text">
            On the job trainings to enhance the learner’s knowledge retention.
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
