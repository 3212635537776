import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../authentication/authentication.service';
import { UtilService } from '../util.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    private auth:AuthenticationService,
    private util:UtilService,
    private router:Router
  ) { }

  login(data){
    var key;
    if(data){
      key ="Basic " +  window.btoa(data.email + ":" + data.password);
    }else{
      localStorage.getItem('key') ? key = localStorage.getItem('key'):"";
    }
    this.auth.doLogin("api/get-user",key).subscribe((res)=>{
      if(res.status == 200){
        this.auth.setAdminLoginStatus(true);
        localStorage.setItem("key", key);
        if(this.router.url.startsWith('/admin-login')){
          this.router.navigate(['/admin-panel/contact-details'])
        }
      }else if(res.status == 401){
        localStorage.removeItem('key');
        this.auth.setAdminLoginStatus(false);
        this.util.showResponseModal(true);
      }
    });
  }
}
