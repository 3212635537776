import { Component, OnInit } from '@angular/core';
import { urlPortService } from '../../../services/url-port.service';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.less']
})
export class FooterComponent implements OnInit {
  
  applicationUrl = environment.apiUrl;

  constructor(
    public porturl: urlPortService
  ) { }

  ngOnInit() {
  }

}
