import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router, Data } from "@angular/router";
import { ApiService } from "src/app/services/http/api.service";

@Component({
  selector: "app-academic-registration",
  templateUrl: "./academic-registration.component.html",
  styleUrls: ["./academic-registration.component.less"],
})
export class AcademicRegistrationComponent implements OnInit {
  enrollForm: FormGroup;
  countries: any = [];
  loading: boolean = false;
  status: boolean = false;
  showForm: boolean = true;
  error: boolean = false;
  states: any = [];
  postValue: any = {};
  countriestates: any = [];
  submitPostData: any = [];
  formValue: any = {};
  formstep: number = 0;
  constructor(
    private api: ApiService,
    private router: ActivatedRoute,
    private changePage: Router,
    private http: HttpClient // private AccordianService: AccordianService
  ) {
    // this.formValue = this.AccordianService.getRegis();
    this.router.data.subscribe((data: Data) => {
      this.states = data.statelist;
      this.countries = data.countryList;
    });
  }
  getCountry(country: any) {
    this.stateList(country);
  }
  stateList(id) {
    this.http.get<any>('assets/json/states.json').subscribe(data => {
      this.countriestates = data.states;
    })
    // this.api.getCountyStateList(id).subscribe(data => {
    //   this.countriestates = data.response;
    // });
  }
  countryLists() {
    this.countries = [{ id: 1, countryName: "India" }];
    // this.api.getCountyList().subscribe(data => {
    //   this.countries = data.response;
    // });
  }
  ngOnInit() {
    this.countryLists();
    this.enrollForm = new FormGroup({
      firstName: new FormControl(null, Validators.required),
      lastName: new FormControl(null, Validators.required),
      email: new FormControl(null, [Validators.required, Validators.email]),
      phone: new FormControl(null, [
        Validators.required,
        Validators.pattern("^[0-9]{10}$"),
      ]),

      orgname: new FormControl(null, Validators.required),
      usersNo: new FormControl(null, [
        Validators.required,
        Validators.pattern("^[1-9]{1}[0-9]{0,3}$"),
      ]),
      // 'orgtype': new FormControl('Corporate'),
      acatype: new FormControl("Engineering"),
      // 'gstin': new FormControl(null)

      countryname: new FormControl(null, Validators.required),
      statename: new FormControl(null, Validators.required),
      // 'orgtype': new FormControl('Corporate'),
      pino: new FormControl(null, [
        Validators.required,
        Validators.pattern("^[0-9]{6}$"),
      ]),
      iconfirm: new FormControl(null, Validators.required),
      // 'gstin': new FormControl(null)
    });

    if (this.formValue.userData) {
      this.enrollForm.patchValue({
        phone: this.formValue.userData.phone,
        email: this.formValue.userData.email,
        firstName: this.formValue.userData.name.substr(
          0,
          this.formValue.userData.name.indexOf(" ")
        ),
        lastName: this.formValue.userData.name.substr(
          this.formValue.userData.name.indexOf(" ") + 1
        ),
      });
    }
  }
  formSubmit(submitData) {
    this.formstep = 2;
    this.loading = true;
    this.postValue = {
      email: submitData.email,
      name: submitData.firstName + submitData.lastName,
      organizeInfo: {
        typeOfOrganization: "Academic_Institute",
        orgname: submitData.orgname,
        usersNo: submitData.usersNo,
        academicType: "Engineering",
      },
      phoneNum: submitData.phone,
      userAddress: {
        country: submitData.countryname,
        pincode: submitData.pino,
        state: submitData.statename,
      },
    };
    console.log(this.postValue);
    // this.api.postSubscriptionData(this.postValue).subscribe(
    //   (data: any) => {
    //     console.log(data);
    //     this.submitPostData = data;
    //     this.loading = true;
    //     if (this.submitPostData.message == "Success") {
    //       this.loading = false;

    //       this.enrollForm.reset();
    //       this.showForm = false;
    //       this.status = true;
    //     }
    //     if (this.submitPostData.message == "Already Exist") {
    //       this.showForm = false;
    //       this.loading = false;
    //     }
    //   },
    //   (error) => {
    //     this.loading = false;
    //     this.status = false;

    //     this.error = true;
    //   }
    // );
  }
  fillAgain() {
    window.location.reload();
  }

  checkMobile(event) {
    let element = event.target;
    if (element.value.length > element.maxLength) {
      this.enrollForm.controls[element.name].setValue(
        element.value.slice(0, element.maxLength)
      );
    }
  }

  checkConsent(event) {
    if (event.target.checked) {
      this.enrollForm.controls[event.target.name].setValue("iaccept");
    } else {
      this.enrollForm.controls[event.target.name].setValue(null);
    }
  }
}
