import { Component, OnInit, Inject} from '@angular/core';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.onscroll = function() {myFunction()};
    var header =<HTMLFormElement>document.querySelector(".header");
    var sticky = header.offsetTop;
    var prevScrollpos = window.pageYOffset;

    function myFunction() {
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
        Array.from(header.children).forEach(element=>(
            Array.from(element.children).forEach(
              element=> element.classList.add("sticky")
            )
        ))
      } else {
        header.classList.remove("sticky");
        Array.from(header.children).forEach(element=>(
          Array.from(element.children).forEach(
            element=> element.classList.remove("sticky")
          )
        ))
      }
     
        var currentScrollPos = window.pageYOffset;
        
          if (prevScrollpos > currentScrollPos) {
            document.getElementById("header-container").style.top = "0px";
          } else {
            if (currentScrollPos > 45) {
              document.getElementById("header-container").style.top = "-90px";
            }
          }
       
        prevScrollpos = currentScrollPos;
     


    }
  }

}
