<div class="banner">
  <h2>Student Registration</h2>
</div>
<div class="container">
  <form class="form-sp" *ngIf="enrollForm" [formGroup]="enrollForm" (ngSubmit)="onFromSubmit()">
      <div class="row" *ngIf="formfill">
          <div class="col-md-12 col-sm-12 col-12 form-radio flex-container flex-w-w no-margin no-padding">
              <span class="radio-span">
                  Registration Type
              </span>
              <!-- <div class="radio">
                 <label>
                      <input type="radio" name="package" [value]="'Free'" formControlName="package" />
                      <i class="helper"></i>Free
                  </label>
              </div>  -->
              <div class="radio">
                  <label>
                      <input type="radio" name="package" [value]="'Premium'" formControlName="package" />
                      <i class="helper"></i>Premium
                  </label>
              </div>
          </div>

          <div class="col-md-6 col-sm-6 col-12 form-group">
              <input type="text" name="fname" formControlName="fname" />
              <label for="input" class="control-label">First Name</label>
              <i class="bar"></i>
              <p class="has-error" *ngIf="enrollForm.get('fname').invalid && enrollForm.get('fname').touched">
                  <small>
                      Enter first user eg. Karan
                  </small>
              </p>
          </div>

          <div class="col-md-6 col-sm-6 col-12 form-group">
              <input type="text" name="lname" formControlName="lname" />
              <label for="input" class="control-label">Last Name</label>
              <i class="bar"></i>
              <p class="has-error" *ngIf="enrollForm.get('lname').invalid && enrollForm.get('lname').touched">
                  <small>
                      Enter last name eg. Sharma
                  </small>
              </p>
          </div>

          <div class="col-md-6 col-sm-6 col-12 form-group">
              <input type="date" class="form-control" ngbDatepicker #d="ngbDatepicker" name="dob" min="1970-01-02"
                  formControlName="dob" onkeydown="return false">
              <div class="input-group-append datepickBtn">
                  <button class="" (click)="d.toggle()" type="button">
                      <i class="fa fa-calendar-o" aria-hidden="true"></i>
                  </button>
              </div>

              <label for="input" class="control-label">Date of birth (dd-mm-yyyy)</label>
              <i class="bar"></i>
              <p class="has-error" *ngIf="enrollForm.get('dob').invalid && enrollForm.get('dob').touched">
                  <small>
                      Select valid date of birth
                  </small>
              </p>
          </div>


          <!-- CONTRY SELECTOR -->
          <div class="col-md-6 col-sm-6 col-12 form-group">
              <select name="country" formControlName="country" (change)=getStatesByCountry($event)>
                  <option [ngValue]="null">Select</option>
                  <option *ngFor="let condata of countries" value="{{condata.id}}">{{condata.countryName}}</option>
              </select>
              <label for="select" class="control-label">Select Country</label>
              <i class="bar"></i>
              <p class="has-error" *ngIf="enrollForm.get('country').invalid && enrollForm.get('country').touched">
                  <small>
                      Select a Country
                  </small>
              </p>
          </div>

          <div class="col-md-6 col-sm-6 col-12 form-group">
              <select name="state" formControlName="state">
                  <option [ngValue]="null">Select</option>
                  <option *ngFor="let cdata of states" value="{{cdata.id}}">{{cdata.state}}</option>
              </select>
              <label for="select" class="control-label">Select State</label>
              <i class="bar"></i>
              <p class="has-error" *ngIf="enrollForm.get('state').invalid && enrollForm.get('state').touched">
                  <small>
                      Select a State
                  </small>
              </p>
          </div>

          <!-- <div class="col-md-12 col-sm-12 col-12 form-group">
              <div class="option-skill">
                  <p>
                      <strong>Selected Skill</strong>
                      <br> {{ selectedDesiredSkills }}
                  </p>
              </div>
              <ul class="choicebox" formArrayName="desiredSkill">
                  <li *ngFor="let skill of desiredSkills; let i = index">
                      <input type="checkbox" name="desiredSkill" [formControlName]="i" value="{{skill.id}}"
                          id="ch-{{i}}">
                      <label for="ch-{{i}}" class="checklabel">{{skill.courseName}}</label>
                  </li>
              </ul>

              <ng-template [ngIf]="otherSelected">
                  <div class="input-oc">
                      <b>Course Name</b>
                      <input type="text" (change)="makeOtherSkillData($event)"
                          placeholder="Enter the desired course name e.g. Course1, Course2 etc">
                  </div>
              </ng-template>

              <label class="control-label">Select Desired Skills</label>
              <p class="has-error"
                  *ngIf="enrollForm.get('desiredSkill').invalid && enrollForm.get('desiredSkill').touched">
                  <small>
                      Select atleast one desired skill
                  </small>
              </p>
          </div> -->


          <!-- <div class="col-md-6 col-sm-6 col-12 form-group">
              <select class="multipleSelect" name="desiredSkill" formControlName="desiredSkill" multiple>
                  <option *ngFor="let skill of desiredSkills" value="{{skill.id}}">{{skill.courseName}}</option>
              </select>
              <i class="bar"></i>
              <p class="has-error" *ngIf="enrollForm.get('desiredSkill').invalid && enrollForm.get('desiredSkill').touched">
                  <small>
                      Select atleast one desired skill
                  </small>
              </p>
          </div> -->

          <div class="col-md-6 col-sm-6 col-12 form-radio">
              <span class="radio-span">
                  Gender
              </span>
              <div class="radio">
                  <label>
                      <input type="radio" name="gender" [value]="'Male'" formControlName="gender" />
                      <i class="helper"></i>Male
                  </label>
              </div>
              <div class="radio">
                  <label>
                      <input type="radio" name="gender" [value]="'Female'" formControlName="gender" />
                      <i class="helper"></i>Female
                  </label>
              </div>
          </div>

          <div class="col-md-6 col-sm-6 col-12 form-group">
              <input type="number" name="mobile" formControlName="mobile" (input)="checkMobile($event)"
                  maxlength="10" />
              <label for="input" class="control-label">Mobile No.</label>
              <i class="bar"></i>
              <p class="has-error" *ngIf="enrollForm.get('mobile').invalid && enrollForm.get('mobile').touched">
                  <small>
                      Enter valid mobile number eg. 9999888877
                  </small>
              </p>
          </div>

          <div class="col-md-6 col-sm-6 col-12 form-group">
              <input type="email" name="email" formControlName="email" />
              <label for="email" class="control-label">Email</label>
              <i class="bar"></i>
              <p class="has-error" *ngIf="enrollForm.get('email').invalid && enrollForm.get('email').touched">
                  <small>
                      Enter valid email address eg. useremail@example.com
                  </small>
              </p>
          </div>

          <div class="col-md-6 col-sm-6 col-12 form-group">
              <input type="password" name="pass" formControlName="pass" />
              <label for="password" class="control-label">Password</label>
              <i class="bar"></i>
              <p class="has-error" *ngIf="enrollForm.get('pass').invalid && enrollForm.get('pass').touched">
                  <small>
                      The password must contain atleast 8 chatacters eg. User@1234
                  </small>
              </p>
          </div>

          <div class="col-md-6 col-sm-6 col-12 form-group">
              <input type="password" name="cpass" formControlName="cpass" appConfirmEqualValidator="pass" />
              <label for="password" class="control-label">Confirm Password</label>
              <i class="bar"></i>
              <p class="has-error" *ngIf="enrollForm.get('cpass').invalid && enrollForm.get('cpass').touched">
                  <small>
                      Enter matching password
                  </small>
              </p>
          </div>

          <div class="col-md-12 col-sm-12 col-12 checkbox">
              <label>
                  <input type="checkbox" name="iconfirm" formControlName="iconfirm"
                      (change)="checkConsent($event)" /><i class="helper"></i>I here by agree
                  to provide my consent to use my details for the registration. 
                  <!-- <br>
                  <a href="" data-toggle="modal" data-target="#modelId">Click here</a> -->
              </label>
          </div>

          <div class="col-md-12 col-sm-12 col-12">
              <button type="submit" class="button button-s"
                  [ngClass]="(!enrollForm.valid)?'disabled':''"
                  [disabled]="(!enrollForm.valid)">Submit</button>
          </div>
      </div>
      <div class="dataOutput flex-container flex-d-c flex-pc" *ngIf="!formfill">
          <div class="status flex-container flex-w-w flex-pc flex-d-c">
              <div class="loading flex-container flex-w-w flex-pc flex-d-c" *ngIf="loader==true; else loadingdone">
                  <div class="text-center">
                      <i class="fa fa-circle-o-notch fa-spin fa-5x fa-fw"></i>
                  </div>
                  <div class="space-30"></div>
                  <h3>Please Wait</h3>
              </div>
              <ng-template #loadingdone>
                  <div *ngIf="status==true; else undone" class="done">
                      <div *ngIf="responseStatus.status=='Success'" class="flex-container flex-w-w flex-pc flex-d-c">
                          <img src="/assets/images/icons/tickicon.png" alt="Info-icons" class="img-fluid">
                          <div class="space-30"></div>
                          <h4>Success</h4>
                          <h6>
                              Thank you for registration, your NexGen LMS Id:
                              <b>{{responseStatus.response.skillPassportId}}</b>.
                              <br> Please go to your email for further steps.
                          </h6>
                      </div>

                      <div *ngIf="responseStatus.status=='Failed'" class="flex-container flex-w-w flex-pc flex-d-c">
                          <img src="/assets/images/icons/crossicon.png" alt="Info-icons" class="img-fluid">
                          <div class="space-30"></div>
                          <h4>User Already Exist</h4>
                          <p>Please try again with different user email</p>
                          <a class="button button-s" (click)="fillAgain()">Fill Again</a>
                      </div>
                  </div>
                  <ng-template class="undone flex-container flex-w-w flex-pc flex-d-c" #undone>
                      <img src="/assets/images/icons/crossicon.png" alt="Info-icons" class="img-fluid">
                      <div class="space-30"></div>
                      <h4>Something went wrong</h4>
                      <p>please refresh the page and try again</p>
                      <a class="button button-s" (click)="fillAgain()">Refresh</a>
                  </ng-template>
              </ng-template>
          </div>
      </div>
  </form>
</div>

<!-- Modal -->
<div class="modal fade" id="modelId" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
  <div class="modal-dialog" role="document">
      <div class="modal-content">
          <div class="modal-header">
              <h4 class="modal-title" id="modelTitleId">Terms and Conditions</h4>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
              </button>
          </div>
          <div class="modal-body">
              <p>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Unde omnis aliquid labore voluptatibus,
                  pariatur quae odit itaque
                  nobis cum consequuntur? Asperiores dolores laborum magnam esse illo rem ipsum, temporibus corrupti!
                  Lorem
                  ipsum, dolor sit amet consectetur adipisicing elit. Unde omnis aliquid labore voluptatibus, pariatur
                  <br> quae odit itaque nobis cum consequuntur? Asperiores dolores laborum magnam esse illo rem ipsum,
                  temporibus corrupti! Lorem ipsum, dolor sit amet consectetur adipisicing elit. Unde omnis aliquid
                  labore
                  voluptatibus, pariatur quae odit itaque nobis cum consequuntur? Asperiores dolores laborum magnam
                  esse
                  illo rem ipsum, temporibus corrupti! Lorem ipsum, dolor sit amet consectetur adipisicing elit. Unde
                  omnis
                  aliquid labore
                  <br> voluptatibus, pariatur quae odit itaque nobis cum consequuntur? Asperiores dolores laborum
                  magnam
                  esse illo rem ipsum, temporibus corrupti! Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Unde
                  omnis aliquid labore voluptatibus, pariatur quae odit itaque nobis cum consequuntur? Asperiores
                  dolores
                  laborum
                  <br> magnam esse illo rem ipsum, temporibus corrupti! Lorem ipsum, dolor sit amet consectetur
                  adipisicing
                  elit. Unde omnis aliquid labore voluptatibus, pariatur quae odit itaque nobis cum consequuntur?
                  Asperiores
                  dolores laborum magnam esse illo rem ipsum, temporibus corrupti! Lorem ipsum, dolor sit amet
                  consectetur
                  <br> adipisicing elit. Unde omnis aliquid labore voluptatibus, pariatur quae odit itaque nobis cum
                  consequuntur?
                  Asperiores dolores laborum magnam esse illo rem ipsum, temporibus corrupti! Lorem ipsum, dolor sit
                  amet
                  consectetur adipisicing elit. Unde omnis aliquid labore voluptatibus, pariatur quae odit itaque
                  nobis
                  cum consequuntur? Asperiores dolores laborum magnam esse illo rem ipsum, temporibus
                  <br> corrupti! Lorem ipsum, dolor sit amet consectetur adipisicing elit. Unde omnis aliquid labore
                  voluptatibus,
                  pariatur quae odit itaque nobis cum consequuntur? Asperiores dolores laborum magnam esse illo rem
                  ipsum,
                  temporibus corrupti!
              </p>
          </div>
          <div class="modal-footer flex-jc-fs">
              <button type="button" class=" button button-s" data-dismiss="modal">Close</button>
          </div>
      </div>
  </div>
</div>

<div class="modal fade" id="addmorec" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
  <div class="modal-dialog" role="document">
      <div class="modal-content">
          <div class="modal-header">
              <h4 class="modal-title" id="modelTitleId">Terms and Conditions</h4>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
              </button>
          </div>
          <div class="modal-body">
              <h2>Its working</h2>
          </div>
          <div class="modal-footer flex-jc-fs">
              <button type="button" class=" button button-s" data-dismiss="modal">Close</button>
          </div>
      </div>
  </div>
</div>