import { HttpClient, HttpHeaders } from "@angular/common/http";
import { WrappedNodeExpr } from "@angular/compiler";
import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Subject } from "rxjs";
import { LoginService } from "../login/login.service";

@Injectable({
    providedIn: "root",
  })
export class AuthenticationService{
    private loginStatus:boolean = false;
    adminLoginStatus = new Subject<boolean>();


    constructor(
        private http:HttpClient
    ){
        
    }
    getLoginStatus(){
        return this.loginStatus;
    }

    setAdminLoginStatus(status:boolean){
        this.adminLoginStatus.next(status);
    }
    getAdminLoginStatus(){
       return this.adminLoginStatus;
    }
    doLogin(url,key){
        // this.loginStatus = true;
        return this.http.get(url,{
            headers:new HttpHeaders({
              'Authorization': key
            }),
            observe: 'response' as 'response',
            withCredentials: true
          });
    }

    doLogout(){
        this.adminLoginStatus.next(false);
        localStorage.removeItem('key');
    }

    isAuthenticated(){
        const promise = new Promise(
            (resolve, reject) => {
                setTimeout( () => resolve(this.loginStatus),500 );
            }
        );
        return promise;
    }
}