<div class="carousel carousel2">
  <div class="slider-box">
    <div class="row">
      <div *ngFor="let pdata of items" class="slider col-md-6 col-sm-6 col-12">
        <h3 class="heading">{{pdata.title}}</h3>
        <ul class="data-list">
          <li *ngFor="let data of pdata.course">
            {{data}}
          </li>
        </ul>
      </div>
    </div>
    <div class="control left"><i class="fa fa-angle-left" aria-hidden="true"></i></div>
    <div class="control right"><i class="fa fa-angle-right" aria-hidden="true"></i></div>
  </div>
</div>