<div class="burger-icon" id="nav-icon1">
  <span></span>
  <span></span>
  <span></span>
</div>
<div class="header nav-menu header-sp container-fluid">
  <nav class="navbar navbar-expand-sm">
    <ul class="navbar-nav">
      <!-- <li class="nav-item dropdown add-dropdown">
        <a class="nav-link dropdown-toggle" routerLink="#" data-toggle="dropdown">
          Course Offering
        </a>
        <div class="dropdown-menu">
          <a class="float-left active" data-screen="1">
            <i class="fa fa-caret-left float-right"></i>
            Rite Erp
          </a>
          <a class="float-left" data-screen="2">
            <i class="fa fa-caret-left float-right"></i>
            Rite Campus
          </a>
          <a class="float-left" data-screen="3">
            <i class="fa fa-caret-left float-right"></i>
            Easy Ship
          </a>
          <a class="float-left" data-screen="4">
            <i class="fa fa-caret-left float-right"></i>
            Skill Passport
          </a>
          <ul class="list-group list-item-screen">
              <div class="list-item-screen-1">
                <h2>Rite Erp</h2>
              </div>
              <div class="list-item-screen-2 d-none">
                <h2>Rite Campus</h2>
              </div>
              <div class="list-item-screen-3 d-none">
                <h2>Rite Ship</h2>
              </div>
              <div class="list-item-screen-4 d-none">
                <h2>Rite Passport</h2>
            </div> 
          </ul>
        </div>
      </li> -->


      <li routerLinkActive="active" routerLink="/" [routerLinkActiveOptions]="{exact: true}" class="nav-item">
        <a href="/" title="home" class="nav-link">
          <!-- <i class="fa fa-home fa-fw" aria-hidden="true"></i> -->
          Home
        </a>
      </li>
      <li *ngIf="loginStatus" routerLinkActive="active" class="nav-item">
        <a href="/dashboard" class="nav-link">
          Dashboard
        </a>
      </li>
      <li class="nav-item dropdown-box" routerLinkActive="active">
        <a class="have-dropdown nav-link">
          <i class="fa fa-caret-down fa-fw" aria-hidden="true"></i>
          FOR
        </a>
        <div class="drop">
          <ul class="menu-list">
            <li class="menu">
              <a class="flex-container flex-d-c flex-w-w flex-pc " routerLink="student" aria-label="Individual Learner"
                routerLinkActive="active">
                <img src="assets/arthaSiksha/icons/students.png" class="menu-img" alt="menu-image">
                <span>
                  Students
                </span>
              </a>
            </li>
            <li class="menu">
              <a class="flex-container flex-d-c flex-w-w flex-pc " routerLink="professionals" aria-label="Individual Learner"
                routerLinkActive="active">
                <img src="assets/arthaSiksha/icons/professional.png" class="menu-img" alt="menu-image">
                <span>
                  Professionals
                </span>
              </a>
            </li>
            <li class="menu">
              <a class="flex-container flex-d-c flex-w-w flex-pc" routerLink="academic" aria-label="Academic Institution"
                routerLinkActive="active">
                <img src="assets/arthaSiksha/icons/institution.png" class="menu-img" alt="menu-image">
                <span>
                  Academic Institutions
                </span>
              </a>
            </li>
            <li class="menu">
              <a class="flex-container flex-d-c flex-w-w  flex-pc" routerLink="organization" aria-label="Organization"
                routerLinkActive="active">
                <img src="assets/arthaSiksha/icons/organization.png" class="menu-img" alt="menu-image">
                <span>
                  Organizations
                </span>
              </a>
            </li>
            <!-- <li class="menu">
              <a class="flex-container flex-d-c flex-w-w flex-pc" routerLink="studentRegister-enroll" title="studentRegister" routerLinkActive="active">
                <img src="/assets/images/icons/menu5.png" class="menu-img" alt="menu-image">
                <span>
                 Student Register
                </span>
              </a>
            </li> -->
            <!-- <li class="menu">
                <a class="flex-container flex-d-c flex-w-w flex-pc" routerLink="vendor-enroll" title="studentRegister" routerLinkActive="active">
                  <img src="/assets/images/icons/menu4.png" class="menu-img" alt="menu-image">
                  <span>
                   Vendor Creation
                  </span>
                </a>
              </li> -->
          </ul>
        </div>
      </li>
      <!-- <li routerLinkActive="active" class="nav-item"><a routerLink="courses" class="nav-link" title="courses">Course
          Offerings</a></li> -->
          <li routerLinkActive="active" class="nav-item"><a routerLink="about-us" class="nav-link" title="About us">ABOUT US</a></li>
      <!-- <li class="nav-item dropdown-box" routerLinkActive="active">
        <a class="have-dropdown nav-link">
          <i class="fa fa-caret-down fa-fw" aria-hidden="true"></i>
          ABOUT US
        </a>
        <div class="drop">
          <ul class="menu-list">
            <li class="menu">
              <a class="flex-container flex-d-c flex-w-w  " (click)="toAboutUs('about-us')" routerLink="about-us"
                aria-label="About Artha Siksha">
                <span>
                  About Artha Siksha
                </span>
              </a>
            </li>
            <li class="menu">
              <a class="flex-container flex-d-c flex-w-w " (click)="toAboutUs('skilling')" routerLink="about-us"
                aria-label="Skilling through Artha Siksha ">
                <span>
                  Skilling
                </span>
              </a>
            </li>
            <li class="menu">
              <a class="flex-container flex-d-c flex-w-w " (click)="toAboutUs('re-skilling')" routerLink="about-us"
                aria-label="Reskilling through Artha Siksha">
                <span>
                  Reskilling
                </span>
              </a>
            </li>
            <li class="menu">
              <a class="flex-container flex-d-c flex-w-w " (click)="toAboutUs('team')" routerLink="about-us"
                aria-label="Management Team">
                <span>
                  Management Team
                </span>
              </a>
            </li>
          </ul>
        </div>
      </li> -->
      <!-- <li routerLinkActive="active" class="nav-item"><a routerLink="blogs" class="nav-link" title="Blogs">BLOGS</a></li> -->
      <!-- <li routerLinkActive="active" class="nav-item"><a routerLink="faq" class="nav-link" title="faq">FAQ</a></li> -->
      <li  class="nav-item active button-header" style="cursor: pointer;"><a class="nav-link" data-toggle="modal" data-target="#exampleModalCenter">Start Trial</a></li>
      <li  class="nav-item active button-header" style="cursor: pointer;"><a class="nav-link"
        >Login</a></li>
        <li routerLinkActive="active" class="nav-item"><a routerLink="contact-us" class="nav-link"
            title="contact us">Contact Us</a></li>

      

    </ul>
  </nav>
</div>


