import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { urlPortService } from '../../services/url-port.service';
import { DmService } from '../../dmarketing/dm.service';

@Component({
  selector: 'app-coueses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.less']
})
export class CoursesComponent implements OnInit {
  currentsection: string;

  constructor(
    private route: ActivatedRoute,
    public porturl: urlPortService,
    private dmdata: DmService
  ) {
    this.dmdata.setDmTags('courses');
  }
  ngOnInit() {
    this.currentsection = this.route.snapshot.params["section"];
  }
}
