import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BlogsService, BlogStruct } from 'src/app/services/blogs.service';

@Component({
  selector: 'app-blog-card',
  templateUrl: './blog-card.component.html',
  styleUrls: ['./blog-card.component.less']
})
export class BlogCardComponent implements OnInit {

  blogList;
  constructor(
    private router:Router,
    private blogs:BlogsService,
    private http:HttpClient
  ) { }

  ngOnInit() {
    this.http.get<BlogStruct[]>('assets/json/blogs.json').subscribe(data => {
      this.blogList = data;
      this.blogList.sort(this.blogs.blogCompare)
    })
  }
  toIndividualBlog(id) {
    this.router.navigate(['/blog'], { queryParams: { blog: id } })
  }

}
