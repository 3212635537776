<section id="faq">
  <div class="container py-5">
    <div class="row">
      <div class="col-md-12 text-center mb-4">
        <h2>FAQ's</h2>
      </div>
      <div class="col-md-12">
        <div class="faq-content">
          <div class="faq-questions" id="accordion">
            <div class="faq-quest" *ngFor="let faq of faqList;let i = index">
              <h5><a data-toggle="collapse" [href]="'#faq'+i" role="button" aria-expanded="false"
                  aria-controls="collapseExample">
                  <span [innerHTML]="faq.question">
                  </span>
                  <i class="fa fa-plus" aria-hidden="true"></i>
                </a>
              </h5>
              <div class="collapse" [id]="'faq'+i" data-parent="#accordion">
                <div class="card card-body">
                  <p class="m-0" [innerHTML]="faq.description"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>