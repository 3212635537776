import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "app-start-trial",
  templateUrl: "./start-trial.component.html",
  styleUrls: ["./start-trial.component.less"],
})
export class StartTrialComponent implements OnInit {
  subscriptionFrom: FormGroup;
  constructor() {}

  ngOnInit() {
    this.subscriptionFrom = new FormGroup({
      regType: new FormControl('', Validators.required),
      name: new FormControl(null, [Validators.required,Validators.pattern('[a-zA-Z0-9]+(.*)')]),
      email: new FormControl(null, [Validators.required, Validators.email]),
      phone: new FormControl(null, [
        Validators.required,
        Validators.pattern("^[a-z0-9_-]{8,15}$"),
      ]),
    });
  }
  onFormSubmit(formValue) {
  }
 
}
