<table id="example" class="table table-striped table-bordered" style="width:100%">
  <thead>
      <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Mobile</th>
          <th>Message</th>
      </tr>
  </thead>
  <tbody>
      <tr *ngFor="let item of contactDetails">
        <td>{{item.name}}</td>
        <td>{{item.email}}</td>
        <td>{{item.mobile}}</td>
        <td>{{item.message}}</td>
      </tr>
  </tbody>
</table>