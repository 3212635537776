import { Title, Meta } from '@angular/platform-browser';
import { Injectable } from '@angular/core';

@Injectable()
export class DmService {
    private dmData = {
        "index": {
            "title": "Artha Siksha",
            "description": "Artha Siksha is the gateway to a career that every student aspires for. It’s an intuitive digital platform that leverages result driven assessment and sustainable learning. Recognizing the apparent gap between the requisite skills and the existing competencies, Artha Siksha bridges the disparity. ",
            "keywords": "Skillpasspport, skpass, education website, career course, careers choice, career shoot, career build, career platform, get placed, placement software, placement website, get a job, get jobs, get a job, secure career, my career choice, my career, future plans, education skills, training platform, training skills",
            "og": {
                "title": "Artha Siksha | Home",
                "creater": "@author_name",
                "card": "product",
                "site": "@publisher_handle",
                "type": "type1",
                "url": "http://www.skillpassport.in",
                "image": "/assets/images/applicationLogos/SBuS.png",
                "description": "Artha Siksha is the gateway to a career that every student aspires for. It’s an intuitive digital platform that leverages result driven assessment and sustainable learning. Recognizing the apparent gap between the requisite skills and the existing competencies, Artha Siksha bridges the disparity. ",
            }
        },
        "individual": {
            "title": "Individual",
            "description": "Perfect platform to assess and well as possess skill will certainly lead an individual to Artha Siksha.",
            "keywords": "Skillpasspport, skpass, education website, career course, careers choice, career shoot, career build, career platform, get placed, placement software, placement website, get a job, get jobs, get a job, secure career, my career choice, my career, future plans, education skills, training platform, training skills, individual training, individual skill",
            "og": {
                "title": "Individual service",
                "creater": "@author_name123",
                "card": "anything",
                "site": "@publisher_handle1",
                "type": "type1",
                "url": "http://www.skillpassport.in",
                "image": "/assets/images/applicationLogos/SBuS.png",
                "description": "Perfect platform to assess and well as possess skill will certainly lead an individual to Artha Siksha.",
            }
        },
        "organization": {
            "title": "Organization",
            "description": "Artha Siksha is a boon for such experts as it works as a collaborator as well as a marketplace bringing the learner and the facilitator together into one platform.",
            "keywords": "Skillpasspport, skpass, education website, career course, careers choice, career shoot, career build, career platform, get placed, placement software, placement website, get a job, get jobs, get a job, secure career, my career choice, my career, future plans, education skills, training platform, training skills, orgainzation training, orgainzation skill",
            "og": {
                "title": "Organization Service",
                "creater": "@author_name",
                "card": "product",
                "site": "@publisher_handle",
                "type": "type1",
                "url": "http://www.skillpassport.in",
                "image": "/assets/images/applicationLogos/SBuS.png",
                "description": "Artha Siksha is a boon for such experts as it works as a collaborator as well as a marketplace bringing the learner and the facilitator together into one platform."
            }
        },
        "trainer": {
            "title": "Trainer",
            "description": "Artha Siksha is a boon for such experts as it works as a collaborator as well as a marketplace bringing the learner and the facilitator together into one platform. ",
            "keywords": "Skillpasspport, skpass, education website, career course, careers choice, career shoot, career build, career platform, get placed, placement software, placement website, get a job, get jobs, get a job, secure career, my career choice, my career, future plans, education skills, training platform, training skills, trainer training, trainer skill",
            "og": {
                "title": "Trainer Service",
                "creater": "@author_name",
                "card": "product",
                "site": "@publisher_handle",
                "type": "type1",
                "url": "http://www.skillpassport.in",
                "image": "/assets/images/applicationLogos/SBuS.png",
                "description": "Artha Siksha is a boon for such experts as it works as a collaborator as well as a marketplace bringing the learner and the facilitator together into one platform. ",
            }
        },
        "corporate": {
            "title": "Corporate",
            "description": "Artha Siksha simplifies and brings a complete overhaul of the hiring process for an Corporate. ",
            "keywords": "Skill passpport®, skpass, education website, career course, careers choice, career shoot, career build, career platform, get placed, placement software, placement website, get a job, get jobs, get a job, secure career, my career choice, my career, future plans, education skills, training platform, training skills, corporate training, corporate skill",
            "og": {
                "title": "Corporate Service",
                "creater": "@authorname123",
                "card": "product",
                "site": "@publisher_handle",
                "type": "type2",
                "url": "http://www.skillpassport.in",
                "image": "/assets/images/applicationLogos/SBuS.png",
                "description": " Artha Siksha simplifies and brings a complete overhaul of the hiring process for an Corporate. "
            }
        },
        "studentenroll": {
            "title": "Student Enrollment",
            "description": "Perfect platform to assess and well as possess skill will certainly lead an individual to Artha Siksha.",
            "keywords": "Skillpasspport, skpass, education website, career course, careers choice, career shoot, career build, career platform, get placed, placement software, placement website, get a job, get jobs, get a job, secure career, my career choice, my career, future plans, education skills, training platform, training skills, individual training, individual skill",
            "og": {
                "title": "Student Enrollment",
                "creater": "@author_name",
                "card": "product",
                "site": "@publisher_handle",
                "type": "type1",
                "url": "http://www.skillpassport.in",
                "image": "/assets/images/applicationLogos/SBuS.png",
                "description": "Perfect platform to assess and well as possess skill will certainly lead an individual to Artha Siksha.",
            }
        },
        "trainerenroll": {
            "title": "Trainer Enrollment",
            "description": "Artha Siksha is a boon for such experts as it works as a collaborator as well as a marketplace bringing the learner and the facilitator together into one platform. ",
            "keywords": "Skillpasspport, skpass, education website, career course, careers choice, career shoot, career build, career platform, get placed, placement software, placement website, get a job, get jobs, get a job, secure career, my career choice, my career, future plans, education skills, training platform, training skills, trainer training, trainer skill",
            "og": {
                "title": "Trainer Enrollment",
                "creater": "@author_name",
                "card": "product",
                "site": "@publisher_handle",
                "type": "type1",
                "url": "http://www.skillpassport.in",
                "image": "/assets/images/applicationLogos/SBuS.png",
                "description": "Artha Siksha is a boon for such experts as it works as a collaborator as well as a marketplace bringing the learner and the facilitator together into one platform. ",
            }
        },
        "corporateenroll": {
            "title": "Corporate Enrollment",
            "description": "Artha Siksha simplifies and brings a complete overhaul of the hiring process for an Corporate. ",
            "keywords": "Skill Passpport®, skpass, education website, career course, careers choice, career shoot, career build, career platform, get placed, placement software, placement website, get a job, get jobs, get a job, secure career, my career choice, my career, future plans, education skills, training platform, training skills, corporate training, corporate skill",
            "og": {
                "title": "Corporate Enrollment",
                "creater": "@author_name",
                "card": "product",
                "site": "@publisher_handle",
                "type": "type1",
                "url": "http://www.skillpassport.in",
                "image": "/assets/images/applicationLogos/SBuS.png",
                "description": "Artha Siksha simplifies and brings a complete overhaul of the hiring process for an Corporate. ",
            }
        },
        "courses": {
            "title": "Courses offered",
            "description": "Various courses offered by Artha Siksha for the career development and improve domain, life, congnitive and Employability skills.",
            "keywords": "Skillpasspport, skpass, education website, career course, careers choice, career shoot, career build, career platform, get placed, placement software, placement website, get a job, get jobs, get a job, secure career, my career choice, my career, future plans, education skills, training platform, skills offered, courses offered, our plans, educational plans",
            "og": {
                "title": "Courses offered",
                "creater": "@author_name",
                "card": "product",
                "site": "@publisher_handle",
                "type": "type1",
                "url": "http://www.skillpassport.in",
                "image": "/assets/images/applicationLogos/SBuS.png",
                "description": "Various courses offered by Artha Siksha for the career development and improve domain, life, congnitive and Employability skills.",
            }
        },
        "support": {
            "title": "Artha Siksha | Support",
            "description": "FAQ related to Artha Siksha technology for all kind of users.",
            "keywords": "Skillpasspport, skpass, education website, career course, careers choice, career shoot, career build, career platform, get placed, placement software, placement website, get a job, get jobs, get a job, secure career, my career choice, my career, future plans, education skills, training platform, faq, questions answers, frequently asked questions",
            "og": {
                "title": "title1",
                "creater": "@author_name",
                "card": "product",
                "site": "@publisher_handle",
                "type": "type1",
                "url": "http://www.skillpassport.in",
                "image": "/assets/images/applicationLogos/SBuS.png",
                "description": "FAQ related to Artha Siksha technology for all kind of users.",
            }
        }
    };

    constructor(private title: Title, private meta: Meta) { }

    setDmTags(path: string) {
        this.title.setTitle(this.dmData[path]['title']);
        this.meta.updateTag({
            name: "description",
            content: this.dmData[path]['description']
        });
        this.meta.updateTag({
            name: "keywords",
            content: this.dmData[path]['keywords']
        });

        // Twitter
        this.meta.updateTag({
            name: "twitter:card",
            content: this.dmData[path]['og']['card']
        });
        this.meta.updateTag({
            name: "twitter:site",
            content: this.dmData[path]['og']['site']
        });
        this.meta.updateTag({
            name: "twitter:title",
            content: this.dmData[path]['og']['title']
        });
        this.meta.updateTag({
            name: "twitter:description",
            content: this.dmData[path]['og']['title']
        });
        this.meta.updateTag({
            name: "twitter:creator",
            content: this.dmData[path]['og']['creater']
        });
        this.meta.updateTag({
            name: "twitter:image",
            content: this.dmData[path]['og']['image']
        });

        // OpenGraph data
        this.meta.updateTag({
            property: "og:title",
            content: this.dmData[path]['og']['title']
        });
        this.meta.updateTag({
            property: "og:type",
            content: this.dmData[path]['og']['type']
        });
        this.meta.updateTag({
            property: "og:url",
            content: this.dmData[path]['og']['url']
        });
        this.meta.updateTag({
            property: "og:image",
            content: this.dmData[path]['og']['image']
        });
        this.meta.updateTag({
            property: "og:description",
            content: this.dmData[path]['og']['description']
        });
    }
}
