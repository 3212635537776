export class CheckoutDetailsModel {
    constructor(
        public firstname: string,
        public lastname: string,
        public organization: string,
        public email: string,
        public phone: string,
        public city:string,
        public state: string,
        public amount: string,
        public message: string,
        public productinfo: string,
        public returnurl: string
    ){}
}