<div class="container">
  <nav class="topbar topbar-sp">
    <ul class="links">
      <li *ngIf="loginStatus"><a class="link">{{currentTime}}</a></li>
      <!-- this.porturl.getUrl(1) -->
      <li *ngIf="!loginStatus"><a href="{{applicationUrl}}/trainer-login" target="_blank" class="link" aria-haspopup="false" title="trainer-connect"> Trainer Connect</a></li>
      <li *ngIf="!loginStatus"><a href="{{applicationUrl}}/corporate-login" target="_blank" class="link" aria-haspopup="false" title="corporate-connect">Corporate Connect</a></li>
      <li *ngIf="!loginStatus"><a href="{{applicationUrl}}/" target="_blank" class="link" aria-haspopup="false" title="Student-login">Student Login</a></li>
      <li *ngIf="!loginStatus"><a href="{{applicationUrl}}/admin-login" target="_blank" class="link" aria-haspopup="false" title="Organization-login">Organization Login</a></li>
      
      <!-- <li *ngIf="!loginStatus"><a  (click)="login()" class="link">Dashboard Login</a></li> -->
      <!-- <li *ngIf="!loginStatus" class="login-container">
        <a class="link"> Login
          <div class="login-box">
              <app-login1 class="login-form-box"></app-login1>
          </div>
        </a>
      </li> -->
      <!-- <li *ngIf="loginStatus"><a [routerLink]="['/']" (click)="logout()" class="link"> Logout</a></li> -->
    </ul>
  </nav>
</div>