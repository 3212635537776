import { Component, OnInit } from "@angular/core";
import { ApiService } from "src/app/services/http/api.service";

declare var $: any;
@Component({
  selector: "app-admin-user-query",
  templateUrl: "./admin-user-query.component.html",
  styleUrls: ["./admin-user-query.component.less"],
})
export class AdminUserQueryComponent implements OnInit {
  totalUserQueryDetails: any = [];
  userQueryDetails: any = [];
  userType = "STUDENT";

  isUserTypeChanged = true;
  constructor(private api: ApiService) {}

  ngOnInit() {
    this.api.getUserQuery().subscribe(
      (res) => {
        this.totalUserQueryDetails = res.body;
        this.userQueryDetails = this.userQueryFilter(this.userType);
        // this.userQueryDetails  = res;
        if (this.userQueryDetails) {
          $(document).ready(function () {
            $("#student").DataTable();
          });
        }
      },
      (err) => {}
    );
  }
  onUserTypeChange() {
    this.isUserTypeChanged = false;
    this.userQueryDetails = this.userQueryFilter(this.userType);
    this.isUserTypeChanged = true;
    if (this.userQueryDetails) {
      $(document).ready(function () {
        $("#student").DataTable();
        $("#professional").DataTable();
        $("#academic").DataTable();
        $("#organization").DataTable();


        // switch (this.userType) {
        //   case "STUDENT":
        //     $("#student").DataTable();
        //     $("#professional").destroy();
        //     $("#academic").destroy();
        //     $("#organization").destroy();
        //     break;
        //   case "PROFESSIONAL":
        //     $("#student").destroy();
        //     $("#professional").DataTable();
        //     $("#academic").destroy();
        //     $("#organization").destroy();
        //     break;
        //   case "ACADEMIC":
        //     $("#student").destroy();
        //     $("#professional").destroy();
        //     $("#academic").DataTable();
        //     $("#organization").destroy();
        //     break;
        //   case "ORGANIZATION":
        //     $("#student").destroy();
        //     $("#professional").destroy();
        //     $("#academic").destroy();
        //     $("#organization").DataTable();
        //     break;
        // }
      });
    }
  }
  userQueryFilter(userType: string) {
    return this.totalUserQueryDetails.filter((item) => {
      return item.userType == userType;
    });
  }
}
