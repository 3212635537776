import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BlogsService implements OnInit {

  blogs = [];
  sortedBlogs = [];
  constructor(
    private http:HttpClient
  ) { 
    this.setBlogsData();
  }
  ngOnInit(): void {
    
  }
  getBlogsData(){
    return this.blogs;
  }
  setBlogsData(){
    this.http.get<BlogStruct[]>('assets/json/blogs.json').subscribe(data => {
      this.blogs = data;
      this.blogs.sort(this.blogCompare)
    })
  }
  blogCompare(a, b) {
    let comparision = 0;
    let blogA_date = new Date(a.date);
    let blogB_date = new Date(b.date);
    if (blogA_date < blogB_date) {
      comparision = 1;
    } else if (blogA_date > blogB_date) {
      comparision = -1;
    }
    return comparision;
  }
  getBlogById(id) {
    this.blogs.forEach(blog => {
      if (blog.id = id) {
        return blog;
      }
    });
  }

}
export interface BlogStruct {
  id: number;
  title: string;
  author: string;
  imgSrc: string;
  thumbnail: string;
  date: string;
  disclaimer: string;
  content: [
    {
      heading: string;
      data: string;
    }
  ];
}