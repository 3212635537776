<div class="bg-white container-fluid blog-list">
  <div class="row py-3 py-md-5" style="flex-direction: row-reverse;">
      <div class="col-12 py-3 col-sm-12 col-md-3" >
          <app-blog-sidebar></app-blog-sidebar>
      </div>
      <div class="col-12 col-sm-12 col-md-9" style="border-right: 1px solid #dedede;">
          <div class="row my-3" *ngFor="let blog of blogList">
              <div class="col-12 col-md-3 d-md-block d-none">
                  <div class="blog-thumbnail wow fadeInUp" [ngStyle]='{"background-image": "url("+blog.thumbnail+")"}'>
                    <!-- <img src="{{blog.thumbnail}}" alt="" class="img-fluid"> -->
                  </div>
              </div>
              <div class="col-12 col-md-9">
                  <div class="blog-header wow fadeInUp" (click)="toIndividualBlog(blog.id)">
                      {{blog.title}}
                  </div>
                  <div class="blog-text wow fadeInUp">
                      {{blog.content[0].data.slice(0,200)+"..."}}
                  </div>
                  <div class="blog-author wow fadeInUp">
                      <b>By</b> <i class="ml-2" style="color: #5e5e5e;">{{"--"+blog.author}}</i>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>