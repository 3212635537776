import { Component, OnInit } from '@angular/core';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-response',
  templateUrl: './response.component.html',
  styleUrls: ['./response.component.less']
})
export class ResponseComponent implements OnInit {

  status:boolean; 
  constructor(
    private util: UtilService
  ) { 
    util.status.subscribe(res=>{
      this.status = res;
    })
  }
  ngOnInit() {
  }

}
