import { Injectable } from "@angular/core";

@Injectable()
export class AccordianService {
    private coursesData = [
        {
            "id": 1,
            "name": "GST Practitioner Expert",
            "imgSrc": "assets/arthaSiksha/courses/new/gst_banner.jpg",
            "duration": "80 hours/40 days",
            "desc": "With the introduction of the new taxation system, it’s imperative, a Finance & Accounts experts has required knowledge on GST too. The program is designed to help the participants gain an overall understanding of the GST Law covering its implementation, compliance and enforcement. It helps understanding how GST would impact the functioning of your organization and changes in the business process required for compliance with the new law. ",
            "overview": [
                "Registration",
                "Tax Invoice, Credit and Debit Notes",
                "Accounts and Records",
                "Returns",
                "Refunds / Payment Of Tax",
                "E - Way Bill",
                "Input tax Credit"
            ],
            "nextBatch":'02-Feb-2021'

        },
        {
            "id": 2,
            "name": "Practical Finance and Accounting Experts",
            "imgSrc": "assets/arthaSiksha/courses/new/accountingexperts.jpg",
            "duration": "90 hours/45 days",
            "desc": "Main objective of introducing PFAE program is to imbibe practical knowledge in the areas of F&A domain for the naïve candidates and make them to be aware of the processes and functional areas that exist in the corporate environment in Finance and Accounting practices and enhance their employability skills.",
            "overview": [
                "Introduction to Accounting Fundamentals and Concepts - Domain Skill",
                "Practical Accounting Knowledge",
                "Functional practices in F & A practiced in Corporate companies",
                "MS - office usage in F & A",
                "Quality standards and Operational Excellence"
            ],
            "nextBatch":'03-Feb-2021'

        },
        {
            "id": 3,
            "name": "Payroll Accounting and Compliance",
            "imgSrc": "assets/arthaSiksha/courses/new/payroll.jpg",
            "duration": "40 hours/20 days",
            "desc": "This program is designed with an objective of specialization in Payroll Accounting and helps a candidate to upskill in all the aspects covered in Payroll accounting process and related functional area. This training helps the trainees to thoroughly understand the Payroll process and help him to comfortably attend the interview in corporate companies engaged in Payroll process.",
            "overview": [
                "Employees Payroll - Salaries and Wages",
                "Fringe benefits",
                "Payroll Taxes and compliance",
                "Employee reimbursements against tax benefits",
                "Retirement benefits like Provident Fund, Pension Fund, Gratuity",
                "Employer Insurance payment",
                "Overtime and Holiday payment",
                "Withholding tax payments",
                "Worker Compensation payments",
                "Incentive, Bonus and Commission payment",
                "Attendance, Leave Management",
                "Employee final settlement"
            ],
            "nextBatch":'04-Feb-2021'

        },
        {
            "id": 4,
            "name": "Indian Accounting standards and application",
            "imgSrc": "assets/arthaSiksha/courses/new/accountingexperts.jpg",
            "duration": "40 hours/20 days",
            "desc": "A critical element which relates the domain learning in context with the standards and regulations in India. The course essentially emphasizes on the governing rules in India that needs to be adhered to. It also focusses on key differences when compared to other standards that are followed globally. ",
            "overview": [
                "Valuation aspect",
                "Accounting aspect",
                "Presentation aspect",
                "Disclosure aspect",
                "Revenue recognition",
                "Income taxes",
                "Property, plant and equipment",
                "Employee benefits",
                "First time adoption of IFRS",
                "Differences between IFRS, Ind AS, and old Indian GAAP"
            ],
            "nextBatch":'05-Feb-2021'

        },
        {
            "id": 5,
            "name": "Tax Accounting and Compliance",
            "imgSrc": "assets/arthaSiksha/courses/new/taxaccounting.jpg",
            "duration": "20 hours/10 days",
            "desc": "Main objective of specialization of Tax Accounting program is to train a candidate on all the aspects covered in Tax accounting process and related compliance area. This program would help the trainees to thoroughly understand the Tax Accounting process and help users to comfortably attend the interview in corporate companies engaged in Tax Accounting process.",
            "overview": [
                "Accounting of GST on Invoice payables and Invoice Receivables",
                "Tax Regulations",
                "Tax Return preparation and Filing",
                "Compliance with Direct Taxes",
                "Tax Refunds and claim status",
                "Payroll Taxes",
                "Withhold taxes- Accounting, Payments and Return filing",
                "New guidelines issued by Government"
            ],
            "nextBatch":'06-Feb-2021'

        },
        {
            "id": 6,
            "name": "Record-to-Report",
            "imgSrc": "assets/arthaSiksha/courses/new/recordtoreport.jpg",
            "duration": "20 hours/10 days",
            "desc": "The course is designed to equip participants to become experts in Record to Report (R2R). It is a Finance and Accounting (F&A) management process which involves collecting, processing and delivering relevant, timely and accurate information. It provides strategic, financial and operational feedback on how a business is performing. The R2R is used to sort and store balance sheet and income statement transactions.",
            "overview": [
                "GL Masters",
                "General accounting and Close activity",
                "Management Reporting",
                "Intercompany accounting Settlement and Recon",
                "Business Entities",
                "FX revaluation",
                "Close tasks and timelines",
                "Period Close and Roll forward",
                "Sub - Ledger closure and impact",
                "Accruals, Standard and Recurring Journals",
                "Reporting Tools - Hyperion, Cognos etc",
                "Balance Sheet Reconciliations"
            ],
            "nextBatch":'07-Feb-2021'

        },
        {
            "id": 7,
            "name": "Statutory and Audit Compliance",
            "imgSrc": "assets/arthaSiksha/courses/new/auditcompliance.jpg",
            "duration": "20 hours/10 days",
            "desc": "Main objective of specialization of Statutory and Audit Compliance program is to train a candidate on the industry level best practices followed in Audit activity and aspects covered in Regulatory Guidelines process and its compliance. This program would help the trainees to thoroughly understand the Audit process and help him to comfortably attend the interview in this domain.",
            "overview": [
                "Internal Controls and Internal Audit",
                "Risk Audit",
                "Transaction Audit",
                "IT Audit",
                "System and Application Audit",
                "SOX audit guidelines",
                "IFRS guidelines",
                "GAAP and IAS guidelines",
                "Audit Planning",
                "Audit Reporting",
                "Audit Sampling",
                "Audit Conclusions",
                "Audit of corrective actions and follow up"
            ],
            "nextBatch":'08-Feb-2021'

        },
        {
            "id": 8,
            "name": "Fixed Asset Accounting and Compliance",
            "imgSrc": "assets/arthaSiksha/courses/new/fixedasset.jpg",
            "duration": "20 hours/10 days",
            "desc": "This course is designed with an objective to obtain specialization of Fixed Asset Accounting and to train a candidate on the all the aspects covered in Asset accounting process and related functional area. This program would help the trainees to thoroughly understand the Asset Accounting process and help him to comfortably attend the interview in corporate companies engaged in Fixed Asset Accounting process",
            "overview": [
                "Classification of Assets",
                "Tangible and Intangible Assets",
                "Asset Grouping and Sub - ledger concepts",
                "Creation of Project, WBS elements",
                "Self - construction.",
                "Procurement of Movable Assets",
                "Accounting of CWIP",
                "Settlement of CWIP",
                "Creation of an Asset account",
                "Components of Costs",
                "Capitalization",
                "Depreciation",
                "Net Block vs Gross Block",
                "Revaluation",
                "Acquisition of Assets",
                "Retirement and Disposal"
            ],
            "nextBatch":'09-Feb-2021'

        },
        {
            "id": 9,
            "name": "Procure to Pay",
            "imgSrc": "assets/arthaSiksha/courses/new/procuretopay.jpg",
            "duration": "20 hours/10 days",
            "desc": "Main objective of specialization of Procure-to-Pay program is to train a candidate on the industry level best practices followed in Procurement process, Invoice accounting, Payment process and other aspects covered in Procure To Pay (P2P) process and related compliance areas. This program would help the trainees to thoroughly understand the P2P process and help him to comfortably attend the interview in this domain.",
            "overview": [
                "Vendor Management - Vendor Master",
                "Procurement process - Requisition, Vendor Selection, PO Release",
                "Payment terms",
                "Services and Goods classification",
                "Non - PO based Vendors",
                "Vendor Invoice accounting",
                "Vendor Queries - Calls and Emails",
                "E - invoicing and Manual invoicing",
                "Self - Billing",
                "Payment Processing - Schedule payments, Proposal and Final Run",
                "Direct Debit Payments",
                "Duplicate Payments - Impact and prevention process - Tools",
                "DPO and ACH analysis",
                "Vendor Account Reconciliation",
                "Travel expenses accounting and Payment",
                "Debit / Credit Notes",
                "Ageing Reporting",
                "Invoice Cycle Time",
                "First Pass Yield",
                "3 Way and 2 Way match.",
                "GRIR"
            ],
            "nextBatch":'10-Feb-2021'

        },
        {
            "id": 10,
            "name": "Order-to-Cash",
            "imgSrc": "assets/arthaSiksha/courses/new/ordertocash.jpg",
            "duration": "20 hours/10 days",
            "desc": "This is a specialized program to train on the Order To Cash process and related industry level best practices followed in Order Management, Billing, Cash Application and Cash Collection, other areas in Order To Cash (OTC) process and related compliance areas. Post training , all the trainees would have thorough understanding on the OTC process and help him to comfortably attend the interview in this domain.",
            "overview": [
                "Customer Management & Customer Master",
                "Order Processing",
                "Credit Terms",
                "Customer Billing",
                "Disputes and Resolutions",
                "Customer Queries",
                "Cash Application",
                "Account Reconciliation",
                "DSO analysis and improvement",
                "Ageing Report",
                "Dunning",
                "Factoring",
                "Unapplied Receipts",
                "On - accounting receipts",
                "Broken promises and follow up",
                "Collection process - Calls and Email handling",
                "Debit / Credit Notes",
                "Cash and Trade Discounts",
                "Tax requirements"
            ],
            "nextBatch":'11-Feb-2021'

        },
    ];


    public getCoursesData() {
        return this.coursesData;
    }
    registerationForm = {};
    addRegis(val) {
        this.registerationForm = val;

    }
    getRegis() {
        return this.registerationForm

    }

    // ----------------------------------------------------------------------------
    // ----------------------------------------------------------------------------

    supportData = [
        {
            "question": "What is Artha Siksha ?",
            "description": "Artha Siksha is the gateway that provides students with engaging learning experience. It’s an intuitive digital platform that leverages result driven assessment and sustainable learning.  ",
        },
        {
            "question": "Who are eligible to register?",
            "description": "Artha Siksha caters programs to various sectors like <ul><li>IT/ITES</li><li>Retail</li><li>BFSI</li><ul>",
        },
        {
            "question": "What is the age limit for the enrollment",
            "description": "21-30 Years",
        },
        {
            "question": "What is the eligibility criteria for Mean Stack developer Training (Technology Product Development Expertise’ (TPDE)?",
            "description": "Artha Siksha caters programs to various sectors like IT/ITES , Retail  and BFSI. \n     IT /ITES  - B.Tech , M.Tech , B.Sc , M.Sc , BCA , MCA\n",
        },
        {
            "question": "What is the eligibility criteria for Full Stack developer Training (Technology Product Development Expertise’ (TPDE)",
            "description": "Artha Siksha caters programs to various sectors like IT/ITES , Retail  and BFSI. \n     IT /ITES  - B.Tech , M.Tech , B.Sc , M.Sc , BCA , MCA\n",
        },
        {
            "question": "What is the eligibility criteria for finance and accounts Training ( Practical Finance and Accounting Experts) (PFAE)",
            "description": "Artha Siksha caters programs to various sectors like IT/ITES , Retail  and BFSI. \nBFSI -   B.com, M.com , MBA , CA Inter , CWA Inter\n",
        },
        {
            "question": " What is the eligibility criteria for Retail Training (Retail Resource Experts)",
            "description": "Artha Siksha caters programs to various sectors like IT/ITES , Retail  and BFSI. \nRRE -  B.com , M.com, BBA, MBA\n",
        },
        {
            "question": "What Courses do you offer?",
            "description": "IT/ITES – Java, Dot Net, PHP, Angular 2, HTML, CSS, CMS, UI /UX, QA, DBA, Cloud -DevOps, J2EE-MS, J2EE–FS, Web 3.0 Architecture, Full Stack, Mean Stack, Wordpress, Python.<br>Retail – Customer Service, Sales, Marketing .<br>BFSI – Accounts Payable, Accounts Receivable, General Ledger, Asset Management, Procurement Management, Professional Tax, Payroll Accounting, GST, Taxation & Accounting.<br>Life skills – Effective Communication, Body Language and Building Personal Image, Decision Making, Critical Thinking, Life Skills for Success, Effective Feedback Mechanism, Time Management, Stress Management, Negotiation Skills, Conflict Management.<br>Cognitive - Quantitative Ability, Logical Reasoning, Verbal Ability.<br>Employability Skills - Resume & Cover Letter, Interview Essentials, Body Language, MS Office Essentials, Presentation Skills.",
        },
        {
            "question": "What is pre assessment",
            "description": "All Participants must take a pre assessment after registering themselves with Artha Siksha to confirm there eligibility before enrollment to a program. Pre assessment will help the trainer to understand the individuals strengths and weakness and help them to improve the course delivery.",
        },
        {
            "question": "What is a sector",
            "description": "Based on Employer needs 38 sector skills were identified for skill development and entrepreneurship ",
        },
        {
            "question": "What is a segment",
            "description": "To facilitate trainings the target population has been divided into 7 Segments and separate programs are designed for each segment.",
        },
        {
            "question": "Who all will come under ‘out of college’ segment",
            "description": "Engineering , Polytechnic, degree and PG pass-outs who are still unemployed",
        },
        {
            "question": " Who will come under ‘Engineering skills’ segment",
            "description": "Engineering , Polytechnic students who are still in college",
        },
        {
            "question": "Pre assessment categories or pre assessment test",
            "description": "The multi-dimensional tool assesses a student on General aptitude , General Communication , Programming skills, Employability skills and Psychometric skills",
        },
        {
            "question": "What is psychometric test",
            "description": "A seldom spoken innate skill of an individual, Psychometric Analysis engages scientific method measure individuals' mental capabilities , behavioral style and candidates' suitability for a role based",
        },
        {
            "question": "What is domain test",
            "description": "The ‘Intelligent Code Analyzer’ prompts the student a scenario for coding and smartly analyzes the code and identifies accuracies and highlights any disparity",
        },
        {
            "question": "What is general aptitude test",
            "description": "Quick decision making is a critical skill. The range of Aptitude Skills assessment gauges the students’ propensity to succeed in a given activity and inherent strengths and weaknesses",
        },
        {
            "question": " What is general communication test",
            "description": "The multi-dimensional tool assesses a student on essential business communication and behavioral skills to determine competency that are critical for a career progression",
        },
        {
            "question": "What are the benefits of doing a course in Artha Siksha ?",
            "description": "Artha Siksha offers an interactive learning experience and helps students to empower to step-in to professional career.",
        }
    ];

    public getSupportData() {
        return this.supportData;
    }
}


