import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Component } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RecaptchaModule, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';



// Webpage components 
import { AppComponent } from './app.component';
import { AboutUsComponent } from './webpages/about-us/about-us.component';
import { ContactUsComponent } from './webpages/contact-us/contact-us.component';
import { BlogsComponent } from './webpages/blogs/blogs.component';
import { EmailverificationComponent } from './webpages/emailverification/emailverification.component';
import { CoursesComponent } from 'src/app/webpages/courses/courses.component';
import { PagenotfoundComponent } from './webpages/pagenotfound/pagenotfound.component';


// Global Components
import { HeaderComponent } from './components/header/header1/header.component';
import { MainHeaderComponent } from './components/header/header1/main-header/main-header.component';

import { NavigationComponent } from './components/navigation/navigation.component';

import { FooterComponent } from './components/footer/footer1/footer.component';

// Directives
import { dropdownDirective } from './directive/dropdown.directive';

// Custom Components
import { Heading1Component } from './components/heading/heading1/heading1.component';
import { Carousel1Component } from './components/carousel/carousel1/carousel1.component';
import { UpcomingEventsComponent } from './components/blocks/upcoming-events/upcoming-events1/upcoming-events.component';
import { GotopComponent } from './components/blocks/gotop/gototop1/gotop.component';
import { Tabs1Component } from './components/blocks/tabs/tabs1/tabs1.component';
import { CoursesCardComponent } from './components/blocks/courses-card/courses-card.component';
import { WorkflowComponent } from './components/blocks/workflow/workflow.component';
import { TopbarComponent } from './components/header/topbar/topbar.component';
import { NavMenuComponent } from './components/header/nav-menu/nav-menu.component';
import { ApiService } from './services/http/api.service';
import { Accordian1Component } from './components/blocks/accordian/accordian1/accordian1.component';
import { AccordianService } from './services/accordian.service';
import { ChatAppComponent } from './components/blocks/chat-app/chat-app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// For Routing files imports and exports
import { AppRoutingModule } from './app-routing.module';
import { DashboardComponent } from './webpages/dashboard/dashboard.component';
import { GuardService } from './services/authentication/guard.service';
import { HomeComponent } from './webpages/dashboard/home/home.component';
import { SettingComponent } from './webpages/dashboard/setting/setting.component';
import { SoftwareComponent } from './webpages/dashboard/software/software.component';
import { DashboardService } from './webpages/dashboard/dashboard.service';
import { AuthenticationService } from './services/authentication/authentication.service';
import { SpSetupStartComponent } from './webpages/dashboard/setting/skillpassportSetup/start/start.component';
import { SpSetupFormComponent } from './webpages/dashboard/setting/skillpassportSetup/form/form.component';
import { FeaturesComponent } from './webpages/features/features.component';
import { Tab1Component } from './components/blocks/tabs/tab1/tab1.component';
import { urlPortService } from './services/url-port.service';
import { OrganizationComponent } from './webpages/solution/organization/organization.component';
import { IndividualComponent } from './webpages/solution/individual/individual.component';
import { Slider1Component } from './components/slider/slider1/slider1.component';
import { Carousel2Component } from './components/carousel/carousel2/carousel2.component';
import { DmService } from './dmarketing/dm.service';
import { CourseDetailsComponent } from './webpages/course-details/course-details.component';
import { CoursesCarouselComponent } from "./components/blocks/courses-card/courses-carousel/courses-carousel.component";
import { CheckoutDetailsComponent } from './webpages/checkout-details/checkout-details.component';
import { TransactionResponseComponent } from './webpages/transaction-response/transaction-response.component';
import { TeamComponent } from './webpages/team/team.component';
import { QueryComponent } from './webpages/arthaSiksha/query/query.component';
import { StudentIndividualComponent } from './webpages/solution/individual/student-individual/student-individual.component';
import { ProfessionalIndividualComponent } from './webpages/solution/individual/professional-individual/professional-individual.component';
import { AcademicComponent } from './webpages/academic/academic.component';
import { NgwWowModule } from 'ngx-wow';
import { FAQComponent } from './webpages/faq/faq.component';
import { SideNavComponent } from './components/header/side-nav/side-nav.component';
import { BlogCardComponent } from './webpages/blogs/blog-card/blog-card.component';
import { BlogDetailsComponent } from './webpages/blogs/blog-details/blog-details.component';
import { BlogSidebarComponent } from './webpages/blogs/blog-sidebar/blog-sidebar.component';
import { FilterPipe } from './webpages/blogs/filter.pipe';
import { StartTrialComponent } from './webpages/arthaSiksha/start-trial/start-trial.component';
import { AdminLoginComponent } from './webpages/arthaSiksha/admin-panel/admin-login/admin-login.component';
import { AdminIndexComponent } from './webpages/arthaSiksha/admin-panel/admin-index/admin-index.component';
import { AdminHeaderComponent } from './webpages/arthaSiksha/admin-panel/admin-header/admin-header.component';
import { AdminSidebarComponent } from './webpages/arthaSiksha/admin-panel/admin-sidebar/admin-sidebar.component';
import { AdminCourseControllerComponent } from './webpages/arthaSiksha/admin-panel/admin-course-controller/admin-course-controller.component';
import { AdminRegisterDetailsComponent } from './webpages/arthaSiksha/admin-panel/admin-register-details/admin-register-details.component';
import { AdminContactDetailsComponent } from './webpages/arthaSiksha/admin-panel/admin-contact-details/admin-contact-details.component';
import { AdminDashboardComponent } from './webpages/arthaSiksha/admin-panel/admin-dashboard/admin-dashboard.component';
import { AdminUserQueryComponent } from './webpages/arthaSiksha/admin-panel/admin-user-query/admin-user-query.component';
import { ResponseComponent } from './components/blocks/response/response.component';
import { RegisterComponent } from './webpages/register/register.component';
import { StudentRegistationComponent } from './webpages/register/student-registation/student-registation.component';
import { ProfessionalRegistrationComponent } from './webpages/register/professional-registration/professional-registration.component';
import { AcademicRegistrationComponent } from './webpages/register/academic-registration/academic-registration.component';
import { OrganizationRegistrationComponent } from './webpages/register/organization-registration/organization-registration.component';
import { AdminFaqComponent } from './webpages/arthaSiksha/admin-panel/admin-faq/admin-faq.component';
@NgModule({
  declarations: [
    AppComponent,

    // Webpage components 
    AboutUsComponent,
    ContactUsComponent,
    BlogsComponent,
    EmailverificationComponent,
    CoursesComponent,
    PagenotfoundComponent,

    // Global Components
    TopbarComponent,
    HeaderComponent,
    MainHeaderComponent,
    NavigationComponent,
    FooterComponent,

    // Directives
    dropdownDirective,



    // Custom Components
    Heading1Component,
    Carousel1Component,
    UpcomingEventsComponent,
    GotopComponent,
    Tabs1Component,
    CoursesCardComponent,
    WorkflowComponent,
    NavMenuComponent,
    Accordian1Component,
    ChatAppComponent,
    DashboardComponent,
    HomeComponent,
    SettingComponent,
    SoftwareComponent,
    SpSetupStartComponent,
    SpSetupFormComponent,
    FeaturesComponent,
    Tab1Component,
    OrganizationComponent,
    IndividualComponent,
    Slider1Component,
    Carousel2Component,
    CourseDetailsComponent,
    CoursesCarouselComponent,
    CheckoutDetailsComponent,
    TransactionResponseComponent,
    TeamComponent,
    QueryComponent,
    StudentIndividualComponent,
    ProfessionalIndividualComponent,
    AcademicComponent,
    FAQComponent,
    SideNavComponent,
    BlogCardComponent,
    BlogDetailsComponent,
    BlogSidebarComponent,
    FilterPipe,
    StartTrialComponent,
    AdminLoginComponent,
    AdminIndexComponent,
    AdminHeaderComponent,
    AdminSidebarComponent,
    AdminCourseControllerComponent,
    AdminRegisterDetailsComponent,
    AdminContactDetailsComponent,
    AdminDashboardComponent,
    AdminUserQueryComponent,
    ResponseComponent,
    RegisterComponent,
    StudentRegistationComponent,
    ProfessionalRegistrationComponent,
    AcademicRegistrationComponent,
    OrganizationRegistrationComponent,
    AdminFaqComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    RecaptchaModule,
    NgwWowModule,
    NgbModule
  ],
  providers: [
    ApiService,
    AccordianService,
    GuardService,
    DashboardService,
    AuthenticationService,
    urlPortService,
    DmService,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: '6Lf3wTcUAAAAAGGceXYwnNaDQI2QynrtHAdqh4M0',
      } as RecaptchaSettings,
    }

  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
