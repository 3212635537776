<div class="container query-container">
  <div class="row">
    <div class="col-md-6 col-sm-6 col-xs-12 role-container">
      <h3 class="wow fadeInUp">Got curious about our course offerings? </h3>
      <h4 class="wow fadeInUp"> Reach out to us for more information.</h4>
      <div class="form-group mt-3">
        <label for="role">
          <h4 class="wow fadeInUp">I am</h4>
        </label>
        <div class="role wow fadeInLeft">
          <input type="radio" [(ngModel)]="role" checked (change)="roleChanged($event)" class="ml-4 mr-3" value="STUDENT"
            name="role" id="student" />
          <label for="student">
            Student
          </label><br>
        </div>
        <div class="role wow fadeInLeft">
          <input type="radio" (change)="roleChanged($event)" class="ml-4 mr-3" value="PROFESSIONAL" name="role" id="professional"
            [(ngModel)]="role" />
          <label for="professional">
            Working Professional
          </label><br>
        </div>
        <div class="role wow fadeInLeft">
          <input type="radio" [(ngModel)]="role" (change)="roleChanged($event)" class="ml-4 mr-3" value="ACADEMIC" name="role"
            id="academic" />
          <label for="academic">
            Academic Institution
          </label><br>
        </div>
        <div class="role wow fadeInLeft">
          <input type="radio" [(ngModel)]="role" (change)="roleChanged($event)" class="ml-4 mr-3" value="ORGANIZATION" name="role"
            id="organization" />
          <label for="organization">
            Organization
          </label><br>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 query-form">
      <form [formGroup]="queryForm">
        <fieldset *ngIf="queryForm">
          <div class="form-group wow fadeInUp">
            <label>Name <span class="text-danger">*</span> </label>
            <input [ngClass]="{'error':isValid('name')}" type="text" name="name" maxlength=30 class="form-control"
              formControlName="name" />
          </div>
          <div class="form-group wow fadeInUp">
            <label>Contact Number<span class="text-danger">*</span></label>
            <input [ngClass]="{'error':isValid('contact')}" type="number" class="form-control" minlength="10" maxlength=12
              formControlName="contact" />
          </div>
          <div class="form-group wow fadeInUp">
            <label>Email<span class="text-danger">*</span></label>
            <input [ngClass]="{'error':isValid('email')}" type="email" class="form-control" maxlength=30
              formControlName="email" />
          </div>
          <div class="form-group wow fadeInUp" *ngIf="isprofessional">
            <label>Work Experience(years)<span class="text-danger">*</span></label>
            <input [ngClass]="{'error':isValid('workExperience')}" type="number" maxlength=2 class="form-control"
              formControlName="workExperience" />
          </div>
          <div class="form-group wow fadeInUp" *ngIf="isAcedemic">
            <label>Name of the institution<span class="text-danger">*</span></label>
            <input [ngClass]="{'error':isValid('institutionName')}" type="text" class="form-control" maxlength=30
              formControlName="institutionName" />
          </div>
          <div class="form-group wow fadeInUp" *ngIf="isOrganization">
            <label>Name of the Organization<span class="text-danger">*</span></label>
            <input [ngClass]="{'error':isValid('organizationName')}" type="text" class="form-control" maxlength=30
              formControlName="organizationName" />
          </div>
          <div class="form-group wow fadeInUp" *ngIf="isAcedemic || isOrganization">
            <label>Location<span class="text-danger">*</span></label>
            <input [ngClass]="{'error':isValid('location')}" type="text" class="form-control" maxlength=30
              formControlName="location" />
          </div>
          <div class="form-group wow fadeInUp" *ngIf="isAcedemic">
            <label>Number of students<span class="text-danger">*</span></label>
            <input [ngClass]="{'error':isValid('noOfStudents')}" type="number" class="form-control" maxlength=5
              formControlName="noOfStudents" />
          </div>
          <div class="form-group wow fadeInUp" *ngIf="isOrganization">
            <label>Number of employees<span class="text-danger">*</span></label>
            <input [ngClass]="{'error':isValid('noOfEmployees')}" type="number" class="form-control" maxlength=5
              formControlName="noOfEmployees" />
          </div>
          <div class="form-group wow fadeInUp">
            <label>Query<span class="text-danger">*</span></label>
            <textarea [ngClass]="{'error':isValid('Query')}" class="form-control" name="query" maxlength=200 id="query"
              cols="30" rows="3" formControlName="Query"></textarea>
          </div>
          <div class="form-group text-center mt-3 wow fadeInUp">
            <button class="next action-button" [ngClass]="{'disabled':queryForm['invalid'] || !role}"
              [disabled]="queryForm['invalid'] || !role" type="button" (click)="onSubmit()">Submit</button>
          </div>
        </fieldset>
      </form>
    </div>
  </div>
</div>