<div class="container-fluid">
	<div class="row">
	  <div class="about-us-image"></div>
	  <div class="about-us-navigation">
		<div class="link-list">
		  <a (click)="toAboutUs('mission')" class="link wow fadeInLeft">Mission</a>
		  <a (click)="toAboutUs('team')" class="link wow fadeInUp">Team</a>
		  <!-- <a href="" class="link">link3</a> -->
		  <a routerLink="/contact-us" class="link fadeInRight">Contact us</a> 
		</div>
	  </div>
	</div>
  </div>
  <div class="aboutus-bg1">
  <div class="container">
	<div class="row py-5 justify-content-center">
	  <div class="col-md-8 col-lg-8 col-sm-12">
		<h1 class="main-heading text-center wow fadeInUp">
			Connecting campus to career
		</h1>
		<hr class="devider wow fadeInUp" />
		<p class="text-center wow fadeInUp">
			Artha Siksha is one of a kind learning & development platform started with a main moto “connecting campus to career” be that campus a college or an existing work place.  Our objective is to prepare commerce graduates and professionals to face and conquer NexGen challenges by offering them bespoke training programs targeting their specific needs.  
		</p>
	  </div>
	</div>
	<div class="row p-0 section-1">
	  <div class="col-md-6 col-lg-6 col-sm-12 p-0">
		<div class="about-image-1 wow fadeInLeft"></div>
	  </div>
	  <div class="col-md-6 col-lg-6 col-sm-12 p-0">
		<div class="p-5 content wow fadeInRight">
		  <h4>One of a kind skilling program</h4>
		  <p>
			Artha Siksha is a catalyst in skill & talent development programs focusing on building competencies for creating employable workforce straight from the college. 
		  </p>
		</div>
	  </div>
	</div>
	<div class="row p-0 section-2" id="mission">
	  <div class="col-md-6 col-lg-6 col-sm-12 p-0">
		<div class="about-image-2 wow fadeInRight"></div>
	  </div>
	  <div class="col-md-6 col-lg-6 col-sm-12 p-0">
		<div class="p-5 content wow fadeInLeft">
		  <h4>Proprietary delivery program</h4>
		  <p>
			We encapsulate the role of practical experience and expertise for skilling and training services meant for students, freshers and professionals in F&A domain.
		  </p>
		</div>
	  </div>
	</div>
	<div class="row p-0 section-3">
	  <div class="col-md-6 col-lg-6 col-sm-12 p-0">
		<div class="about-image-3 wow fadeInLeft"></div>
	  </div>
	  <div class="col-md-6 col-lg-6 col-sm-12 p-0">
		<div class="p-5 content wow fadeInRight" >
		  <h4>Unique staffing solutions</h4>
		  <p>
			Our highly-skilled and readily deployable resources provide flexibility to the employers by offering them a choice to hire them on a short, medium or a on a permanent basis.
		  </p>
		</div>
	  </div>
	</div>
  </div>
  <div class="container py-5">
	<div class="row justify-content-center">
	  <div class="col-md-8 col-lg-8 col-sm-12">
		<h3 class="text-center wow fadeInUp">
		  Our Principles Define Us and Our Work
		</h3>
		<hr class="devider wow fadeInUp" />
		<p class="text-center my-4 wow fadeInUp">
		  At Revature, we’re about more than developing great talent and
		  technology. The propelling force behind our work is to empower the next
		  generation of innovation in technology.
		</p>
	  </div>
	</div>
	<div class="row">
	  <div class="col-md-6 col-lg-6 col-sm-12 mission-vision wow fadeInLeft"></div>
	  <div class="col-md-6 col-lg-6 col-sm-12">
		<div class="row">
		  <div class="col-12 my-3 wow fadeInRight" data-wow-duration="0.5s">
			<h4>Our Mission is to</h4>
			<ul>
				<li>Create a pool of highly skilled and readily deployable talent for short, medium and long term hire</li>
				<li>Enable efficient and effective transition from academic career to professional career</li>
				<li>Enrich professional career through upskilling and reskilling</li>
				<li>Create holistic industry experts in F&A domain through innovative learning solutions right from college.</li>
			</ul>
		  </div>
		  <div class="col-12 my-3 wow fadeInRight" data-wow-duration="1s">
			<h4>Vision</h4>
			<p>
				The vison that conceived and drives Artha Siksha is the creation of a focussed and expert intervention in the Training and Development eco-system for Finance & Accounting domain.
			</p>
		  </div>
		  <div class="col-12 my-3 wow fadeInRight" data-wow-duration="1.5s">
			<h4>Values</h4>
			<p>
			  We are a group of people with wide-ranging passions and aspirations,
			  united by common values. Our communities are inclusive, diverse,
			  respectful, caring and fun. Our people are curious, creative,
			  original, inspiring, and ambitious.
			</p>
		  </div>
		</div>
	  </div>
	</div>
  </div>
  <app-team></app-team>
</div>