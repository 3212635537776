import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/http/api.service';
import { CheckoutDetailsModel } from 'src/app/services/model/checkoutDetails.model';

@Component({
  selector: 'app-checkout-details',
  templateUrl: './checkout-details.component.html',
  styleUrls: ['./checkout-details.component.less']
})
export class CheckoutDetailsComponent implements OnInit {

  checkoutForm: FormGroup;
  submitted = false;
  paymentUrl = "https://test.payu.in/_payment";
  isPaymaentError = false;
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    // private ContactModel:ContactModel,
    private api: ApiService
  ) {}


  inputvalidations(event) {
    if (event.target.value != "") {
      event.target.nextElementSibling.style.transform = "translateY(-15px)"
      event.target.nextElementSibling.style.fontSize = "12px"
    } else {
      event.target.nextElementSibling.style.transform = "translateY(0px)"
      event.target.nextElementSibling.style.fontSize = "18px"
    }
  }
  ngOnInit() {
    this.checkoutForm = this.formBuilder.group({
      firstName: ['', Validators.compose([Validators.required])],
      lastName: ['', Validators.compose([Validators.required])],
      organization: ['', Validators.compose([Validators.required])],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      phone: ['', Validators.compose([Validators.required])],
      city: ['', Validators.compose([Validators.required])],
      state: ['', Validators.compose([Validators.required])],
      amount: ['', Validators.compose([Validators.required, Validators.pattern(/^[1-9][0-9]*$/)])]
    });


  }

  get f() { return this.checkoutForm.controls; }

  convertPhoneNumber(e, fv, sv) {
    const val = e.target.value.replace('-', '').replace('-', '');
    if (val != undefined && val != '') {
        const newval = val.split('').reduce((sum, v, i) => {
            if (i == fv || i == sv) sum += '-';
            return sum = sum + v;
        }, '');
        // var regvar = ""+newval;
        // var regex = RegExp(/^\d{3}-\d{3}-\d{4}$/gm);
        e.target.value = newval;
    }
}

  onSubmit() {
    if (!this.submitted) {
      this.submitted = true;
      var data = () => new CheckoutDetailsModel(
        this.checkoutForm.value['firstName'],
        this.checkoutForm.value['lastName'],
        this.checkoutForm.value['organization'],
        this.checkoutForm.value['email'],
        this.checkoutForm.value['phone'],
        this.checkoutForm.value['city'],
        this.checkoutForm.value['state'],
        this.checkoutForm.value['amount'],
        this.checkoutForm.value['message'],
        'SP',
        location.origin + '/transaction-response'
      );
      this.submitted = true;
      this.api.post("/payment/payment-details", data()).subscribe(
        response => {
          this.isPaymaentError = false;
          const paymentform = document.forms["paymentform"];
          // paymentform.elements['salt'].value = response['salt'];
          // paymentform.elements['lastname'].value = this.paymentresponse['lastname'];
          // paymentform.elements['curl'].value = this.paymentresponse['curl'];
          // paymentform.elements['address1'].value = this.paymentresponse['address1'];
          // paymentform.elements['address2'].value = this.paymentresponse['address2'];
          // paymentform.elements['city'].value = this.paymentresponse['city'];
          // paymentform.elements['state'].value = this.paymentresponse['state'];
          // paymentform.elements['country'].value = this.paymentresponse['country'];
          // paymentform.elements['zipcode'].value = this.paymentresponse['zipcode']
          paymentform.elements['key'].value = response['key'];
          paymentform.elements['hash'].value = response['hash'];
          paymentform.elements['txnid'].value = response['txnid'];
          paymentform.elements['amount'].value = response['amount'];
          paymentform.elements['firstname'].value = response['firstname'];
          paymentform.elements['email'].value = response['email'];
          paymentform.elements['phone'].value = response['phone'];
          paymentform.elements['productinfo'].value = response['productinfo'];
          paymentform.elements['surl'].value = response['surl'];
          paymentform.elements['furl'].value = response['furl']
          paymentform.elements['udf1'].value = response['returnurl'];
          this.paymentUrl = response['gatewayUrl'];
          setTimeout(() => {
            paymentform.submit();
          }, 1000);
        },

        error => {
          // this.router.navigate(["/transaction-response/failure"]);
          this.isPaymaentError = true;
          this.submitted = false;
        }
        
        );
    }
  }

  checkMobile(event) {
    let element = event.target;
    if ( element.value.length > element.maxLength) {
      this.checkoutForm.controls['phone'].setValue(element.value.slice(0, element.maxLength));
    }
  }
}
