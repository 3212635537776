import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  OnInit,
} from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ApiService } from "src/app/services/http/api.service";
import Cropper from "cropperjs";
import { UtilService } from "src/app/services/util.service";

declare var $: any;
@Component({
  selector: "app-admin-course-controller",
  templateUrl: "./admin-course-controller.component.html",
  styleUrls: ["./admin-course-controller.component.less"],
})
export class AdminCourseControllerComponent
  implements OnInit, AfterViewChecked {
  courseForm: FormGroup;
  courseOverviewList = [""];

  coursesList: any = [];
  imgData;
  cropper;
  cropedImageData;
  filedata;
  isCropped: boolean = false;

  submitType = "CREATE";
  editCourse;

  table;

  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private util: UtilService
  ) {}
  ngAfterViewChecked(): void {}

  ngOnInit() {
    // this.api.getCoursesById(25).subscribe(res=>{
    //   console.log(res);
    // },
    // err=>{
    //   console.log(err);
    // })
    this.loadCourseData();
    this.formInitilization();

    // $('#courseTable').DataTable();
  }
  formInitilization() {
    this.courseForm = this.fb.group({
      courseName: ["", Validators.required],
      courseDuration: ["", Validators.required],
      courseBatchDate: ["", Validators.required],
      courseDescription: ["", Validators.required],
      courseOverview: this.fb.array([""]),
      courseImage: [{}],
      courseOrder: ["", Validators.required],
    });
  }
  loadCourseData() {
    var self = this;
    this.api.getCourseNamesAndOrder().subscribe(
      (res) => {
        if (res.status == 200 && res.body) {
          this.coursesList = res.body;
          setTimeout(() => {
            $("#courseTable").DataTable();
            this.util.setLoadingStatus(false);
          }, 1000);
        }
      },
      (err) => {
        console.log(err);
      }
    );
    // this.api.getCourses().subscribe(
    //   (res) => {
    //     if (res.status == 200 && res.body) {
    //       var data:any = res.body;
    //       this.coursesList = data.map(course=>{
    //         course.courseBatchDate = this.dateConvert(course.courseBatchDate);
    //         return course;
    //       });
    //       setTimeout(() => {
    //         $('#courseTable').DataTable();
    //         this.util.setLoadingStatus(false);
    //       }, 1000);
    //     }
    //   },
    //   (err) => {
    //     console.log(err);
    //   }
    // );
  }
  dateConvert(date) {
    return date.split("-").reverse().join("-");
  }
  getFileData(e) {
    this.filedata = {};
    const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    if (file) {
      if (
        file.type == "image/jpeg" ||
        file.type == "image/jpg" ||
        file.type == "image/png"
      ) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event) => {
          this.filedata = {
            fileData: reader.result,
            fileName: file.name,
            fileNameWithExt: file.name.split(".")[1],
            filePath: "",
            fileSize: file.size,
          };
          this.courseForm.patchValue({
            courseImage: this.filedata,
          });
          this.imgData = reader.result;
          this.toCrop();

          e.target.value = null;
        };
      } else {
        e.target.value = null;
        alert("invalid format. Only Jpeg, jpg and png formats are supported");
        return;
      }
    }
  }

  toCrop() {
    var image = $("#cropImage")[0];
    image.src = this.imgData;
    this.cropper && this.cropper.destroy();

    this.cropper = new Cropper(image, {
      viewMode: 1,
      dragMode: "move",
      aspectRatio: 4 / 3,
      minCanvasHeight: 800,
      minCanvasWidth: 450,
      minContainerWidth: 800,
      minContainerHeight: 450,
      movable: true,
      rotatable: true,
      modal: true,
    });
    $("#cropModal").modal("show");
  }

  onFormSubmit() {
    var formData = this.courseForm.value;
    formData.courseBatchDate = this.dateConvert(
      this.courseForm.value.courseBatchDate
    );
    if (this.submitType == "CREATE") {
      this.api.saveCourse(formData).subscribe(
        (res) => {
          if (res.status == 200) {
            this.util.setLoadingStatus(true);
            this.loadCourseData();
          }
        },
        (err) => {
          console.log(err);
        }
      );
    } else {
      var data = {
        ...formData,
        id: this.editCourse.id,
      };
      this.api.updateCoure(data).subscribe(
        (res) => {
          if (res.status == 200) {
            this.util.setLoadingStatus(true);
            this.loadCourseData();
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
    this.closeModal();
    this.formClear();
    this.loadCourseData();
  }
  formClear() {
    this.formInitilization();
    this.courseOverviewList = [""];
    this.imgData = "";
    this.filedata = "";
  }
  deleteCourseById(id) {
    this.api.deleteCoursesById(id).subscribe(
      (res) => {
        if (res.status == 200) {
          this.util.setLoadingStatus(true);
          this.loadCourseData();
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  toEditCourse(id) {
    this.formClear();
    this.submitType = "UPDATE";
    this.api.getCoursesById(id).subscribe((res) => {
      if (res.status == 200 && res.body) {
        this.editCourse = res.body;
        this.editCourse.courseBatchDate = this.dateConvert(this.editCourse.courseBatchDate);

        this.courseOverview.removeAt(0);
        this.editCourse.courseOverview.forEach(() => {
          this.addItemToCourseOverviewList();
        });
        this.courseOverviewList = this.editCourse.courseOverview;
        this.courseForm.patchValue({
          courseName: this.editCourse.courseName,
          courseDuration: this.editCourse.courseDuration,
          courseBatchDate: this.editCourse.courseBatchDate,
          courseDescription: this.editCourse.courseDescription,
          courseOverview: this.editCourse.courseOverview,
          courseImage: this.editCourse.courseImage,
          courseOrder: this.editCourse.courseOrder,
        });

        this.filedata = this.editCourse.courseImage;
        this.imgData = this.editCourse.courseImage.fileData;
        $("#courseModal").modal("show");
      }
    });
    // this.coursesList.forEach((course) => {
    //   if (course.id == id) {
    //     this.editCourse = course;
    //   }
    // });
  }
  saveCrop(event) {
    event.preventDefault();
    console.log(this.cropper.ready);
    event.target.innetHtml = "Uploading";
    const canvas = this.cropper.getCroppedCanvas();
    this.imgData = canvas.toDataURL();
    this.filedata["fileData"] = this.imgData;
    this.courseForm.patchValue({
      courseImage: this.filedata,
    });
    $("#cropModal").modal("hide");
    this.isCropped = true;
  }

  toggleStatus(id) {
    this.api.toggleStatus(id).subscribe(
      (res) => {
        if (res.status == 200) {
          this.util.setLoadingStatus(true);
          this.loadCourseData();
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  get courseOverview() {
    return this.courseForm.get("courseOverview") as FormArray;
  }
  addItemToCourseOverviewList() {
    this.courseOverviewList.push("");
    this.courseOverview.push(this.fb.control(""));
  }
  removeItemInCourseOverviewList(i) {
    this.courseOverview.removeAt(i);
    this.courseOverviewList = this.courseOverview.value;
  }
  openModal(type) {
    this.submitType = type;
    this.imgData = "";
    $("#courseModal").modal("show");
  }
  closeModal() {
    $("#courseModal").modal("hide");
  }

  rotateLeft() {
    this.cropper.rotate(-90);
  }
  rotateRight() {
    this.cropper.rotate(90);
  }
  zoomIn() {
    this.cropper.zoom(0.2);
  }
  zoomOut() {
    this.cropper.zoom(-0.2);
  }
}

export class docStruct {
  constructor(
    fileData: string,
    fileName: string,
    fileNameWithExt: string,
    filePath: string,
    fileSize: number
  ) {}
}
enum courseEditType {
  EDIT = "EDIT",
  CREATE = "CREATE",
}
