import { Component, OnInit, ViewEncapsulation } from '@angular/core';
@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./style/main-sp.component.less'],
  encapsulation: ViewEncapsulation.Emulated
})
export class MainHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    
  }

}
