import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthenticationService } from "src/app/services/authentication/authentication.service";
import { ApiService } from "src/app/services/http/api.service";
import { LoginService } from "src/app/services/login/login.service";
import { UtilService } from "src/app/services/util.service";

@Component({
  selector: "app-admin-login",
  templateUrl: "./admin-login.component.html",
  styleUrls: ["./admin-login.component.less"],
})
export class AdminLoginComponent implements OnInit {
  adminloginForm: FormGroup;
  isPassVisible = false;
  loginStatus:boolean = false;
  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private auth: AuthenticationService,
    private util: UtilService,
    private login:LoginService,
    private router:Router
  ) {}

  ngOnInit() {
    this.auth.adminLoginStatus.subscribe(res=>{
      this.loginStatus = res;
    })
    this.adminloginForm = this.fb.group({
      email: ["", [Validators.required]],
      password: [
        "",
        [
          Validators.required,
          Validators.pattern(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
          ),
        ],
      ],
    });
  }
  onFormSubmit(data) {
    this.login.login(data);
  }

  passwordToggle() {
    var passEl = document.getElementById("password");
    if (this.isPassVisible) {
      passEl.setAttribute("type", "password");
      this.isPassVisible = false;
    } else {
      passEl.setAttribute("type", "text");
      this.isPassVisible = true;
    }
  }
}
