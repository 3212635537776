import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BlogsService, BlogStruct } from 'src/app/services/blogs.service';

@Component({
  selector: 'app-blog-sidebar',
  templateUrl: './blog-sidebar.component.html',
  styleUrls: ['./blog-sidebar.component.less']
})
export class BlogSidebarComponent implements OnInit {

  
  blogList: any;
  viewAll: number = 3;
  searchBox: any;
  constructor(
    private blogs: BlogsService,
    private router: Router,
    private http:HttpClient
  ) { }

  ngOnInit(): void {
    this.http.get<BlogStruct[]>('assets/json/blogs.json').subscribe(data => {
      this.blogList = data;
      this.blogList.sort(this.blogs.blogCompare)
    })
  }

  toIndividualBlog(id) {
    this.router.navigate(['/blog'], { queryParams: { blog: id } })
  }
  changeViewAll(length) {
    this.viewAll = length;
  }
}
