<div class="blog-search mb-3 wow fadeInUp">
  <input type="text" [(ngModel)]="searchBox" placeholder="Search" class="form-control blog-search-input w-100 mx-auto">
  <i class="fa fa-search blog-search-icon"></i>
</div>
<h5>Recent Blogs</h5>
<hr class="m-0 mb-1">

<div *ngIf="(blogList | filter: searchBox).length; else noResults">
  <div class="blog-side-list mb-3" *ngFor="let blog of blogList | filter: searchBox;let i= index">
      <div *ngIf="i<viewAll">
          <b class="text-uppercase blog-side-heading wow fadeInUp"
              (click)="toIndividualBlog(blog.id)">{{blog.title}}</b>
          <i class="d-block wow fadeInUp"> {{blog.date}} </i>
      </div>
  </div>
  <button class="btn btn-secondary w-100" (click)="changeViewAll(blogList.length)"
      *ngIf="viewAll == 3 && blogList.length > 3">View All
      Blogs</button>
  <button class="btn btn-secondary w-100" (click)="changeViewAll(3)" *ngIf="viewAll != 3">Show Less</button>
</div>
<ng-template #noResults>
  <p>No results found for "{{searchBox}}".</p>
</ng-template>