<div class="payment-banner">
  <section class="section section--contact-info section--bg-white-smoke" data-bg="white-smoke">
  
    <div *ngIf="transactionMessage == 'success'">
      <!-- <img src="/assets/images/icons/successful.png" style="height: 200px; width: 200px;"> -->
      <h1><i class="fa fa-check" aria-hidden="true"></i>  Transaction Successfull!</h1>
      <a href="#" rel="nofollow" style="margin-bottom: 20px" class="main-nav__link" routerActiveLink="/"
        class="btns btn-p flex-container flex-d-c">Go to Homepage
      </a>
    </div>
    <div *ngIf="transactionMessage == 'failure'">
      <!-- <img src="/assets/images/icons/failure.png" style="height: 200px; width: 200px;"> -->
      <h1><i class="fa fa-close" aria-hidden="true"></i>  Transaction Failed!</h1>
      <a href="#" rel="nofollow" style="margin-bottom: 20px" class="main-nav__link" routerActiveLink="/"
        class="btns btn-p flex-container flex-d-c">Go to Homepage
      </a>
    </div>
  </section>
</div>
