import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { ApiService } from "src/app/services/http/api.service";
import { UserQuery } from "src/app/services/model/UserQuery.model";
import { UtilService } from "src/app/services/util.service";

@Component({
  selector: "app-query",
  templateUrl: "./query.component.html",
  styleUrls: ["./query.component.less"],
})
export class QueryComponent implements OnInit {
  queryForm: FormGroup;

  isStudent: boolean = false;
  isprofessional: boolean = false;
  isAcedemic: boolean = false;
  isOrganization: boolean = false;
  role: any;

  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private util: UtilService
  ) {}

  commonFormGroup = {
    name: ["", [Validators.required]],
    contact: ["", [Validators.required, Validators.minLength(10)]],
    email: ["", [Validators.email, Validators.required]],
    Query: ["", [Validators.required]],
  };
  professionalFormGroup = {
    workExperience: ["", [Validators.required]],
  };
  academicFormGroup = {
    institutionName: ["", [Validators.required]],
    location: ["", [Validators.required]],
    noOfStudents: ["", [Validators.required]],
  };
  organizationFormGroup = {
    organizationName: ["", [Validators.required]],
    location: ["", [Validators.required]],
    noOfEmployees: ["", [Validators.required]],
  };
  ngOnInit() {
    this.loadFormGroup(this.commonFormGroup);
  }
  loadFormGroup(form) {
    var formData = this.queryForm ? this.queryForm.value : "";
    this.queryForm = this.fb.group(form);
    formData && this.queryForm.patchValue(formData);
  }
  roleChanged(e) {
    var newForm = {};
    var val = e.target.value;
    // val = +val;
    switch (val) {
      case "STUDENT":
        this.isStudent = true;
        this.isprofessional = false;
        this.isAcedemic = false;
        this.isOrganization = false;
        this.loadFormGroup(this.commonFormGroup);
        break;
      case "PROFESSIONAL":
        this.isStudent = false;
        this.isprofessional = true;
        this.isAcedemic = false;
        this.isOrganization = false;
        newForm = {
          ...this.commonFormGroup,
          ...this.professionalFormGroup,
        };
        this.loadFormGroup(newForm);
        break;
      case "ACADEMIC":
        this.isStudent = false;
        this.isprofessional = false;
        this.isAcedemic = true;
        this.isOrganization = false;
        newForm = {
          ...this.commonFormGroup,
          ...this.academicFormGroup,
        };
        this.loadFormGroup(newForm);
        break;
      case "ORGANIZATION":
        this.isStudent = false;
        this.isprofessional = false;
        this.isAcedemic = false;
        this.isOrganization = true;
        newForm = {
          ...this.commonFormGroup,
          ...this.organizationFormGroup,
        };
        this.loadFormGroup(newForm);
        break;
    }
  }
  onSubmit() {
    this.util.setLoadingStatus(true);
    const data = this.queryForm.value;
    var userQuery;
    switch (this.role) {
      case "STUDENT":
        userQuery = new UserQuery(
          this.role,
          data.name,
          data.contact,
          data.email,
          data.Query
        );
        break;
      case "PROFESSIONAL":
        userQuery = new UserQuery(
          this.role,
          data.name,
          data.contact,
          data.email,
          data.Query,
          data.workExperience
        );
        break;
      case "ACADEMIC":
        userQuery = new UserQuery(
          this.role,
          data.name,
          data.contact,
          data.email,
          data.Query,
          null,
          data.institutionName,
          data.noOfStudents,
          data.location
        );
        break;
      case "ORGANIZATION":
        userQuery = new UserQuery(
          this.role,
          data.name,
          data.contact,
          data.email,
          data.Query,
          null,
          null,
          null,
          data.location,
          data.organizationName,
          data.noOfEmployees
        );
        break;
    }
    this.api.saveUserQuery(userQuery).subscribe(
      (res) => {
        this.util.setLoadingStatus(false);
        this.util.showResponseModal(true);
      },
      (err) => {
        this.util.setLoadingStatus(false);
        this.util.showResponseModal(false);
      }
    );
  }
  isValid(name) {
    if (this.queryForm) {
      if (this.queryForm.get(name)) {
        return (
          this.queryForm.get(name)["invalid"] &&
          this.queryForm.get(name)["touched"]
        );
      }
    }
    return false;
  }
}
