import { Component, OnInit } from "@angular/core";


declare var $: any;
@Component({
  selector: "app-workflow",
  templateUrl: "./workflow.component.html",
  styleUrls: ["./workflow.component.less"],
})
export class WorkflowComponent implements OnInit {
  constructor() {}

  ngOnInit() {
    // var animate_left = document.querySelectorAll('.anim-left');
    // var animate_right = document.querySelectorAll('.anim-right');
    // for(let i=0;i<animate_left.length;i++){
    //   gsap.from(animate_left[i], {
    //     x: -300,
    //     opacity:0,
    //     duration: 2,
    //     scrollTrigger: {
    //       trigger:animate_left[i],
    //       start:'bottom bottom',
    //       end:'+=100%'
    //     }
    //   });
    // }
    // for(let i=0;i<animate_right.length;i++){
    //   gsap.from(animate_right[i], {
    //     x: 300,
    //     opacity:0,
    //     duration: 2,
    //     scrollTrigger: {
    //       trigger:animate_right[i],
    //       start:'bottom bottom',
    //       end:'+=100%'
    //     }
    //   });
    }

    // var domainList = document.querySelector('.domain-list');
    // var domainListItem = domainList.getElementsByTagName('li');

    // for(let i=0;i<domainListItem.length;i++){
    //   domainListItem[i].addEventListener('mouseover',(e)=>{
    //     for(let j=0;j<domainListItem.length;j++){
    //       if(i!=j){
    //         $(domainListItem[j]).css({'transform':'scale(0.8)','filter':'brigthness(0.5)'});
    //       }else{

    //         $(domainListItem[j]).css({'transform':'scale(1.2)'});
    //       }
    //     }
    //   })
    //   domainListItem[i].addEventListener('mouseleave',(e)=>{
    //     for(let j=0;j<domainListItem.length;j++){
    //         $(domainListItem[j]).css({'transform':'scale(1)'});
    //     }
    //   })
    // }
  
}
