<div class="container-fluid py-5" style="background: #e8e8e8;">
  <div class="row justify-content-center py-5">
    <div class="col-md-8 text-center">
      <h1 class="mb-4 wow fadeInUp">Individuals</h1>
      <p class="wow fadeInUp">Artha Siksha is designed and developed to cater to Learners at their individual capacity.
        The course offering
        and
        delivery gives flexibility and freedom to an individual learner to identify their leaning areas, assess their
        existing
        competency and choose learning interventions for a tangible outcome. The services are designed keeping in mind
        the
        nature of Individual Learner. You could be a Student or even a working professional.</p>
      <p class="wow fadeInUp">
        <strong>Hence, the course offering caters to Individual Learners of 2 categories.</strong>
      </p>
      <div class="individual-tab-container">
        <div class="student-tab individual-tab individual-tab--active wow fadeInUp" (click)="Clicked(1)">
          <span>Students</span>
        </div>
        <div class="professionals-tab individual-tab wow fadeInUp" (click)="Clicked(2)">
          <span>Professionals</span>
        </div>
      </div>
    </div>
  </div>
</div>
<app-student-individual *ngIf="isStudentSelected"></app-student-individual>
<app-professional-individual *ngIf="isProfessionalSelected"></app-professional-individual>