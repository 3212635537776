import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-index',
  templateUrl: './admin-index.component.html',
  styleUrls: ['./admin-index.component.less']
})
export class AdminIndexComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
