<div class="dashboard dashboard-home">
  <div class="grid-container">
    <div class="d-comp logo-space">
      <img src="/assets/images/applicationLogos/SBuS.png" alt="" class="img-fluid">
    </div>
    <div class="d-comp welcome-space">
      <h3>
        <i class="fa fa-cogs" aria-hidden="true"></i>
        Setting
      </h3>
    </div>
    <div class="d-comp software-setup">
      <h4 class="heading">
        Software Setting
      </h4>
      <div class="section-install">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
