<!-- <div class="container py-5" *ngIf="course">
  <div class="row">
    <div class="col-12 mb-3 wow fadeInUp">
      <h2>{{course.name}}</h2>
    </div>
  </div>
  <div class="row" style="flex-direction: row-reverse;">
    <div class="col-md-4 col-sm-12 wow fadeInUp">
      <img [src]="course.imgSrc" class="img-fluid w-100" alt="">
    </div>
    <div class="col-md-8 col-sm-12">
      <div class="mb-3 d-flex align-items-center wow fadeInUp">
        <strong><i class="fa fa-clock-o primary-text mr-1" style="font-size: 25px;transform: translateY(3px);"
            aria-hidden="true"></i></strong>
        <span class="">
          {{course.duration}}
        </span>
      </div>
      <p class="wow fadeInUp">
        {{course.desc}}
      </p>
      <div class="my-3">
        <h4>Overview</h4>
      </div>
      <ul class="as-list p-0 overview-list row">
        <li *ngFor="let overview of course.overview" class="wow fadeInUp col-md-6">
          <div class="text">{{overview}}</div>
        </li>
      </ul>
    </div>

  </div>
  <div class="row">
    <div class="col-12 mt-4 d-flex justify-content-between align-content-center">
      <a (click)="goBack()" [ngClass]="{'disabled':!isBack,'back':true}">
        <i class="fa fa-long-arrow-left"></i>
        Back</a>
        <p class="m-0">Next batch starts from: <b>{{course.nextBatch}}</b></p>
      <a (click)="next()" [ngClass]="{'disabled':!isNext,'next':true}">
        Next
        <i class="fa fa-long-arrow-right"></i>
      </a>
    </div>
  </div>
</div> -->
<div class="container py-5" *ngIf="course">
  <div class="row">
    <div class="col-12 mb-3 wow fadeInUp">
      <h2>{{course.courseName}}</h2>
    </div>
  </div>
  <div class="row" style="flex-direction: row-reverse;">
    <div class="col-md-4 col-sm-12 wow fadeInUp">
      <img [src]="course.courseImage.fileData" class="img-fluid w-100" alt="">
    </div>
    <div class="col-md-8 col-sm-12">
      <div class="mb-3 d-flex align-items-center wow fadeInUp">
        <strong><i class="fa fa-clock-o primary-text mr-1" style="font-size: 25px;transform: translateY(3px);"
            aria-hidden="true"></i></strong>
        <span class="">
          {{course.courseDuration}}
        </span>
      </div>
      <p class="wow fadeInUp">
        {{course.courseDescription}}
      </p>
      <div class="my-3">
        <h4>Overview</h4>
      </div>
      <ul class="as-list p-0 overview-list row">
        <li *ngFor="let overview of course.courseOverview" class="wow fadeInUp col-md-6">
          <div class="text">{{overview}}</div>
        </li>
      </ul>
    </div>

  </div>
  <div class="row">
    <div class="col-12 mt-4 d-flex justify-content-between align-content-center">
      <a (click)="goBack()" [ngClass]="{'disabled':!isBack,'back':true}">
        <i class="fa fa-long-arrow-left"></i>
        Back</a>
        <p class="m-0">Next batch starts from: <b>{{course.courseBatchDate}}</b></p>
      <a (click)="next()" [ngClass]="{'disabled':!isNext,'next':true}">
        Next
        <i class="fa fa-long-arrow-right"></i>
      </a>
    </div>
  </div>
</div>