<!-- <div class="parallax-banner"></div> -->
<div class="space-40"></div>
<div class="container p-5" style="box-shadow: 4px 4px 10px 0 #cacaca;">
    <div class="row">
        <div class="col-12">
            <h1 class="text-center">Contact us</h1>
            <hr class="devider">
        </div>
    </div>
    <div class="space-50"></div>
    <div class="row">

        <div class="col-md-6">
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d21530.420681270756!2d78.36789926327214!3d17.45604666211436!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb916038b4a0a3%3A0x71b9c0cf143e770c!2sSD%20MacTec%20IT%20Solutions%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1610443353355!5m2!1sen!2sin"
                frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"
                style="width: 100%;height: 100%;"></iframe>
            <form>

            </form>
        </div>
        <div class="col-md-6">
            <div class="row">

                <!-- <legend class="col-12"><span class="glyphicon glyphicon-globe"></span> Our office</legend> -->
                <address class="col-6 d-flex">
                    <i class="fa fa-map-marker mt-1 mr-3 primary-text" aria-hidden="true"></i>
                    <span>
                        4th Floor. Plot No. 5<br />
                        VVC Konark, Jubilee Enclave. <br />
                        Madhapur, Hyderabad. TS<br />
                    </span>
                </address>
                <address class="col-6 row">
                    <div class="col-12 d-flex">
                        <i class="fa fa-phone mt-1 mr-3 primary-text" aria-hidden="true"></i>
                        <!-- <abbr title="Phone">
                            P:</abbr> -->
                        +91 800 868 5685
                    </div>
                    <div class="col-6 d-flex">
                        <i class="fa fa-envelope mt-1 mr-3 primary-text" aria-hidden="true"></i>
                        <a href="mailto:info@arthasiksha.com" style="white-space: nowrap;">info@arthasiksha.com</a>
                    </div>
                </address>
            </div>
            <div class="well well-sm">
                <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <!-- <label for="name">
                            Name<span class="text-danger">*</span></label> -->
                        <input formControlName="name" maxlength=50 [ngClass]="{'error':isValid('name')}" type="text"
                            class="form-control css_inputfield" id="name" placeholder="Name*" required="required" />
                    </div>
                    <div class="form-group">
                        <!-- <label for="email">
                            Email Address<span class="text-danger">*</span></label> -->
                        <div class="input-group">
                            <span class="input-group-addon"><span class="glyphicon glyphicon-envelope"></span>
                            </span>
                            <input formControlName="email" [ngClass]="{'error':isValid('email')}" type="email"
                                class="form-control css_inputfield" id="email" placeholder="Email Address*"
                                required="required" />
                        </div>
                    </div>
                    <div class="form-group">
                        <!-- <label for="email">
                            Mobile<span class="text-danger">*</span></label> -->
                        <div class="input-group">
                            <span class="input-group-addon"><span class="glyphicon glyphicon-envelope"></span>
                            </span>
                            <input formControlName="mobile" maxlength=15 [ngClass]="{'error':isValid('mobile')}" type="text"
                                class="form-control css_inputfield" id="mobile" placeholder="Mobile*"
                                required="required" />
                        </div>
                    </div>
                    <div class="form-group">
                        <!-- <label for="name">
                            Message<span class="text-danger">*</span></label> -->
                        <textarea formControlName="message" maxlength=500 [ngClass]="{'error':isValid('message')}" name="message"
                            id="message" class="form-control " rows="5" cols="25" required="required"
                            placeholder="Message*"></textarea>
                    </div>
                    <div class="text-center">
                        <button type="submit" [ngClass]="{'disabled':contactForm['invalid']}"
                            [disabled]="contactForm['invalid']" class="button primary button-1" id="btnContactUs">
                            Send Message
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- <app-blog-card></app-blog-card> -->
<div class="space-100"></div>